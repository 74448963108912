import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Button,
  Tooltip,
  Typography,
  FormGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  changeDisplayMode,
  closeMenu,
  openCloseMenu,
} from "../../redux/features/config";
import Logout from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import logoJpg from "../../assets/images/logoH30.jpg";
import logoWebp from "../../assets/images/logoH30.webp";
import logoAvif from "../../assets/images/logoH30.avif";
import logout from "../../functions/auth/logout";
import { setActualBoard } from "../../redux/features/boards";
import { breakpoints } from "../../utils/responsive";
import MenuIcon from "@mui/icons-material/Menu";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { colorsTheme } from "../../assets/theme/theme";

// !!! A SUPPRIMER APRES AVOIR VERIFIE QUE LE CHANGEMENT DE TABLEAU FONCTIONNE !!!
// const MaterialUISwitch = styled(Switch)(({ theme }) => ({
//   width: 56,
//   height: 28,
//   padding: 7,
//   "& .MuiSwitch-switchBase": {
//     margin: 1,
//     padding: 0,
//     transform: "translateX(6px)",
//     "&.Mui-checked": {
//       color: "#fff",
//       transform: "translateX(22px)",
//       "& .MuiSwitch-thumb:before": {
//         backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
//           "#fff"
//         )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
//       },
//       "& + .MuiSwitch-track": {
//         opacity: 1,
//         backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
//       },
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
//     width: 26,
//     height: 26,
//     "&::before": {
//       content: "''",
//       position: "absolute",
//       width: "100%",
//       height: "100%",
//       left: 0,
//       top: 0,
//       backgroundRepeat: "no-repeat",
//       backgroundPosition: "center",
//       backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
//         "#fff"
//       )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
//     },
//   },
//   "& .MuiSwitch-track": {
//     opacity: 1,
//     backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
//     borderRadius: 20 / 2,
//   },
// }));

const Header = () => {
  const configRedux = useSelector((state) => state.config);
  const authRedux = useSelector((state) => state.auth);
  const boardsRedux = useSelector((state) => state.boards);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);

  const handleClick = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
    handleClose2();
    dispatch(closeMenu());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event) => {
    if (!anchorEl2) {
      setAnchorEl2(event.currentTarget);
    } else {
      setAnchorEl2(null);
    }
    handleClose();
    dispatch(closeMenu());
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const menuItems = [
    {
      id: "Profil",
      text: "Profil",
      action: () => {
        handleClose();
        navigate("/profile");
      },
      icon: (
        <AccountCircleIcon
          sx={{
            color: configRedux.darkMode ? "dark.primary" : "light.primary",
          }}
          fontSize="small"
        />
      ),
    },
    {
      id: "Mes tableaux",
      text: "Mes tableaux",
      action: () => {
        handleClose();
        navigate("/boards");
      },
      icon: (
        <ElectricBoltIcon
          sx={{
            color: configRedux.darkMode ? "dark.primary" : "light.primary",
          }}
          fontSize="small"
        />
      ),
    },
    {
      id: "divider-1",
      text: "divider",
    },
    {
      id: "Change-mode",
      text: `Mode ${configRedux.darkMode ? "sombre" : "clair"}`,
      action: () => {
        dispatch(changeDisplayMode());
      },
      icon: configRedux.darkMode ? (
        <DarkModeIcon
          sx={{
            color: configRedux.darkMode ? "dark.primary" : "light.primary",
          }}
          fontSize="small"
        />
      ) : (
        <LightModeIcon
          sx={{
            color: configRedux.darkMode ? "dark.primary" : "light.primary",
          }}
          fontSize="small"
        />
      ),
    },
    {
      id: "divider-2",
      text: "divider",
    },
    {
      id: "MEs notifications",
      text: "Mes notifications",
      action: () => {
        handleClose();
        navigate("notifications");
      },
      icon: (
        <NotificationsActiveIcon
          sx={{
            color: configRedux.darkMode ? "dark.primary" : "light.primary",
          }}
          fontSize="small"
        />
      ),
    },
    {
      id: "logout-button",
      text: "Se déconnecter",
      action: () => {
        handleClose();
        logout(dispatch, navigate);
      },
      icon: (
        <Logout
          sx={{
            color: configRedux.darkMode ? "dark.primary" : "light.primary",
          }}
          fontSize="small"
        />
      ),
    },
  ];

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: configRedux.darkMode
          ? "dark.secondary"
          : "light.secondary",
        zIndex: 1399,
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "64px",
        }}
      >
        {configRedux.screenWidth <= breakpoints.minDesktop && (
          <Button
            sx={{
              width: 50,
              minWidth: 50,
              height: 50,
              p: 0,
              m: 0,
              borderRadius: 50,
            }}
            onClick={() => {
              dispatch(openCloseMenu());
            }}
          >
            <MenuIcon
              sx={{
                color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
              }}
            />
          </Button>
        )}
        <Box
          sx={{
            p: 0,
            m: 0,
            mr: 0,
            mb: 0,
            mt: 0.8,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <picture>
            <source
              type="image/avif"
              srcSet={logoAvif}
              style={{ height: 30, width: 79 }}
            />
            <source
              type="image/webp"
              srcSet={logoWebp}
              style={{ height: 30, width: 79 }}
            />
            <img src={logoJpg} style={{ height: 30, width: 79 }} alt="Oghji" />
          </picture>
        </Box>
        {authRedux.status &&
          !boardsRedux.isLoading &&
          configRedux.screenWidth > breakpoints.minDesktop && (
            // !!! A SUPPRIMER APRES AVOIR VERIFIE QUE LE CHANGEMENT DE TABLEAU FONCTIONNE !!!
            // <Select
            //   inputProps={{
            //     MenuProps: {
            //       MenuListProps: {
            //         sx: {
            //           backgroundColor: `${
            //             configRedux.darkMode ? "dark" : "light"
            //           }.secondary`,
            //         },
            //       },
            //       PaperProps: {
            //         sx: {
            //           color: `${
            //             configRedux.darkMode ? "dark" : "light"
            //           }.primary`,
            //           "& .MuiMenuItem-root.Mui-selected": {
            //             color: `${
            //               configRedux.darkMode ? "dark" : "light"
            //             }.background`,
            //             backgroundColor: `${
            //               configRedux.darkMode ? "dark" : "light"
            //             }.link`,
            //           },
            //         },
            //       },
            //     },
            //   }}
            //   sx={{
            //     minWidth: "10vw",
            //     textAlign: "center",
            //     boxShadow: "none",
            //     fontSize: 20,
            //     color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
            //     ".MuiSelect-icon": {
            //       color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
            //     },
            //     ".MuiOutlinedInput-notchedOutline": {
            //       border: 0,
            //     },
            //     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            //       borderColor: `blue`,
            //       border: 0,
            //     },
            //     "&:hover": { boxShadow: 5, },
            //   }}
            //   onChange={(event) => {
            //     if (event.target.value < 998) {
            //       dispatch(setActualBoard({ actualBoard: event.target.value }));
            //     } else if (event.target.value === 999) {
            //       navigate("/addboard");
            //     }
            //   }}
            //   labelId="board-select"
            //   id="board-select"
            //   name="board-select"
            //   value={
            //     boardsRedux.boards.length > 0 ? boardsRedux.actualBoard : 1000
            //   }
            //   label="Tableau"
            //   aria-label="Tableau"
            // >
            //   {boardsRedux.boards.length > 0 &&
            //     boardsRedux.boards.map((oneBoard) => {
            //       return (
            //         <MenuItem
            //           key={oneBoard.id}
            //           value={boardsRedux.boards.indexOf(oneBoard) + 1}
            //         >
            //           {oneBoard.name}
            //         </MenuItem>
            //       );
            //     })}
            //   {boardsRedux.boards.length === 0 && (
            //     <MenuItem value={1000}>Aucun tableau</MenuItem>
            //   )}
            //   <MenuItem value={999}>Ajouter un tableau</MenuItem>
            // </Select>
            <>
              <Button
                onClick={handleClick2}
                size="small"
                aria-controls={open2 ? "boards-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? "true" : undefined}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: 1,
                  borderRadius: 2,
                  p: 2,
                }}
              >
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    backgroundColor: "transparent",
                  }}
                >
                  <ElectricMeterIcon
                    sx={{
                      color: configRedux.darkMode ? "dark.link" : "light.link",
                      fontSize: 35,
                    }}
                  />
                </Avatar>
                <Typography
                  sx={{
                    textTransform: "none",
                    fontSize: 18,
                    color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
                    fontWeight: "600",
                  }}
                >
                  {location.pathname === "/addboard"
                    ? "Ajouter un tableau"
                    : boardsRedux.actualBoard !== undefined
                    ? boardsRedux.boards[boardsRedux.actualBoard - 1].name
                    : "Aucun tableau"}
                </Typography>
                <KeyboardArrowDownIcon
                  sx={{
                    color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
                  }}
                />
              </Button>
              {/* </Tooltip> */}
              <Menu
                anchorEl={anchorEl2}
                id="boards-menu"
                open={open2}
                onClose={handleClose2}
                onClick={handleClose2}
                sx={{
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 0.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "& .MuiPaper-root": {
                    backgroundColor: configRedux.darkMode
                      ? "dark.background"
                      : "light.background",
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                }}
                transformOrigin={{ horizontal: "center", vertical: "top" }}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
              >
                <FormGroup>
                  {boardsRedux.boards.map((oneBoard, index) => {
                    return (
                      <FormControlLabel
                        value={oneBoard.id}
                        key={`board-${oneBoard.id}`}
                        sx={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          justifyContent: "space-between",
                          columnGap: 2,
                          pl: 2,
                          pr: 2,
                          m: 0,
                        }}
                        // label={`${oneCircuit.name} • ${oneCircuit.location}`}
                        label={
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: "700",
                              color: `${
                                configRedux.darkMode ? "dark" : "light"
                              }.primary`,
                            }}
                          >
                            {oneBoard.name}
                          </Typography>
                        }
                        control={
                          <Radio
                            size="small"
                            sx={{
                              "&.MuiButtonBase-root": {
                                color:
                                  colorsTheme.palette[
                                    configRedux.darkMode ? "dark" : "light"
                                  ].link,
                                pl: 0,
                                pr: 0,
                                pb: 1,
                                pt: 1,
                              },
                            }}
                            id={`board-choice-${oneBoard.id}`}
                            checked={
                              location.pathname !== "/addboard" &&
                              boardsRedux.boards &&
                              boardsRedux.actualBoard !== undefined &&
                              oneBoard.id ===
                                boardsRedux.boards[boardsRedux.actualBoard - 1]
                                  .id
                            }
                            onChange={() => {
                              handleClose2();
                              if (
                                oneBoard.id !==
                                boardsRedux.boards[boardsRedux.actualBoard - 1]
                                  .id
                              ) {
                                dispatch(
                                  setActualBoard({ actualBoard: index + 1 })
                                );
                                navigate("/home");
                              } else if (location.pathname === "/addboard") {
                                navigate("/home");
                              }
                            }}
                          />
                        }
                      />
                    );
                  })}
                  <FormControlLabel
                    value={0}
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      justifyContent: "space-between",
                      columnGap: 2,
                      pl: 2,
                      pr: 2,
                      m: 0,
                    }}
                    label={
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: "700",
                          color: `${
                            configRedux.darkMode ? "dark" : "light"
                          }.primary`,
                        }}
                      >
                        Ajouter un tableau
                      </Typography>
                    }
                    control={
                      <Radio
                        size="small"
                        sx={{
                          "&.MuiButtonBase-root": {
                            color:
                              colorsTheme.palette[
                                configRedux.darkMode ? "dark" : "light"
                              ].link,
                            pl: 0,
                            pr: 0,
                            pb: 1,
                            pt: 1,
                          },
                        }}
                        id={`board-choice-add-board`}
                        checked={location.pathname === "/addboard"}
                        onChange={() => {
                          handleClose2();
                          navigate("/addboard");
                        }}
                      />
                    }
                  />
                </FormGroup>
              </Menu>
            </>
          )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 2,
            alignItems: "center",
          }}
        >
          {authRedux.status && (
            <>
              <Tooltip title={!anchorEl ? "Mon compte" : ""}>
                <IconButton
                  onClick={handleClick}
                  size="small"
                  aria-controls={open ? "user-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar
                    sx={{
                      width: 32,
                      height: 32,
                      backgroundColor: configRedux.darkMode
                        ? "dark.link"
                        : "light.link",
                    }}
                  >
                    <PersonIcon
                      sx={{
                        color: configRedux.darkMode
                          ? "dark.secondary"
                          : "light.secondary",
                      }}
                    />
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="user-menu"
                open={open}
                onClose={handleClose}
                // onClick={handleClose}
                sx={{
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 4,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "& .MuiPaper-root": {
                    backgroundColor: configRedux.darkMode
                      ? "dark.background"
                      : "light.background",
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {menuItems.map((oneElement) => {
                  return oneElement.id.startsWith("divider") ? (
                    <Divider
                      key={oneElement.id}
                      sx={{
                        borderColor: configRedux.darkMode
                          ? "#434343"
                          : "#aaaaaa",
                      }}
                    />
                  ) : (
                    <MenuItem
                      sx={{
                        color: configRedux.darkMode
                          ? "dark.primary"
                          : "light.primary",
                      }}
                      onClick={oneElement.action}
                      key={oneElement.id}
                    >
                      <ListItemIcon>{oneElement.icon}</ListItemIcon>
                      {oneElement.text}
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
