import BASE_URL from "../../utils/api";
import endpoints from "../../utils/endpoints";
import refreshTokenFunction from "../auth/refreshToken";

const getConso = async (data) => {
  let responseStatus = 0;
  let token = data.token;
  let refreshToken = data.refreshToken;
  let result = [];

  const tryFetch = async (fetchToken) => {
    try {
      let fetchConso = await fetch(
        `${BASE_URL}${endpoints.getConso}/${data.boardId}${
          endpoints[data.consoType]
        }?startDate=${data.begin}&endDate=${data.end}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken}`,
          },
          signal: AbortSignal.timeout(3000),
        }
      );
      responseStatus = fetchConso.status;
      if (fetchConso.status === 200) {
        console.log("Get conso function : 200");
        result = await fetchConso.json();
      }
    } catch (error) {
      responseStatus = error.status;
    }
  };

  await tryFetch(token);

  if (responseStatus === 401) {
    console.log("Get conso function : 401");
    let refreshFunction = await refreshTokenFunction({
      refreshToken: refreshToken,
      dispatch: data.dispatch,
    });
    if (refreshFunction.status === 200) {
      token = refreshFunction.token;
      refreshToken = refreshFunction.refreshToken;
      await tryFetch(refreshFunction.token);
    }
  }
  responseStatus !== 200 &&
    data.addSnack("Impossible de récupérer votre consommation.", {
      variant: "error",
    });
  return {
    status: responseStatus,
    token: token,
    refreshToken: refreshToken,
    mustLoading: data.mustLoading,
    result: result,
  };
};

export default getConso;
