export const checkBoardPermissions = (data) => {
  // console.log(data.userId);
  // console.log(Number(data.boardId));
  // console.log(data.boards[0].id);
  let havePermissions = false;
  let isOwner = false;
  let filteredBoards = data.boards.filter(
    (oneBoard) => oneBoard.id === Number(data.boardId)
  );
  if (filteredBoards.length === 1) {
    havePermissions =
      filteredBoards[0].configurations.filter(
        (oneUser) => oneUser.user.id === data.userId
      ).length === 1
        ? true
        : false;
  }
  if (havePermissions) {
    isOwner =
      filteredBoards[0].configurations.filter(
        (oneUser) => oneUser.user.id === data.userId
      )[0].roles[0] === "ROLE_OWNER";
  }
  return { havePermissions: havePermissions, isOwner: isOwner };
};
