import { Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changePeriodChoice } from "../../redux/features/conso";

const PeriodChoice = () => {
  const consoRedux = useSelector((state) => state.conso);
  const configRedux = useSelector((state) => state.config);

  const dispatch = useDispatch();

  const periodChoiceTabs = [
    { text: "Heure" },
    { text: "Jour" },
    { text: "Mois" },
    { text: "Année" },
    { text: "Tout" },
  ];

  const handleChangePeriodChoice = (event, value) => {
    event.preventDefault();
    value !== 0 && dispatch(changePeriodChoice({ period: value }));
  };

  return (
    <Tabs
      value={consoRedux.periodChoice}
      onChange={handleChangePeriodChoice}
      centered
      indicatorColor={`${configRedux.darkMode ? "dark" : "light"}.primary`}
      variant="fullWidth"
      sx={{
        width: "100%",
        borderRadius: 2,
        backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.secondary`,
      }}
    >
      {periodChoiceTabs.map((oneTab) => {
        return (
          <Tab
            disabled={consoRedux.isLoading}
            key={oneTab.text}
            sx={{
              textTransform: "none",
              borderRadius: 2,
              "&.MuiTab-root": {
                backgroundColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.secondary`,
                color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
              },
              "&.Mui-selected": {
                backgroundColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.link`,
                color: `${configRedux.darkMode ? "dark.background" : "light.primary"}`,
              },
            }}
            label={oneTab.text}
          />
        );
      })}
    </Tabs>
  );
};

export default PeriodChoice;
