import { createSlice } from "@reduxjs/toolkit";

// Boards feature

export const boards = createSlice({
  name: "boards",
  initialState: {
    // isLoading : true (during boards fetch) / false
    isLoading: false,
    // Boards list
    boards: [],
    // Datetime of the last fetch
    lastUpdate: undefined,
    actualBoard: undefined,
  },
  reducers: {
    // To activate loading during boards fetch
    enableBoardsLoading: (state) => {
      state.isLoading = true;
    },
    // To disable loading after boards fetch
    disableBoardsLoading: (state) => {
      state.isLoading = false;
    },
    // To save boards after board fetch success
    setBoards: (state, action) => {
      state.boards = action.payload.boards;
      state.lastUpdate = action.payload.lastUpdate;
      state.actualBoard = action.payload.actualBoard;
    },
    // To set actual board choice
    setActualBoard: (state, action) => {
      state.actualBoard = action.payload.actualBoard;
    },
    // To reset all the feature
    resetElectronicBoards: (state) => {
      state.isLoading = false;
      state.boards = [];
      state.lastUpdate = undefined;
      state.actualBoard = undefined;
    },
  },
});

export const {
  enableBoardsLoading,
  disableBoardsLoading,
  setBoards,
  setActualBoard,
  resetElectronicBoards,
} = boards.actions;
