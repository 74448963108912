import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import InfoIcon from "@mui/icons-material/Info";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";

const TechnicalInfos = () => {
  const configRedux = useSelector((state) => state.config);
  const boardsRedux = useSelector((state) => state.boards);

  const params = useParams();
  const navigate = useNavigate();

  const technicalElements = [
    { text: "Nom", attribute: "nom" },
    { text: "Numéro de série", attribute: "numeroSerie" },
    { text: "Version du firmware", attribute: "firmwareVersion" },
    { text: "Version de l'application", attribute: "applicationVersion" },
    { text: "Adress IP", attribute: "ipAdresse" },
    { text: "Routeur", attribute: "routeur" },
    { text: "DNS", attribute: "dns" },
    { text: "Adresse Mac", attribute: "macAdresse" },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        rowGap: 2,
      }}
    >
      <List
        sx={{
          width: "100%",
          backgroundColor: `${
            configRedux.darkMode ? "dark" : "light"
          }.secondary`,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          mt: 1,
          pt: 4,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: -12,
            left: -12,
            backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
            borderRadius: 4,
            pl: 1,
            pr: 2,
            // width: 32,
            height: 32,
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            justifyContent: "center",
            alignItems: "center",
            boxShadow: 5,
          }}
        >
          <InfoIcon />
          <Typography
            sx={{
              color: configRedux.darkMode ? "dark.secondary" : "light.primary",
            }}
          >
            {`${
              boardsRedux.boards.filter(
                (oneBoard) => oneBoard.id === Number(params.boardId)
              ).length === 1 &&
              boardsRedux.boards.filter(
                (oneBoard) => oneBoard.id === Number(params.boardId)
              )[0].name
            } - Informations techniques`}
          </Typography>
        </Box>
        {technicalElements.map((oneItem) => {
          return (
            <ListItem
              key={oneItem.text}
              sx={{
                width: "calc(100% - 32px)",
                backgroundColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.background`,
                mt: 1,
                mb: 1,
                mr: 2,
                ml: 2,
                p: 0,
                borderRadius: 2,
              }}
              secondaryAction={
                <Typography
                  sx={{
                    color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
                  }}
                >
                  {boardsRedux.boards[boardsRedux.actualBoard - 1]
                    .informationTechnique[oneItem.attribute]
                    ? boardsRedux.boards[boardsRedux.actualBoard - 1]
                        .informationTechnique[oneItem.attribute]
                    : "N/A"}
                </Typography>
              }
            >
              <ListItemButton
                sx={{ borderRadius: 2 }}
                onClick={() => {
                  if (
                    boardsRedux.boards[boardsRedux.actualBoard - 1]
                      .informationTechnique[oneItem.attribute]
                  ) {
                    navigator.clipboard.writeText(
                      boardsRedux.boards[boardsRedux.actualBoard - 1]
                        .informationTechnique[oneItem.attribute]
                    );
                    enqueueSnackbar(`Information - ${oneItem.text} - copiée.`, {
                      variant: "success",
                    });
                  }
                }}
              >
                <ListItemText
                  sx={{
                    color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
                  }}
                  id={"test"}
                  primary={oneItem.text}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <Button
        onClick={() => {
          navigate(`/boards/${params.boardId}`);
        }}
        aria-label="add-board-button"
        size="small"
        // fullWidth
        variant="contained"
        sx={{
          mt: 0,
          mr: 0,
          ml: 0,
          mb: 2,
          backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
          color: `${configRedux.darkMode ? "dark" : "light"}.background`,
          "&:hover": {
            backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
            opacity: 0.8,
          },
          textTransform: "none",
          display: "flex",
          flexDirection: "row",
          columnGap: 1,
          alignItems: "center",
        }}
      >
        <ReplyIcon />
        Retour
      </Button>
    </Box>
  );
};

export default TechnicalInfos;
