import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { changeFaqQuestionOpened } from "../../redux/features/config";

export const QuestionBox = (data) => {
  const configRedux = useSelector((state) => state.config);
  const dispatch = useDispatch();

  return (
    <Accordion
      expanded={configRedux.faqQuestionOpened === data.question.question}
      sx={{
        backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.secondary`,
        color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        onClick={() => {
          dispatch(
            changeFaqQuestionOpened({
              question:
                configRedux.faqQuestionOpened === data.question.question
                  ? 0
                  : data.question.question,
            })
          );
        }}
      >
        <Typography sx={{ fontSize: 16 }}>{data.question.title}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: `${
            configRedux.darkMode ? "dark" : "light"
          }.secondary`,
        }}
      >
        {data.question.response}
      </AccordionDetails>
    </Accordion>
  );
};
