import { setBoardEvents } from "../../redux/features/events";

function getBoardEvents(data) {
  let selectedBoardId =
    data.boards.length > 0 && data.actualBoard
      ? data.boards[data.actualBoard - 1].id
      : -1;
  let circuitsOfSelectedBoard = [];
  data.boards.length > 0 &&
    data.actualBoard &&
    data.boards[data.actualBoard - 1].box.map((oneBox) =>
      oneBox.circuits.map((oneCircuit) =>
        circuitsOfSelectedBoard.push(oneCircuit.id)
      )
    );
  let resultEvents = [];
  data.events.length > 0 &&
    data.events.forEach((oneEvent) => {
      if (
        (oneEvent.circuit &&
          circuitsOfSelectedBoard.includes(oneEvent.circuit.id)) ||
        (oneEvent.electronicBoard &&
          oneEvent.electronicBoard.id === selectedBoardId)
      ) {
        resultEvents.push(oneEvent);
      }
    });
  data.dispatch(
    setBoardEvents({
      boardEvents: resultEvents,
    })
  );
  return resultEvents.length > 0;
}

export default getBoardEvents;
