import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import { auth } from "../features/auth";
import { config } from "../features/config";
import storage from "redux-persist/lib/storage";
import { boards } from "../features/boards";
import { circuits } from "../features/circuits";
import { events } from "../features/events";
import { profile } from "../features/profile";
import { conso } from "../features/conso";
import { analyse } from "../features/analyse";

const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_ENCRYPTED_STORAGE_KEY,
  onError: function (error) {
    console.log(error);
  },
});

const persistConfig = {
  key: "oghji",
  storage,
  transforms: [encryptor],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth: auth.reducer,
    config: config.reducer,
    boards: boards.reducer,
    circuits: circuits.reducer,
    events: events.reducer,
    profile: profile.reducer,
    conso: conso.reducer,
    analyse: analyse.reducer,
  })
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export function setupStore(preloadedState) {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
  });
}

export const persistor = persistStore(store);
