import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import PinField from "react-pin-field";
import { enqueueSnackbar } from "notistack";
import addElectronicBoard from "../functions/boards/addElectronicBoard";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";

const AddBoardPage = () => {
  const authRedux = useSelector((state) => state.auth);
  const configRedux = useSelector((state) => state.config);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("send");
    setIsLoading(true);
    await addElectronicBoard({
      token: authRedux.token,
      refreshToken: authRedux.refreshToken,
      dispatch: dispatch,
      shareCode: code,
      addSnack: enqueueSnackbar,
      navigate: navigate,
    });
    setIsLoading(false);
  };

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          mt: 3,
          p: 2,
          backgroundColor: alpha(
            configRedux.darkMode ? "#000000" : "#ffffff",
            0.8
          ),
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 3,
          maxWidth: 500,
          rowGap: 3,
        }}
      >
        <Box>
          <Typography sx={{ fontSize: 18, fontWeight: "600" }}>
            Ajouter un tableau
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: 2,
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: 14, textAlign: "justify" }}>
            Vous pouvez saisir le code qui vous a été communiqué par le
            propriétaire du tableau. Il s'agit d'un code contenant 6 caractères
            (lettres et chiffres).
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                columnGap: 1,
                alignItems: "center",
              }}
            >
              <PinField
                autoComplete="off"
                ref={ref}
                length={6}
                style={{
                  backgroundColor: "transparent",
                  width: 30,
                  height: 30,
                  borderBottomWidth: 1,
                  borderTopWidth: 0,
                  borderRightWidth: 0,
                  borderLeftWidth: 0,
                  boxShadow: 0,
                  outline: "none",
                  textAlign: "center",
                  transitionDuration: 250,
                  transitionProperty: "color, border, boxShadow, transform",
                }}
                name="testid"
                onClick={() =>
                  ref &&
                  ref.current &&
                  ref.current[code.length < 6 ? code.length : 5].focus()
                }
                onChange={(text) => {
                  setCode(text.toUpperCase());
                }}
                onComplete={() => ref && ref.current && ref.current[5].blur()}
                format={(k) => k.toUpperCase()}
                autoFocus
                disabled={false}
              />
            </Box>
            <Tooltip title={code.length === 0 ? "" : "Effacer"}>
              <span>
                <IconButton
                  disabled={code.length === 0}
                  aria-label="delete code"
                  onClick={() => {
                    setCode("");
                    ref.current.forEach((input) => (input.value = ""));
                  }}
                  edge="end"
                >
                  <BackspaceOutlinedIcon
                    sx={{
                      color: `${
                        configRedux.darkMode ? "dark" : "light"
                      }.primary`,
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>
        <Box>
          <Button
            data-testid="sendButton"
            disabled={isLoading || code.length !== 6}
            type="submit"
            size="small"
            fullWidth
            variant="contained"
            sx={{
              mt: 1,
              mb: 4,
              backgroundColor: `${
                configRedux.darkMode ? "dark" : "light"
              }.link`,
              color: `${configRedux.darkMode ? "dark" : "light"}.background`,
              "&:hover": {
                backgroundColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.link`,
                opacity: 0.8,
              },
              textTransform: "none",
            }}
          >
            Ajouter
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddBoardPage;
