import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import oghjiLogo from "../../assets/images/logoOnly.png";
import { useEffect } from "react";
import { checkBoardPermissions } from "../../functions/settings/checkBoardPersmissions";
import SignalCellularAlt1BarIcon from "@mui/icons-material/SignalCellularAlt1Bar";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import SignalCellularAlt2BarIcon from "@mui/icons-material/SignalCellularAlt2Bar";

const Tension = () => {
  const configRedux = useSelector((state) => state.config);
  const boardsRedux = useSelector((state) => state.boards);
  const profileRedux = useSelector((state) => state.profile);

  const params = useParams();
  const navigate = useNavigate();

  const tensionElements = [
    {
      text: "Tension minimale",
      attribute: "min",
      value: 200,
      icon: <SignalCellularAlt1BarIcon />,
    },
    {
      text: "Tension moyenne",
      attribute: "moy",
      value: 230,
      icon: <SignalCellularAlt2BarIcon />,
    },
    {
      text: "Tension maximale",
      attribute: "max",
      value: 260,
      icon: <SignalCellularAltIcon />,
    },
  ];

  useEffect(() => {
    if (
      !checkBoardPermissions({
        boardId: params.boardId,
        userId: profileRedux.id,
        boards: boardsRedux.boards,
      }).havePermissions
    ) {
      navigate("/error");
    } // eslint-disable-next-line
  }, []);

  return boardsRedux.isLoading ? (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexBasis: "100%",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: alpha(
            configRedux.darkMode ? "#000000" : "#ffffff",
            0.8
          ),
          borderRadius: 100,
          position: "relative",
        }}
      >
        <CircularProgress
          size={70}
          sx={{ color: `${configRedux.darkMode ? "dark" : "light"}.link` }}
        />
        <img
          src={oghjiLogo}
          alt={"Chargement"}
          height={40}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        rowGap: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          backgroundColor: `${
            configRedux.darkMode ? "dark" : "light"
          }.secondary`,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          mt: 1,
          pt: 4,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: -12,
            left: -12,
            backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
            borderRadius: 4,
            pl: 1,
            pr: 2,
            // width: 32,
            height: 32,
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            justifyContent: "center",
            alignItems: "center",
            boxShadow: 5,
          }}
        >
          <ElectricBoltIcon />
          <Typography
            sx={{
              color: configRedux.darkMode ? "dark.secondary" : "light.primary",
            }}
          >
            {`${
              boardsRedux.boards.filter(
                (oneBoard) => oneBoard.id === Number(params.boardId)
              ).length === 1 &&
              boardsRedux.boards.filter(
                (oneBoard) => oneBoard.id === Number(params.boardId)
              )[0].name
            } - Tension`}
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              pl: 2,
              pr: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              rowGap: 2,
            }}
          >
            <Typography
              sx={{
                color: `${configRedux.darkMode ? "dark" : "light"}.link`,
                fontSize: 16,
                fontWeight: "600",
              }}
            >
              {
                boardsRedux.boards[boardsRedux.actualBoard - 1].tensions[0]
                  .tension
              }
              {" V"}
            </Typography>
            <Divider
              sx={{
                color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
                width: "100%",
              }}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                pb: 2,
              }}
            >
              {tensionElements.map((oneElement) => {
                return (
                  <Box
                    key={oneElement.attribute}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {oneElement.icon}
                    <Typography>{oneElement.text}</Typography>
                    <Typography>{`${oneElement.value} V`}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
      <Button
        onClick={() => {
          navigate(`/boards/${params.boardId}`);
        }}
        aria-label="add-board-button"
        size="small"
        // fullWidth
        variant="contained"
        sx={{
          mt: 0,
          mr: 0,
          ml: 0,
          mb: 2,
          backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
          color: `${configRedux.darkMode ? "dark" : "light"}.background`,
          "&:hover": {
            backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
            opacity: 0.8,
          },
          textTransform: "none",
          display: "flex",
          flexDirection: "row",
          columnGap: 1,
          alignItems: "center",
        }}
      >
        <ReplyIcon />
        Retour
      </Button>
    </Box>
  );
};

export default Tension;
