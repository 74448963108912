import BASE_URL from "../../utils/api";
import endpoints from "../../utils/endpoints";
import refreshTokenFunction from "../auth/refreshToken";

const getBatimentInfos = async (data) => {
  let responseStatus = 0;
  let token = data.token;
  let refreshToken = data.refreshToken;
  let batiment = undefined;
  const tryFetch = async (fetchToken) => {
    try {
      let fetchBatiment = await fetch(
        `${BASE_URL}${endpoints.getBatiment}/${data.batimentId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken}`,
          },
          signal: AbortSignal.timeout(3000),
        }
      );
      responseStatus = fetchBatiment.status;
      if (fetchBatiment.status === 200) {
        console.log("Get batiment function : 200");
        batiment = await fetchBatiment.json();
      }
    } catch (error) {
      responseStatus = error.status;
    }
  };

  await tryFetch(token);

  if (responseStatus === 401) {
    console.log("Get batiment function : 401");
    let refreshFunction = await refreshTokenFunction({
      refreshToken: refreshToken,
      dispatch: data.dispatch,
    });
    if (refreshFunction.status === 200) {
      token = refreshFunction.token;
      refreshToken = refreshFunction.refreshToken;
      await tryFetch(refreshFunction.token);
    }
  }
  responseStatus !== 200 &&
    data.addSnack("Impossible de récupérer les informations du bâtiment.", {
      variant: "error",
    });
  return {
    status: responseStatus,
    batiment: batiment,
  };
};

export default getBatimentInfos;
