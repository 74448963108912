import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  setfilterEventsCategories,
  setfilterEventsCircuits,
} from "../../redux/features/events";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import eventsCategories from "../../utils/eventsCategories";
import { colorsTheme } from "../../assets/theme/theme";

const FilterLine = () => {
  const configRedux = useSelector((state) => state.config);
  const circuitsRedux = useSelector((state) => state.circuits);
  const eventsRedux = useSelector((state) => state.events);

  const dispatch = useDispatch();

  const [anchorElCircuits, setAnchorElCircuits] = useState(null);
  const [newValuesCircuits, setNewValuesCircuits] = useState(
    eventsRedux.filterEventsCircuits
  );
  const openCircuits = Boolean(anchorElCircuits);
  const [anchorElCategories, setAnchorElCategories] = useState(null);
  const [newValuesCategories, setNewValuesCategories] = useState(
    eventsRedux.filterEventsCategories
  );
  const openCategories = Boolean(anchorElCategories);

  const handleClickCircuits = (event) => {
    setAnchorElCircuits(event.currentTarget);
  };

  const handleCloseCircuits = () => {
    setNewValuesCircuits(eventsRedux.filterEventsCircuits);
    setAnchorElCircuits(null);
  };

  const handleConfirmCircuits = () => {
    dispatch(setfilterEventsCircuits({ circuits: newValuesCircuits }));
    setAnchorElCircuits(null);
  };

  const changeValuesCircuits = (event) => {
    if (!event.target.checked) {
      setNewValuesCircuits((newValuesCircuits) =>
        newValuesCircuits.filter(
          (oneCircuit, index) => oneCircuit !== Number(event.target.value)
        )
      );
    } else {
      setNewValuesCircuits([...newValuesCircuits, Number(event.target.value)]);
    }
  };

  const handleClickCategories = (event) => {
    setAnchorElCategories(event.currentTarget);
  };

  const handleCloseCategories = () => {
    setNewValuesCategories(eventsRedux.filterEventsCategories);
    setAnchorElCategories(null);
  };

  const handleConfirmCategories = () => {
    dispatch(setfilterEventsCategories({ categories: newValuesCategories }));
    setAnchorElCategories(null);
  };

  const changeValuesCategories = (event) => {
    if (!event.target.checked) {
      setNewValuesCategories((newValuesCategories) =>
        newValuesCategories.filter(
          (oneCategory, index) => oneCategory !== Number(event.target.value)
        )
      );
    } else {
      setNewValuesCategories([
        ...newValuesCategories,
        Number(event.target.value),
      ]);
    }
  };

  const FilterButton = (data) => {
    return (
      <Tooltip title={data.data.title}>
        <Button
          onClick={data.data.clickFunction}
          sx={{
            ml: 1,
            textTransform: "none",
            display: "flex",
            flexDirection: "row",
            columnGap: 2,
            alignItems: "center",
            backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
          }}
          aria-controls={openCircuits ? data.data.ariaControl : undefined}
          aria-haspopup="true"
          aria-expanded={openCircuits ? "true" : undefined}
        >
          <Typography
            sx={{
              fontSize: 14,
              color: `${configRedux.darkMode ? "dark" : "light"}.background`,
            }}
          >
            {data.data.boxTitle}
          </Typography>
          {data.data.fullCondition ? (
            <FilterListOffIcon
              sx={{
                color: `${configRedux.darkMode ? "dark" : "light"}.background`,
              }}
            />
          ) : (
            <FilterListIcon
              sx={{
                color: `${configRedux.darkMode ? "dark" : "light"}.background`,
              }}
            />
          )}
        </Button>
      </Tooltip>
    );
  };

  const DialogActionsMaker = (data) => {
    return (
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          onClick={data.data.reinitFunction}
          sx={{
            backgroundColor: `${
              configRedux.darkMode ? "dark" : "light"
            }.secondary`,
            color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
            textTransform: "none",
          }}
        >
          {data.data.reinitText}
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: 2,
          }}
        >
          <Button
            onClick={data.data.cancelFunction}
            sx={{
              backgroundColor: `${
                configRedux.darkMode ? "dark" : "light"
              }.secondary`,
              color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
              textTransform: "none",
            }}
          >
            {data.data.cancelText}
          </Button>
          <Button
            onClick={data.data.confirmFunction}
            sx={{
              backgroundColor: `${
                configRedux.darkMode ? "dark" : "light"
              }.link`,
              color: `${configRedux.darkMode ? "dark" : "light"}.background`,
              "&:hover": {
                backgroundColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.link`,
                opacity: 0.8,
              },
              textTransform: "none",
            }}
          >
            {data.data.confirmText}
          </Button>
        </Box>
      </DialogActions>
    );
  };

  const DialogContentMaker = (data) => {
    return (
      <DialogContent
        dividers
        sx={{
          borderColor: configRedux.darkMode
            ? "dark.button.before"
            : "light.button.before",
        }}
      >
        <FormGroup>
          {data.data.baseArray.map((oneElement) => (
            <FormControlLabel
              value={oneElement.id}
              key={
                data.data.type === "circuits" ? oneElement.id : oneElement.name
              }
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "space-between",
                p: 0,
                m: 0,
              }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    columnGap: 2,
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  <img
                    src={
                      // CHANGE IF ID > LIST LENGTH
                      data.data.type === "circuits"
                        ? require(`../../assets/circuits/pictos/${
                            oneElement.pictogramme.id < 63
                              ? oneElement.pictogramme.id
                              : 1
                          }.png`)
                        : oneElement.picto
                    }
                    alt={oneElement.name}
                    style={{
                      height: 16,
                      filter: configRedux.darkMode
                        ? "brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(1%) hue-rotate(198deg) brightness(87%) contrast(98%)"
                        : "brightness(0) saturate(100%) invert(3%) sepia(8%) saturate(6%) hue-rotate(332deg) brightness(99%) contrast(101%)",
                    }}
                  />
                  <Typography
                    sx={{
                      color: configRedux.darkMode
                        ? "dark.primary"
                        : "light.primary",
                      fontSize: "13",
                    }}
                  >{`${oneElement.name}${
                    data.data.type === "circuits"
                      ? ` • ${oneElement.location}`
                      : ""
                  }`}</Typography>
                </Box>
              }
              control={
                <Checkbox
                  style={{
                    color:
                      colorsTheme.palette[
                        configRedux.darkMode ? "dark" : "light"
                      ].link,
                  }}
                  id={`filter-${data.data.type}-${oneElement.id}-${oneElement.name}`}
                  checked={data.data.newValuesArray.includes(oneElement.id)}
                  onChange={data.data.checkBoxChange}
                />
              }
            />
          ))}
        </FormGroup>
      </DialogContent>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.secondary`,
        // position: "sticky",
        // top: -23,
        zIndex: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 2,
        p: 2,
      }}
    >
      {/* Circuits */}
      <FilterButton
        data={{
          titie: "Filtrer par circuits",
          clickFunction: handleClickCircuits,
          ariaControl: "circuits-filter-menu",
          boxTitle: "Filtrer par circuit",
          fullCondition:
            eventsRedux.initialFilterEventsCircuits.length ===
            newValuesCircuits.length,
        }}
      />
      <Dialog
        open={openCircuits}
        keepMounted
        aria-label="circuits-filter-menu"
        onClose={handleCloseCircuits}
        aria-describedby="filter-circuits-dialog"
        sx={{
          "& .MuiDialog-paper": {
            minWidth: "30%",
            maxWidth: "100%",
            maxHeight: "calc(100vh - 200px)",
            backgroundColor: configRedux.darkMode
              ? "dark.secondary"
              : "light.secondary",
          },
        }}
      >
        <DialogTitle
          sx={{
            color: configRedux.darkMode ? "dark.primary" : "light.primary",
          }}
        >
          Circuits à afficher
        </DialogTitle>
        <DialogContentMaker
          data={{
            type: "circuits",
            baseArray: circuitsRedux.boardCircuits,
            newValuesArray: newValuesCircuits,
            checkBoxChange: changeValuesCircuits,
          }}
        />
        <DialogActionsMaker
          data={{
            reinitFunction: () => {
              handleCloseCircuits();
              setNewValuesCircuits(eventsRedux.initialFilterEventsCircuits);
              dispatch(
                setfilterEventsCircuits({
                  circuits: eventsRedux.initialFilterEventsCircuits,
                })
              );
            },
            reinitText: "Réinitialiser",
            cancelFunction: handleCloseCircuits,
            cancelText: "Annuler",
            confirmFunction: handleConfirmCircuits,
            confirmText: "Appliquer",
          }}
        />
      </Dialog>
      {/* Categories */}
      <FilterButton
        data={{
          titie: "Filtrer par catégories",
          clickFunction: handleClickCategories,
          ariaControl: "categories-filter-menu",
          boxTitle: "Filtrer par évènement",
          fullCondition:
            eventsRedux.initialFilterEventsCategories.length ===
            newValuesCategories.length,
        }}
      />
      <Dialog
        open={openCategories}
        keepMounted
        onClose={handleCloseCategories}
        aria-label="categories-filter-menu"
        aria-describedby="filter-categories-dialog"
        sx={{
          "& .MuiDialog-paper": {
            minWidth: "30%",
            maxWidth: "100%",
            maxHeight: "calc(100vh - 200px)",
            backgroundColor: configRedux.darkMode
              ? "dark.secondary"
              : "light.secondary",
          },
        }}
      >
        <DialogTitle
          sx={{
            borderColor: `${configRedux.darkMode ? "dark" : "light"}.secondary`,
          }}
        >
          <Typography
            sx={{ color: `${configRedux.darkMode ? "dark" : "light"}.primary` }}
          >
            Evènements à afficher
          </Typography>
        </DialogTitle>
        <DialogContentMaker
          data={{
            type: "categories",
            baseArray: eventsCategories,
            newValuesArray: newValuesCategories,
            checkBoxChange: changeValuesCategories,
          }}
        />
        <DialogActionsMaker
          data={{
            reinitFunction: () => {
              handleCloseCategories();
              setNewValuesCategories(eventsRedux.initialFilterEventsCategories);
              dispatch(
                setfilterEventsCategories({
                  categories: eventsRedux.initialFilterEventsCategories,
                })
              );
            },
            reinitText: "Réinitialiser",
            cancelFunction: handleCloseCategories,
            cancelText: "Annuler",
            confirmFunction: handleConfirmCategories,
            confirmText: "Appliquer",
          }}
        />
      </Dialog>
    </Box>
  );
};

export default FilterLine;
