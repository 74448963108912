const eventsCategories = [
    {id: 1, type: 0, name: 'Coupure générale du réseau électrique', picto: require('../assets/events/galReseau.png'), importance: 3},
    {id: 2, type: 0, name: 'Activation générale du réseau électrique', picto: require('../assets/events/galReseau.png'), importance: 3},
    {id: 3, type: 0, name: 'Coupure circuit : sur-intensité', picto: require('../assets/events/surintensite.png'), importance: 3},
    {id: 4, type: 0, name: 'Coupure circuit : fuite de courant', picto: require('../assets/events/fuite.png'), importance: 3},
    {id: 5, type: 0, name: 'Coupure circuit : erreur de branchement', picto: require('../assets/events/branchement.png'), importance: 3},
    {id: 6, type: 1, name: 'Sur-tension du réseau électrique', picto: require('../assets/events/surtensionReseau.png'), importance: 3},
    {id: 7, type: 1, name: 'Sous-tension du réseau électrique', picto: require('../assets/events/soustension.png'), importance: 3},
    {id: 8, type: 2, name: 'Début de délestage : forte consommation', picto: require('../assets/events/forteConso.png'), importance: 2},
    {id: 9, type: 2, name: 'Fin de délestage : forte consommation', picto: require('../assets/events/forteConso.png'), importance: 2},
    {id: 10, type: 2, name: `Début de délestage : ordre d'un opérateur`, picto: require('../assets/events/operateur.png'), importance: 2},
    {id: 11, type: 2, name: `Fin de délestage : ordre d'un opérateur`, picto: require('../assets/events/operateur.png'), importance: 2},
    {id: 12, type: 3, name: `Coupure d'un circuit : changement de mode (Présence, Absence, Vacances)`, picto: require('../assets/events/mode.png'), importance: 0},
    {id: 13, type: 3, name: `Activation d'un circuit : changement de mode (Présence, Absence, Vacances)`, picto: require('../assets/events/mode.png'), importance: 0},
    {id: 14, type: 3, name: `Coupure d'un circuit : changement de mode (Jour, Nuit)`, picto: require('../assets/events/soleil.png'), importance: 0},
    {id: 15, type: 3, name: `Activation d'un circuit : changement de mode (Jour, Nuit)`, picto: require('../assets/events/soleil.png'), importance: 0},
    {id: 16, type: 3, name: `Coupure d'un circuit : programmation horaire`, picto: require('../assets/events/horaire.png'), importance: 0},
    {id: 17, type: 3, name: `Activation d'un circuit : programmation horaire`, picto: require('../assets/events/horaire.png'), importance: 0},
    {id: 18, type: 3, name: `Coupure d'un circuit : changement de plage tarifaire`, picto: require('../assets/events/tarif.png'), importance: 0},
    {id: 19, type: 3, name: `Coupure d'un circuit : activation de plage tarifaire`, picto: require('../assets/events/tarif.png'), importance: 0},
    {id: 20, type: 4, name: `Coupure générale manuelle`, picto: require('../assets/events/manuel.png'), importance: 1},
    {id: 21, type: 4, name: `Activation générale manuelle`, picto: require('../assets/events/manuel.png'), importance: 1},
    {id: 22, type: 4, name: `Coupure manuelle d'un circuit`, picto: require('../assets/events/manuel.png'), importance: 1},
    {id: 23, type: 4, name: `Activation manuelle d'un circuit`, picto: require('../assets/events/manuel.png'), importance: 1},
  ];
  
  export default eventsCategories;
  