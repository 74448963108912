import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { breakpoints } from "../../utils/responsive";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HttpsIcon from "@mui/icons-material/Https";
import TextFieldProfile from "./textFieldProfile";
import { enqueueSnackbar } from "notistack";
import { changePassword } from "../../functions/settings/changePassword";

const PasswordProfile = () => {
  const configRedux = useSelector((state) => state.config);
  const authRedux = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [isOpened, setIsOpened] = useState(false);
  const [actualPass, setAcutalPass] = useState("");
  const [newPass1, setNewPass1] = useState("");
  const [newPass2, setNewPass2] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const inputElements = [
    {
      id: "myActualPass",
      type: "password",
      error: false,
      errorTxt: "Format incorrect",
      textId: "input-actual-password-profile",
      label: "Mot de passe actuel",
      required: true,
      prefix: "",
      value: actualPass,
      autoComplete: "current-password",
      onChange: (text) => {
        setAcutalPass(text.target.value);
      },
    },
    {
      id: "myNewPass1",
      type: "password",
      error: false,
      errorTxt: "Format incorrect",
      textId: "input-new1-password-profile",
      label: "Nouveau mot de passe",
      autoComplete: "new-password",
      required: true,
      prefix: "",
      value: newPass1,
      onChange: (text) => {
        setNewPass1(text.target.value);
      },
    },
    {
      id: "myNewPass2",
      type: "password",
      error: false,
      errorTxt: "Format incorrect",
      textId: "input-new2-password-profile",
      label: "Confirmer votre nouveau mot de passe",
      autoComplete: "new-password",
      required: true,
      prefix: "",
      value: newPass2,
      onChange: (text) => {
        setNewPass2(text.target.value);
      },
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFetching(true);
    if (actualPass !== newPass1 && newPass1 === newPass2) {
      await changePassword({
        token: authRedux.token,
        refreshToken: authRedux.refreshToken,
        newPassword: { current_password: actualPass, new_password: newPass1 },
        addSnack: enqueueSnackbar,
        dispatch: dispatch,
      });
    } else if (newPass1 !== newPass2) {
      enqueueSnackbar("Les nouveaux mots de passe doivent être identiques.", {
        variant: "error",
      });
    } else if (actualPass === newPass1) {
      enqueueSnackbar(
        "Le nouveau mot de passe doit être différent de l'ancien",
        {
          variant: "error",
        }
      );
    }
    setIsFetching(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.secondary`,
        pt: 5,
        pr: 2,
        pl: 2,
        pb: 2,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent:
          configRedux.screenWidth > breakpoints.minTablet
            ? "space-around"
            : "center",
        position: "relative",
        boxShadow: 3,
        alignItems: "center",
        rowGap: 0,
        // height: "100%",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: -12,
          left: -12,
          backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
          borderRadius: 4,
          pl: 1,
          pr: 2,
          // width: 32,
          height: 32,
          display: "flex",
          flexDirection: "row",
          columnGap: 1,
          justifyContent: "center",
          alignItems: "center",
          boxShadow: 5,
        }}
      >
        <HttpsIcon />
        <Typography
          sx={{
            color: configRedux.darkMode ? "dark.secondary" : "light.primary",
          }}
        >
          Mot de passe
        </Typography>
      </Box>
      <Accordion
        expanded={isOpened}
        sx={{
          backgroundColor: `${
            configRedux.darkMode ? "dark" : "light"
          }.secondary`,
          color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
          p: 0,
          m: 0,
          width: "100%",
          "&.Mui-expanded": { m: 0, minHeight: 48 },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ m: 0 }}
          onClick={() => {
            setIsOpened(!isOpened);
          }}
        >
          <Typography sx={{ fontSize: 16 }}>
            Modifier votre vot de passe
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: `${
              configRedux.darkMode ? "dark" : "light"
            }.secondary`,
            p: 0,
            mt: 0,
            ml: 0,
            mr: 0,
            mb: 2,
          }}
        >
          <Box
            onSubmit={handleSubmit}
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              m: 0,
              p: 0,
              rowGap: 0,
            }}
          >
            {inputElements.map((oneInput) => {
              return (
                <Box sx={{ width: "100%", m: 0, p: 0 }} key={oneInput.id}>
                  <TextFieldProfile data={{ ...oneInput, margin: 0 }} />
                </Box>
              );
            })}
            <Button
              aria-label="save-profile-button"
              disabled={isFetching}
              type="submit"
              size="small"
              variant="contained"
              sx={{
                mt: 2,
                backgroundColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.link`,
                color: `${configRedux.darkMode ? "dark" : "light"}.background`,
                "&:hover": {
                  backgroundColor: `${
                    configRedux.darkMode ? "dark" : "light"
                  }.link`,
                  opacity: 0.8,
                },
                textTransform: "none",
              }}
            >
              Enregistrer
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default PasswordProfile;
