import { setBoards } from "../../redux/features/boards";
import BASE_URL from "../../utils/api";
import endpoints from "../../utils/endpoints";
import refreshTokenFunction from "../auth/refreshToken";

const getBoards = async (data) => {
  let responseStatus = 0;
  let token = data.token;
  let refreshToken = data.refreshToken;
  const tryFetch = async (fetchToken) => {
    try {
      let fetchBoards = await fetch(`${BASE_URL}${endpoints.getBoards}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken}`,
        },
        signal: AbortSignal.timeout(3000),
      });
      responseStatus = fetchBoards.status;
      if (fetchBoards.status === 200) {
        console.log("Get board function : 200");
        let fetchData = await fetchBoards.json();
        let newActual = undefined;
        if (
          data.actual !== undefined &&
          fetchData["hydra:totalItems"] > 0 &&
          data.actual <= fetchData["hydra:totalItems"] + 1
        ) {
          newActual = data.actual;
        } else if (fetchData["hydra:totalItems"] > 0) {
          newActual = 1;
        } else {
          newActual = undefined;
        }
        data.dispatch(
          setBoards({
            boards:
              fetchData["hydra:totalItems"] > 0
                ? fetchData["hydra:member"]
                : [],
            lastUpdate: Date.now(),
            actualBoard: newActual,
          })
        );
      }
    } catch (error) {
      responseStatus = error.status;
    }
  };
  await tryFetch(token);
  if (responseStatus === 401) {
    console.log("Get board function : 401");
    let refreshFunction = await refreshTokenFunction({
      refreshToken: refreshToken,
      dispatch: data.dispatch,
    });
    if (refreshFunction.status === 200) {
      token = refreshFunction.token;
      refreshToken = refreshFunction.refreshToken;
      await tryFetch(refreshFunction.token);
    }
  }
  responseStatus !== 200 &&
    data.addSnack("Impossible de récupérer les tableaux.", {
      variant: "error",
    });
  return {
    status: responseStatus,
    token: token,
    refreshToken: refreshToken,
    mustLoading: data.mustLoading,
  };
};

export default getBoards;
