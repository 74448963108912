import HomeIcon from "@mui/icons-material/Home";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import BarChartIcon from "@mui/icons-material/BarChart";
import PieChartIcon from "@mui/icons-material/PieChart";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import LoginIcon from "@mui/icons-material/Login";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";

export const menuElements = {
  logged: [
    {
      name: "Accueil",
      icon: <HomeIcon />,
      link: "/home",
    },
    {
      name: "Circuits",
      icon: <DynamicFormIcon />,
      link: "/circuits",
    },
    { name: "Conso", icon: <BarChartIcon />, link: "/conso" },
    { name: "Analyse", icon: <PieChartIcon />, link: "/analyse" },
    {
      name: "Evènements",
      icon: <FormatListBulletedIcon />,
      link: "/events",
    },
  ],
  unlogged: [
    {
      name: "Se connecter",
      icon: <LoginIcon />,
      link: "/signin",
    },
    {
      name: "S'inscrire",
      icon: <AppRegistrationIcon />,
      link: "/signup",
    },
  ],
};
