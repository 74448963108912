import { setProfile } from "../../redux/features/profile";
import BASE_URL from "../../utils/api";
import endpoints from "../../utils/endpoints";
import refreshTokenFunction from "../auth/refreshToken";

export const patchMyProfile = async (data) => {
  let responseStatus = 0;
  const tryFetch = async (token) => {
    try {
      let fetchPatchMyProfile = await fetch(
        `${BASE_URL}${endpoints.patchProfile}/${data.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/merge-patch+json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data.newProfil),
          signal: AbortSignal.timeout(3000),
        }
      );
      responseStatus = fetchPatchMyProfile.status;
      if (fetchPatchMyProfile.status === 200) {
        console.log("Patch circuits function : 200");
        data.dispatch(setProfile({ ...data.newProfil, update: Date.now() }));
      }
    } catch (error) {
      responseStatus = error.status;
    }
  };

  await tryFetch(data.token);

  if (responseStatus === 401) {
    console.log("Patch circuits function : 401");
    let refreshFunction = await refreshTokenFunction({
      refreshToken: data.refreshToken,
      dispatch: data.dispatch,
    });
    refreshFunction.status === 200 && (await tryFetch(refreshFunction.token));
  }
  responseStatus === 200
    ? data.addSnack("Profil mis à jour avec succès !", {
        variant: "success",
      })
    : data.addSnack("impossible de mettre à jour votre profil", {
        variant: "error",
      });
  return responseStatus;
};
