export const pageTitle = [
  { path: "/", title: "Oghji - Tableau intelligent et connecté" },
  { path: "/signup", title: "Oghji - Inscription" },
  { path: "/signin", title: "Oghji - Connexion" },
  { path: "/home", title: "Oghji - Accueil" },
  { path: "/circuits", title: "Oghji - Circuits" },
  { path: "/faq", title: "Oghji - Foire aux questions" },
  { path: "/conso", title: "Oghji - Consommation" },
  { path: "/analyse", title: "Oghji - Analyse" },
  { path: "/events", title: "Oghji - Evènements" },
  { path: "/addboard", title: "Oghji - Ajouter un tableau" },
  { path: "/profile", title: "Oghji - Profil" },
  { path: "/boards", title: "Oghji - Mes tableaux" },
  { path: "/boards/", title: "Oghji - Mon tableau" },
  { path: "/technical-informations", title: "Oghji - Informations techniques" },
];
