import { createSlice } from "@reduxjs/toolkit";

export const profile = createSlice({
  name: "profile",
  initialState: {
    isLoading: true,
    id: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    phoneNumber: undefined,
    profile: undefined,
    lastUpdate: 0,
  },
  reducers: {
    // Start loading
    enableProfileLoading: (state) => {
      state.isLoading = true;
    },
    // Set profile data
    setProfile: (state, action) => {
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.phoneNumber = action.payload.phoneNumber;
      state.profile = action.payload.profile;
      state.lastUpdate = action.payload.update;
      state.id = action.payload.id;
    },
    disableProfileLoading: (state, action) => {
      state.isLoading = false;
    },
    // To reset all the feature
    resetProfile: (state) => {
      state.isLoading = true;
      state.email = undefined;
      state.firstName = undefined;
      state.lastName = undefined;
      state.phoneNumber = undefined;
      state.profile = undefined;
      state.lastUpdate = 0;
      state.id = undefined;
    },
  },
});

export const {
  enableProfileLoading,
  disableProfileLoading,
  setProfile,
  resetProfile,
} = profile.actions;
