import { setAllEvents } from "../../redux/features/events";
import BASE_URL from "../../utils/api";
import endpoints from "../../utils/endpoints";
import refreshTokenFunction from "../auth/refreshToken";

const getEvents = async (data) => {
  let responseStatus = 0;
  let token = data.token;
  let refreshToken = data.refreshToken;
  const tryFetch = async (fetchToken) => {
    try {
      let fetchEvents = await fetch(`${BASE_URL}${endpoints.getEvents}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken}`,
        },
        signal: AbortSignal.timeout(3000),
      });
      responseStatus = fetchEvents.status;
      if (fetchEvents.status === 200) {
        console.log("Get events function : 200");
        let fetchData = await fetchEvents.json();
        data.dispatch(
          setAllEvents({
            allEvents:
              fetchData["hydra:totalItems"] > 0
                ? fetchData["hydra:member"]
                : [],
            update: Date.now(),
          })
        );
      }
    } catch (error) {
      responseStatus = error.status;
    }
  };

  await tryFetch(token);

  if (responseStatus === 401) {
    console.log("Get events function : 401");
    let refreshFunction = await refreshTokenFunction({
      refreshToken: refreshToken,
      dispatch: data.dispatch,
    });
    if (refreshFunction.status === 200) {
      token = refreshFunction.token;
      refreshToken = refreshFunction.refreshToken;
      await tryFetch(refreshFunction.token);
    }
  }
  responseStatus !== 200 &&
    data.addSnack("Impossible de récupérer les évènements actifs.", {
      variant: "error",
    });
  return {
    status: responseStatus,
    token: token,
    refreshToken: refreshToken,
    mustLoading: data.mustLoading,
  };
};

export default getEvents;
