// src/App.js
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { store } from "./redux/store/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./redux/store/store";
import { ThemeProvider, createTheme, styled } from "@mui/material";
import { colorsTheme } from "./assets/theme/theme";
import Body from "./pages/Body";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";

const App = () => {
  const theme = createTheme(colorsTheme);

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    display: "flex",
    // justifyContent: 'center',
    alignItems: "center",
    "&.notistack-MuiContent-success": {
      fontFamily: "Trenda Regular",
    },
    "&.notistack-MuiContent-error": {
      fontFamily: "Trenda Regular",
    },
    "&.notistack-MuiContent-info": {
      fontFamily: "Trenda Regular",
    },
  }));

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{
            fontFamily: "Trenda Regular",
          }}
          Components={{
            success: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent,
            info: StyledMaterialDesignContent,
          }}
        >
          <Router>
            <ThemeProvider theme={theme}>
              <div
                style={{
                  minHeight: "100vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Header />
                <Body />
                <Footer />
              </div>
            </ThemeProvider>
          </Router>
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
