import { Box, Typography } from "@mui/material";

export const questions = [
  {
    question: 1,
    title: "Question 1",
    response: (
      <Box>
        <Typography sx={{ fontSize: 14 }}>Response 1</Typography>
      </Box>
    ),
  },
  {
    question: 2,
    title: "Question 2",
    response: (
      <Box>
        <Typography sx={{ fontSize: 14 }}>Response 2</Typography>
      </Box>
    ),
  },
  {
    question: 3,
    title: "Question 3",
    response: (
      <Box>
        <Typography sx={{ fontSize: 14 }}>Response 3</Typography>
      </Box>
    ),
  },
  {
    question: 4,
    title: "Question 4",
    response: (
      <Box>
        <Typography sx={{ fontSize: 14 }}>Response 4</Typography>
      </Box>
    ),
  },
];
