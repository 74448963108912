import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { useDispatch, useSelector } from "react-redux";
import {
  setPeriodDefineDay,
  setPeriodDefineMonth,
  setPeriodDefineYear,
} from "../../redux/features/conso";
import { useEffect, useState } from "react";
import { colorsTheme } from "../../assets/theme/theme";

const PeriodDefine = () => {
  const configRedux = useSelector((state) => state.config);
  const consoRedux = useSelector((state) => state.conso);
  const boardsRedux = useSelector((state) => state.boards);

  const dispatch = useDispatch();

  const [newYear, setNewYear] = useState(consoRedux.periodDefineYear);
  const [newMonthMonth, setNewMonthMonth] = useState(
    consoRedux.periodDefineMonthMonth
  );
  const [newMonthYear, setNewMonthYear] = useState(
    consoRedux.periodDefineMonthYear
  );
  const [newDayDay, setNewDayDay] = useState(consoRedux.periodDefineDayDay);
  const [newDayMonth, setNewDayMonth] = useState(
    consoRedux.periodDefineDayMonth
  );
  const [newDayYear, setNewDayYear] = useState(consoRedux.periodDefineDayYear);

  const today = dayjs();
  const minDate = dayjs()
    .year(
      new Date(
        boardsRedux.boards[boardsRedux.actualBoard - 1].createdAt
      ).getFullYear()
    )
    .month(
      new Date(
        boardsRedux.boards[boardsRedux.actualBoard - 1].createdAt
      ).getMonth()
    )
    .date(
      new Date(
        boardsRedux.boards[boardsRedux.actualBoard - 1].createdAt
      ).getDate()
    )
    .hour(0)
    .minute(0)
    .second(1);

  useEffect(() => {
    setNewYear(consoRedux.periodDefineYear);
    setNewMonthMonth(consoRedux.periodDefineMonthMonth);
    setNewMonthYear(consoRedux.periodDefineMonthYear);
    setNewDayDay(consoRedux.periodDefineDayDay);
    setNewDayMonth(consoRedux.periodDefineDayMonth);
    setNewDayYear(consoRedux.periodDefineDayYear); // eslint-disable-next-line
  }, [consoRedux.periodChoice]);

  return (
    consoRedux.periodChoice !== 4 && (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <DatePicker
          disabled={consoRedux.isLoading}
          format={
            consoRedux.periodChoice === 0
              ? "DD / MM / YYYY"
              : consoRedux.periodChoice === 1
              ? "DD / MM / YYYY"
              : consoRedux.periodChoice === 2
              ? "MM / YYYY"
              : consoRedux.periodChoice === 3 && "YYYY"
          }
          slotProps={{ textField: { size: "small" } }}
          yearsPerRow={3}
          value={
            consoRedux.periodChoice === 0
              ? dayjs().date(newDayDay).month(newDayMonth).year(newDayYear)
              : consoRedux.periodChoice === 1
              ? dayjs().date(newDayDay).month(newDayMonth).year(newDayYear)
              : consoRedux.periodChoice === 2
              ? dayjs().date(1).month(newMonthMonth).year(newMonthYear)
              : consoRedux.periodChoice === 3 &&
                dayjs().date(1).month(1).year(newYear)
          }
          maxDate={today}
          minDate={minDate}
          sx={{
            position: "relative",
            width: "100%",
            backgroundColor: `${
              configRedux.darkMode ? "dark" : "light"
            }.secondary`,
            borderRadius: 2,
            "& .MuiInputBase-input": {
              height: 31,
              textAlign: "center",
              color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
            },
            "& .Mui-disabled": {
              WebkitTextFillColor:
                colorsTheme.palette[configRedux.darkMode ? "dark" : "light"]
                  .primary,
            },
            "& .MuiSvgIcon-root": {
              fill: colorsTheme.palette[configRedux.darkMode ? "dark" : "light"]
                .primary,
            },
          }}
          label={""}
          onError={() => {
            if (consoRedux.periodChoice === 3) {
              setNewYear(consoRedux.periodDefineYear);
            } else if (consoRedux.periodChoice === 2) {
              setNewMonthMonth(consoRedux.periodDefineMonthMonth);
              setNewMonthYear(consoRedux.periodDefineMonthYear);
            } else if (consoRedux.periodChoice === 1) {
              setNewDayDay(consoRedux.periodDefineDayDay);
              setNewDayMonth(consoRedux.periodDefineDayMonth);
              setNewDayYear(consoRedux.periodDefineDayYear);
            }
          }}
          onAccept={(newValue) => {
            const dateNow = dayjs().hour(23).minute(59).second(59);
            const creationBoardDate = minDate;
            const inputDate = dayjs()
              .year(newValue.$y)
              .month(newValue.$M)
              .date(newValue.$D)
              .hour(12)
              .minute(0)
              .second(0);
            if (
              dateNow.diff(inputDate) > 0 &&
              inputDate.diff(creationBoardDate) > 0
            ) {
              if (consoRedux.periodChoice === 3) {
                setNewYear(newValue.$y);
                dispatch(setPeriodDefineYear({ year: newValue.$y }));
              } else if (consoRedux.periodChoice === 2) {
                setNewMonthMonth(newValue.$M);
                setNewMonthYear(newValue.$y);
                dispatch(
                  setPeriodDefineMonth({
                    year: newValue.$y,
                    month: newValue.$M,
                  })
                );
              } else if (consoRedux.periodChoice === 1) {
                setNewDayDay(newValue.$D);
                setNewDayMonth(newValue.$M);
                setNewDayYear(newValue.$y);
                dispatch(
                  setPeriodDefineDay({
                    year: newValue.$y,
                    month: newValue.$M,
                    day: newValue.$D,
                  })
                );
              }
            } else {
              if (consoRedux.periodChoice === 3) {
                setNewYear(consoRedux.periodDefineYear);
              } else if (consoRedux.periodChoice === 2) {
                setNewMonthMonth(consoRedux.periodDefineMonthMonth);
                setNewMonthYear(consoRedux.periodDefineMonthYear);
              } else if (consoRedux.periodChoice === 1) {
                setNewDayDay(consoRedux.periodDefineDayDay);
                setNewDayMonth(consoRedux.periodDefineDayMonth);
                setNewDayYear(consoRedux.periodDefineDayYear);
              }
            }
          }}
          views={
            consoRedux.periodChoice === 0
              ? ["day", "month", "year"]
              : consoRedux.periodChoice === 1
              ? ["day", "month", "year"]
              : consoRedux.periodChoice === 2
              ? ["month", "year"]
              : consoRedux.periodChoice === 3 && ["year"]
          }
        />
      </LocalizationProvider>
    )
  );
};

export default PeriodDefine;
