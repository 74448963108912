// src/pages/HomePage.js
import React, { useEffect } from "react";
import { AppBar, Tabs, Tab, Box, Container, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoginPage from "../components/login/LoginPage";
import RegisterPage from "../components/signup/RegisterPage";
import { switchToLogin, switchToRegister } from "../redux/features/auth";
import { breakpoints } from "../utils/responsive";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function WelcomePage() {
  const authRedux = useSelector((state) => state.auth);
  const configRedux = useSelector((state) => state.config);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleTabChange = (event, newValue) => {
    newValue === 0 && dispatch(switchToLogin());
    newValue === 1 && dispatch(switchToRegister());
  };

  useEffect(() => {
    location.pathname === "/" &&
      authRedux.welcomeComponent === 1 &&
      dispatch(switchToLogin());
    location.pathname === "/signin" &&
      authRedux.welcomeComponent === 1 &&
      dispatch(switchToLogin());
    location.pathname === "/signup" &&
      authRedux.welcomeComponent === 0 &&
      dispatch(switchToRegister());
    authRedux.status && navigate("/home"); // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <Container
      sx={{
        pl: 0,
        pr: 0,
        flexBasis: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        pt: configRedux.screenWidth > breakpoints.minTablet ? 5 : 0,
        alignItems: "center",
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          backgroundColor: `rgba(${
            configRedux.darkMode ? "15,15,15" : "255,255,255"
          },0.8)`,
          position: "relative",
          paddingTop: 3,
          paddingBottom: 2,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pl: 0,
          pr: 0,
        }}
      >
        {configRedux.screenWidth > breakpoints.minTablet ? (
          <AppBar
            position="static"
            color="default"
            sx={{ width: "100%", borderRadius: 2 }}
          >
            <Tabs
              value={authRedux.welcomeComponent}
              onChange={handleTabChange}
              aria-label="login register tabs"
              variant="fullWidth"
              sx={{
                backgroundColor: configRedux.darkMode
                  ? "dark.secondary"
                  : "light.secondary",
                borderRadius: 1,
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: configRedux.darkMode ? "#00ff93" : "#00c321",
                  height: "100%",
                  borderRadius: 4,
                },
              }}
            >
              <Tab
                aria-label="tabs-button-connect"
                label="Se connecter"
                sx={{
                  color: configRedux.darkMode
                    ? "dark.primary"
                    : "light.primary",
                  zIndex: 2,
                  "&.Mui-selected": {
                    color: configRedux.darkMode
                      ? "dark.secondary"
                      : "light.secondary",
                  },
                  textTransform: "none",
                }}
                onClick={() => {
                  location.pathname !== "/signin" &&
                    location.pathname !== "/" &&
                    navigate("/signin");
                  dispatch(switchToLogin());
                }}
              />
              <Tab
                aria-label="tabs-button-register"
                label="S'inscrire"
                sx={{
                  color: configRedux.darkMode
                    ? "dark.primary"
                    : "light.primary",
                  zIndex: 2,
                  "&.Mui-selected": {
                    color: configRedux.darkMode
                      ? "dark.secondary"
                      : "light.secondary",
                  },
                  textTransform: "none",
                }}
                onClick={() => {
                  location.pathname !== "/signup" && navigate("/signup");
                  dispatch(switchToRegister());
                }}
              />
            </Tabs>
          </AppBar>
        ) : (
          <Typography sx={{ fontSize: 18, fontWeight: "500" }}>
            {authRedux.welcomeComponent === 0 ? "Connexion" : "Inscription"}
          </Typography>
        )}
        <TabPanel value={authRedux.welcomeComponent} index={0}>
          <LoginPage />
        </TabPanel>
        <TabPanel value={authRedux.welcomeComponent} index={1}>
          <RegisterPage />
        </TabPanel>
      </Container>
    </Container>
  );
}
