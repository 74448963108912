import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import pictos from "../../utils/pictos";
import { breakpoints } from "../../utils/responsive";

const HigherConso = () => {
  const configRedux = useSelector((state) => state.config);
  const circuitsRedux = useSelector((state) => state.circuits);
  return (
    <Box
      sx={{
        backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.secondary`,
        pl: 2,
        pb: 2,
        pr: 2,
        pt: 5,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        rowGap: 2,
        boxShadow: 3,
        flexBasis: "70%",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: -12,
          left: -12,
          backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
          borderRadius: 4,
          pl: 1,
          pr: 2,
          // width: 32,
          height: 32,
          display: "flex",
          flexDirection: "row",
          columnGap: 1,
          justifyContent: "center",
          alignItems: "center",
          boxShadow: 5,
        }}
      >
        <ElectricBoltIcon />
        <Typography
          sx={{
            color: configRedux.darkMode ? "dark.secondary" : "light.primary",
          }}
        >
          {configRedux.screenWidth > breakpoints.minTablet
            ? "Les circuits qui consomment le plus en ce moment"
            : "Les circuits les plus consommateurs"}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection:
            configRedux.screenWidth > breakpoints.minTablet ? "row" : "column",
          rowGap: 2,
          width: "100%",
          // alignItems: "center",
          justifyContent: "space-around",
          columnGap: 2,
        }}
      >
        {circuitsRedux.boardCircuitsHigherConso.length > 0 &&
          circuitsRedux.boardCircuitsHigherConso
            // CHANGE IF ID > LIST LENGTH
            .slice(0, 3)
            .map((oneCircuit) => {
              return (
                <Box
                  key={`${oneCircuit.id}${oneCircuit.name}`}
                  sx={{
                    flexBasis: "100%",
                    // width: "calc(100% - 48px - 16px)",
                    display: "flex",
                    flexDirection:
                      configRedux.screenWidth > breakpoints.minTablet
                        ? "column"
                        : "row",
                    alignItems: "center",
                    justifyContent:
                      configRedux.screenWidth > breakpoints.minTablet
                        ? "auto"
                        : "space-between",
                    rowGap: 1,
                    boxShadow: 2,
                    p: 1,
                    backgroundColor: `${
                      configRedux.darkMode ? "dark" : "light"
                    }.background`,
                    borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection:
                        configRedux.screenWidth > breakpoints.minTablet
                          ? "column"
                          : "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: `${
                          configRedux.darkMode ? "dark" : "light"
                        }.background`,
                        p: 1,
                        borderRadius: 2,
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: `${
                            configRedux.darkMode ? "dark" : "light"
                          }.secondary`,
                          padding: 1,
                          borderRadius: 2,
                          boxShadow: 1,
                        }}
                      >
                        <img
                          alt={
                            pictos[
                              oneCircuit.pictogramme.id < 63
                                ? oneCircuit.pictogramme.id - 1
                                : 5
                            ].name
                          }
                          src={
                            pictos[
                              oneCircuit.pictogramme.id < 63
                                ? oneCircuit.pictogramme.id - 1
                                : 5
                            ].picto
                          }
                          style={{
                            filter: configRedux.darkMode
                              ? "brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(1%) hue-rotate(198deg) brightness(87%) contrast(98%)"
                              : "brightness(0) saturate(100%) invert(3%) sepia(8%) saturate(6%) hue-rotate(332deg) brightness(99%) contrast(101%)",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap:
                          configRedux.screenWidth > breakpoints.minTablet
                            ? 1
                            : 0,
                        alignItems:
                          configRedux.screenWidth > breakpoints.minTablet
                            ? "center"
                            : "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          color: `${
                            configRedux.darkMode ? "dark" : "light"
                          }.primary`,
                          textAlign: "center",
                        }}
                      >
                        {oneCircuit.name}
                      </Typography>
                      <Typography
                        sx={{
                          color: `${
                            configRedux.darkMode ? "dark" : "light"
                          }.primary`,
                          textAlign: "center",
                        }}
                      >
                        {oneCircuit.location}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      color: `${configRedux.darkMode ? "dark" : "light"}.link`,
                      textAlign: "center",
                    }}
                  >
                    {Math.round(
                      oneCircuit.consommationInstantanees[0].consommation
                    )}{" "}
                    W
                  </Typography>
                </Box>
              );
            })}
      </Box>
    </Box>
  );
};

export default HigherConso;
