import { TextField } from "@mui/material";
import { useSelector } from "react-redux";

const TextFieldWelcome = (data) => {
  const configRedux = useSelector((state) => state.config);
  return (
    <TextField
      sx={{
        "& label": {
          color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
        },
        "& label.Mui-focused": {
          color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
        },
        "& .MuiInput-root": {
          "&:after": {
            borderColor: configRedux.darkMode
              ? "dark.primary"
              : "light.primary",
            borderWidth: "2px",
          },
          "&:before": {
            borderColor: `${
              configRedux.darkMode ? "dark" : "light"
            }.button.before`,
          },
          ":hover:not(.Mui-focused)": {
            "&:before": {
              borderColor: `${
                configRedux.darkMode ? "dark" : "light"
              }.button.hover`,
              borderWidth: "2px",
            },
          },
        },
        input: {
          color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
        },
      }}
      margin="normal"
      type={data.data.type}
      error={data.data.error}
      helperText={data.data.error ? data.data.errorText : ""}
      required
      fullWidth
      id={data.data.id}
      inputProps={{ "data-testid": data.data.testId }}
      label={data.data.label}
      name={data.data.id}
      autoComplete={data.data.autoComplete}
      value={data.data.value}
      onChange={data.data.onChange}
      size="small"
      variant="standard"
    />
  );
};

export default TextFieldWelcome;
