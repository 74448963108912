import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import {
  disableBoardsLoading,
  enableBoardsLoading,
} from "../../redux/features/boards";
import getBoards from "../../functions/boards/getBoards";
import {
  disableEventsLoading,
  enableEventsLoading,
} from "../../redux/features/events";
import getActiveEvents from "../../functions/events/getActiveEvents";
import getEvents from "../../functions/events/getEvents";
import {
  disableCircuitsLoading,
  enableCircuitsLoading,
} from "../../redux/features/circuits";
import getCircuits from "../../functions/circuits/getCircuits";
import getBoardCircuits from "../../functions/circuits/getBoardCircuits";
import getBoardActiveEvents from "../../functions/events/getBoardActiveEvents";
import getBoardEvents from "../../functions/events/getBoardEvents";
import { makeBoardCircuitsResult } from "../../functions/circuits/makeBoardCircuitsResult";
import { autoRefreshValues } from "../../utils/autoRefreshValues";
import {
  changeFaqQuestionOpened,
  changeScreenWidth,
  clearMustRefresh,
} from "../../redux/features/config";
import loginBackgroundAvif from "../../assets/images/background/bg1.avif";
import loginBackground1024Avif from "../../assets/images/background/bg1H1024.avif";
import loginBackground800Avif from "../../assets/images/background/bg1H800.avif";
import loginBackground768Avif from "../../assets/images/background/bg1H768.avif";
import { pageTitle } from "../../utils/pageTitle";
import {
  disableProfileLoading,
  enableProfileLoading,
} from "../../redux/features/profile";
import getProfile from "../../functions/settings/getProfile";
import getConso from "../../functions/conso/getConso";
import {
  disableConsoLoading,
  enableConsoLoading,
  setActualDay,
  setActualFetched,
  setActualMonth,
  setActualYear,
  setConsoResult,
  setLastUpdate,
} from "../../redux/features/conso";
import { makeConsoYear } from "../../functions/conso/makeConsoYear";
import { makeConsoAll } from "../../functions/conso/makeConsoAll";
import { makeConsoMonth } from "../../functions/conso/makeConsoMonth";
import { makeConsoDay } from "../../functions/conso/makeConsoDay";
import {
  disableAnalyseLoading,
  enableAnalyseLoading,
  setAnalyseActualDay,
  setAnalyseActualFetched,
  setAnalyseActualMonth,
  setAnalyseActualYear,
  setAnalyseLastUpdate,
  setAnalyseResult,
} from "../../redux/features/analyse";
import { makeAnalyse } from "../../functions/analyse/makeAnalyse";

const Configurator = () => {
  const authRedux = useSelector((state) => state.auth);
  const boardsRedux = useSelector((state) => state.boards);
  const circuitsRedux = useSelector((state) => state.circuits);
  const eventsRedux = useSelector((state) => state.events);
  const configRedux = useSelector((state) => state.config);
  const profileRedux = useSelector((state) => state.profile);
  const consoRedux = useSelector((state) => state.conso);
  const analyseRedux = useSelector((state) => state.analyse);

  const dispatch = useDispatch();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState();

  // Fetch boards
  const fetchBoards = async (data) => {
    if (!boardsRedux.isLoading) {
      data.mustLoading && dispatch(enableBoardsLoading());
      let tryFetch = await getBoards({
        token: data.token,
        refreshToken: data.refreshToken,
        dispatch: dispatch,
        actual: data.actualBoard,
        mustLoading: data.mustLoading,
        addSnack: enqueueSnackbar,
      });
      data.mustLoading && dispatch(disableBoardsLoading());
      return tryFetch;
    }
  };

  // Fetch events and active events
  const fetchEvents = async (data) => {
    if (!eventsRedux.isLoading) {
      data.mustLoading && dispatch(enableEventsLoading());
      let tryFetch1 = await getActiveEvents({
        token: data.token,
        refreshToken: data.refreshToken,
        dispatch: dispatch,
        addSnack: enqueueSnackbar,
        mustLoading: data.mustLoading,
      });
      await getEvents({
        token: tryFetch1.token,
        refreshToken: tryFetch1.refreshToken,
        dispatch: dispatch,
        addSnack: enqueueSnackbar,
        mustLoading: data.mustLoading,
      });
      data.mustLoading && dispatch(disableEventsLoading());
    }
  };

  // Fetch circuits
  const fetchCircuits = async (data) => {
    if (!circuitsRedux.circuitsIsLoading) {
      data.mustLoading && dispatch(enableCircuitsLoading());
      let tryFetch = await getCircuits({
        token: data.token,
        refreshToken: data.refreshToken,
        dispatch: dispatch,
        addSnack: enqueueSnackbar,
        mustLoading: data.mustLoading,
      });
      data.mustLoading && dispatch(disableCircuitsLoading());
      return tryFetch;
    }
  };

  // Fetch profile
  const fetchProfile = async (data) => {
    if (!profileRedux.circuitsIsLoading) {
      data.mustLoading && dispatch(enableProfileLoading());
      let tryFetch = await getProfile({
        token: data.token,
        refreshToken: data.refreshToken,
        dispatch: dispatch,
        addSnack: enqueueSnackbar,
        mustLoading: data.mustLoading,
      });
      data.mustLoading && dispatch(disableProfileLoading());
      return tryFetch;
    }
  };

  // Auto refresh data (boards, circuits, events, active events) if time limit is over
  const refreshData = async () => {
    if (authRedux.status) {
      if (
        location.pathname === "/profile" &&
        Date.now() > profileRedux.lastUpdate + autoRefreshValues.profile
      ) {
        await updateProfile({
          token: authRedux.token,
          refreshToken: authRedux.refreshToken,
          actualBoard: boardsRedux.actualBoard,
          mustLoading: true,
        });
      }
      if (Date.now() > boardsRedux.lastUpdate + autoRefreshValues.boards) {
        await updateBoards({
          token: authRedux.token,
          refreshToken: authRedux.refreshToken,
          actualBoard: boardsRedux.actualBoard,
          mustLoading: true,
        });
      } else if (
        Date.now() >
        circuitsRedux.lastUpdate + autoRefreshValues.circuits
      ) {
        await updateCircuits({
          token: authRedux.token,
          refreshToken: authRedux.refreshToken,
          mustLoading: true,
        });
      } else if (
        Date.now() >
        eventsRedux.lastUpdate + autoRefreshValues.events
      ) {
        await updateEvents({
          token: authRedux.token,
          refreshToken: authRedux.refreshToken,
          mustLoading: true,
        });
      }
    }
  };

  // Global function: update conso
  const updateConso = async (data) => {
    let initialActualDay = consoRedux.actualDay;
    let initialActualMonth = consoRedux.actualMonth;
    let initialActualYear = consoRedux.actualYear;
    let fetchedData = consoRedux.actualFetched;

    dispatch(enableConsoLoading());

    // Add 0 if < 10
    const initialZero = (num) => (num < 10 ? `0${num}` : num);

    // Base by Hour
    let base = new Date(Date.now());
    let beginDateHour = `${base.getFullYear()}-${initialZero(
      base.getMonth() + 1
    )}-${initialZero(base.getDate())} 00:00:00`;
    let endDateHour = `${base.getFullYear()}-${initialZero(
      base.getMonth() + 1
    )}-${initialZero(base.getDate())} ${initialZero(
      base.getHours()
    )}:${initialZero(base.getMinutes())}:${initialZero(base.getSeconds())}`;

    // Base by Day
    let dayBeginBase = new Date(base.getFullYear(), base.getMonth(), 1);
    let dayEndBase = base;
    dayEndBase.getDate() !== 1 && dayEndBase.setDate(base.getDate() - 1);
    let beignDateDay = `${dayBeginBase.getFullYear()}-${initialZero(
      dayBeginBase.getMonth() + 1
    )}-${initialZero(dayBeginBase.getDate())}`;
    let endDateDay = `${dayEndBase.getFullYear()}-${initialZero(
      dayEndBase.getMonth() + 1
    )}-${initialZero(dayEndBase.getDate())}`;

    // Base by Month
    let monthBeginBase = new Date(base.getFullYear(), 0, 1);
    let monthEndBase = base;
    monthEndBase.getMonth() !== 0 && monthEndBase.setMonth(base.getMonth() - 1);
    let beignDateMonth = `${monthBeginBase.getFullYear()}-${initialZero(
      monthBeginBase.getMonth() + 1
    )}`;
    let endDateMonth = `${monthEndBase.getFullYear()}-${initialZero(
      monthEndBase.getMonth() + 1
    )}`;

    let initialData = {
      boardId: boardsRedux.boards[boardsRedux.actualBoard - 1].id,
      dispatch: dispatch,
      addSnack: enqueueSnackbar,
      mustLoading: true,
    };

    if (
      !consoRedux.lastUpdate ||
      new Date(consoRedux.lastUpdate).getMonth() !==
        new Date(Date.now()).getMonth()
    ) {
      dispatch(setLastUpdate());
      let actualDay = await getConso({
        ...initialData,
        token: authRedux.token,
        refreshToken: authRedux.refreshToken,
        begin: beginDateHour,
        end: endDateHour,
        consoType: "getConsoHour",
      });
      let actualMonth = await getConso({
        ...initialData,
        token: actualDay.token,
        refreshToken: actualDay.refreshToken,
        begin: beignDateDay,
        end: endDateDay,
        consoType: "getConsoDay",
      });
      let actualYear = await getConso({
        ...initialData,
        token: actualMonth.token,
        refreshToken: actualMonth.refreshToken,
        begin: beignDateMonth,
        end: endDateMonth,
        consoType: "getConsoMonth",
      });
      initialActualDay = actualDay.result;
      initialActualMonth = actualMonth.result;
      initialActualYear = actualYear.result;
      dispatch(
        setActualYear({
          day: actualDay.result,
          month: actualMonth.result,
          year: actualYear.result,
        })
      );
    } else if (
      new Date(consoRedux.lastUpdate).getDate() !==
      new Date(Date.now()).getDate()
    ) {
      dispatch(setLastUpdate());
      let actualDay = await getConso({
        ...initialData,
        token: authRedux.token,
        refreshToken: authRedux.refreshToken,
        begin: beginDateHour,
        end: endDateHour,
        consoType: "getConsoHour",
      });
      let actualMonth = await getConso({
        ...initialData,
        token: actualDay.token,
        refreshToken: actualDay.refreshToken,
        begin: beignDateDay,
        end: endDateDay,
        consoType: "getConsoDay",
      });
      initialActualDay = actualDay.result;
      initialActualMonth = actualMonth.result;
      dispatch(
        setActualMonth({
          day: actualDay.result,
          month: actualMonth.result,
        })
      );
    } else if (
      new Date(consoRedux.lastUpdate).getHours() !==
      new Date(Date.now()).getHours()
    ) {
      dispatch(setLastUpdate());

      let actualDay = await getConso({
        ...initialData,
        token: authRedux.token,
        refreshToken: authRedux.refreshToken,
        begin: beginDateHour,
        end: endDateHour,
        consoType: "getConsoHour",
      });
      initialActualDay = actualDay.result;
      dispatch(
        setActualDay({
          day: actualDay.result,
        })
      );
    }
    data.firstFetch &&
      dispatch(
        setConsoResult({
          result: makeConsoYear({
            conso: initialActualDay.concat(
              initialActualMonth.concat(initialActualYear)
            ),
          }),
        })
      );
    if (data.updatePeriod === 3) {
      if (data.updateYear !== new Date(Date.now()).getFullYear()) {
        if (
          consoRedux.actualFetchedPeriod !== 3 ||
          consoRedux.actualFetchedPeriodDetail !== data.updateYear
        ) {
          let beginDate = `${data.updateYear}-01`;
          let endDate = `${data.updateYear}-12`;
          let newData = await getConso({
            boardId: boardsRedux.boards[boardsRedux.actualBoard - 1].id,
            dispatch: dispatch,
            addSnack: enqueueSnackbar,
            mustLoading: true,
            token: authRedux.token,
            refreshToken: authRedux.refreshToken,
            begin: beginDate,
            end: endDate,
            consoType: "getConsoMonth",
          });
          fetchedData = newData.result;
          dispatch(
            setActualFetched({
              fetched: fetchedData,
              period: 3,
              periodDetail: data.updateYear,
            })
          );
        } else {
          fetchedData = consoRedux.actualFetched;
        }
        dispatch(
          setConsoResult({
            result: makeConsoYear({
              conso: fetchedData,
            }),
          })
        );
      } else {
        dispatch(
          setConsoResult({
            result: makeConsoYear({
              conso: initialActualDay.concat(
                initialActualMonth.concat(initialActualYear)
              ),
            }),
          })
        );
      }
    } else if (data.updatePeriod === 4) {
      if (consoRedux.actualFetchedPeriod !== 4) {
        let beginDate = `${new Date(
          boardsRedux.boards[boardsRedux.actualBoard - 1].createdAt
        ).getFullYear()}-${initialZero(
          new Date(
            boardsRedux.boards[boardsRedux.actualBoard - 1].createdAt
          ).getMonth() + 1
        )}`;
        let endDate = `${new Date(Date.now()).getFullYear()}-12`;
        let newData = await getConso({
          boardId: boardsRedux.boards[boardsRedux.actualBoard - 1].id,
          dispatch: dispatch,
          addSnack: enqueueSnackbar,
          mustLoading: true,
          token: authRedux.token,
          refreshToken: authRedux.refreshToken,
          begin: beginDate,
          end: endDate,
          consoType: "getConsoMonth",
        });
        fetchedData = newData.result;
        dispatch(
          setActualFetched({
            fetched: fetchedData,
            period: 4,
            periodDetail: "all",
          })
        );
      } else {
        fetchedData = consoRedux.actualFetched;
      }
      dispatch(
        setConsoResult({
          result: makeConsoAll({
            conso: fetchedData.concat(
              initialActualDay.concat(
                initialActualMonth.concat(initialActualYear)
              )
            ),
          }),
        })
      );
    } else if (data.updatePeriod === 2) {
      if (
        data.updateMonthYear !== new Date(Date.now()).getFullYear() ||
        data.updateMonthMonth !== new Date(Date.now()).getMonth()
      ) {
        if (
          consoRedux.actualFetchedPeriod !== 2 ||
          consoRedux.actualFetchedPeriodDetail !==
            `${data.updateMonthYear}-${data.updateMonthMonth}`
        ) {
          let beginDate = `${data.updateMonthYear}-${initialZero(
            data.updateMonthMonth + 1
          )}-01`;
          let endDate = `${data.updateMonthYear}-${initialZero(
            data.updateMonthMonth + 1
          )}-${new Date(
            data.updateMonthYear,
            data.updateMonthMonth + 1,
            0
          ).getDate()}`;
          let newData = await getConso({
            boardId: boardsRedux.boards[boardsRedux.actualBoard - 1].id,
            dispatch: dispatch,
            addSnack: enqueueSnackbar,
            mustLoading: true,
            token: authRedux.token,
            refreshToken: authRedux.refreshToken,
            begin: beginDate,
            end: endDate,
            consoType: "getConsoDay",
          });
          fetchedData = newData.result;
          dispatch(
            setActualFetched({
              fetched: fetchedData,
              period: 2,
              periodDetail: `${data.updateMonthYear}-${data.updateMonthMonth}`,
            })
          );
        } else {
          fetchedData = consoRedux.actualFetched;
        }
        dispatch(
          setConsoResult({
            result: makeConsoMonth({
              conso: fetchedData,
            }),
          })
        );
      } else {
        dispatch(
          setConsoResult({
            result: makeConsoMonth({
              conso: initialActualDay.concat(initialActualMonth),
            }),
          })
        );
      }
    } else if (data.updatePeriod === 1) {
      if (
        data.updateDayYear !== new Date(Date.now()).getFullYear() ||
        data.updateDayhMonth !== new Date(Date.now()).getMonth() ||
        data.updateDayDay !== new Date(Date.now()).getDate()
      ) {
        if (
          consoRedux.actualFetchedPeriod !== 1 ||
          consoRedux.actualFetchedPeriodDetail !==
            `${data.updateDayYear}-${data.updateDayMonth}-${data.updateDayDay}`
        ) {
          let beginDate = `${new Date(
            data.updateDayYear,
            data.updateDayMonth,
            data.updateDayDay
          ).getFullYear()}-${initialZero(
            new Date(
              data.updateDayYear,
              data.updateDayMonth,
              data.updateDayDay
            ).getMonth() + 1
          )}-${initialZero(
            new Date(
              data.updateDayYear,
              data.updateDayMonth,
              data.updateDayDay
            ).getDate()
          )} 00:00:00`;
          let endDate = `${new Date(
            data.updateDayYear,
            data.updateDayMonth,
            data.updateDayDay + 1
          ).getFullYear()}-${initialZero(
            new Date(
              data.updateDayYear,
              data.updateDayMonth,
              data.updateDayDay + 1
            ).getMonth() + 1
          )}-${initialZero(
            new Date(
              data.updateDayYear,
              data.updateDayMonth,
              data.updateDayDay + 1
            ).getDate()
          )} 00:00:00`;
          let newData = await getConso({
            boardId: boardsRedux.boards[boardsRedux.actualBoard - 1].id,
            dispatch: dispatch,
            addSnack: enqueueSnackbar,
            mustLoading: true,
            token: authRedux.token,
            refreshToken: authRedux.refreshToken,
            begin: beginDate,
            end: endDate,
            consoType: "getConsoHour",
          });
          fetchedData = newData.result;
          dispatch(
            setActualFetched({
              fetched: fetchedData,
              period: 1,
              periodDetail: `${data.updateDayYear}-${data.updateDayMonth}-${data.updateDayDay}`,
            })
          );
        } else {
          fetchedData = consoRedux.actualFetched;
        }
        dispatch(
          setConsoResult({
            result: makeConsoDay({
              conso: fetchedData,
            }),
          })
        );
      } else {
        dispatch(
          setConsoResult({
            result: makeConsoDay({ conso: initialActualDay }),
          })
        );
      }
    }
    dispatch(disableConsoLoading());
  };

  // Global function: update analyse
  const updateAnalyse = async (data) => {
    let initialActualDay = analyseRedux.actualDay;
    let initialActualMonth = analyseRedux.actualMonth;
    let initialActualYear = analyseRedux.actualYear;
    let fetchedData = analyseRedux.actualFetched;

    dispatch(enableAnalyseLoading());

    // Add 0 if < 10
    const initialZero = (num) => (num < 10 ? `0${num}` : num);

    // Base by Hour
    let base = new Date(Date.now());
    let beginDateHour = `${base.getFullYear()}-${initialZero(
      base.getMonth() + 1
    )}-${initialZero(base.getDate())} 00:00:00`;
    let endDateHour = `${base.getFullYear()}-${initialZero(
      base.getMonth() + 1
    )}-${initialZero(base.getDate())} ${initialZero(
      base.getHours()
    )}:${initialZero(base.getMinutes())}:${initialZero(base.getSeconds())}`;

    // Base by Day
    let dayBeginBase = new Date(base.getFullYear(), base.getMonth(), 1);
    let dayEndBase = base;
    dayEndBase.getDate() !== 1 && dayEndBase.setDate(base.getDate() - 1);
    let beignDateDay = `${dayBeginBase.getFullYear()}-${initialZero(
      dayBeginBase.getMonth() + 1
    )}-${initialZero(dayBeginBase.getDate())}`;
    let endDateDay = `${dayEndBase.getFullYear()}-${initialZero(
      dayEndBase.getMonth() + 1
    )}-${initialZero(dayEndBase.getDate())}`;

    // Base by Month
    let monthBeginBase = new Date(base.getFullYear(), 0, 1);
    let monthEndBase = base;
    monthEndBase.getMonth() !== 0 && monthEndBase.setMonth(base.getMonth() - 1);
    let beignDateMonth = `${monthBeginBase.getFullYear()}-${initialZero(
      monthBeginBase.getMonth() + 1
    )}`;
    let endDateMonth = `${monthEndBase.getFullYear()}-${initialZero(
      monthEndBase.getMonth() + 1
    )}`;

    let initialData = {
      boardId: boardsRedux.boards[boardsRedux.actualBoard - 1].id,
      dispatch: dispatch,
      addSnack: enqueueSnackbar,
      mustLoading: true,
    };

    if (
      !analyseRedux.lastUpdate ||
      new Date(analyseRedux.lastUpdate).getMonth() !==
        new Date(Date.now()).getMonth()
    ) {
      dispatch(setAnalyseLastUpdate());
      let actualDay = await getConso({
        ...initialData,
        token: authRedux.token,
        refreshToken: authRedux.refreshToken,
        begin: beginDateHour,
        end: endDateHour,
        consoType: "getConsoHour",
      });
      let actualMonth = await getConso({
        ...initialData,
        token: actualDay.token,
        refreshToken: actualDay.refreshToken,
        begin: beignDateDay,
        end: endDateDay,
        consoType: "getConsoDay",
      });
      let actualYear = await getConso({
        ...initialData,
        token: actualMonth.token,
        refreshToken: actualMonth.refreshToken,
        begin: beignDateMonth,
        end: endDateMonth,
        consoType: "getConsoMonth",
      });
      initialActualDay = actualDay.result;
      initialActualMonth = actualMonth.result;
      initialActualYear = actualYear.result;
      dispatch(
        setAnalyseActualYear({
          day: actualDay.result,
          month: actualMonth.result,
          year: actualYear.result,
        })
      );
    } else if (
      new Date(analyseRedux.lastUpdate).getDate() !==
      new Date(Date.now()).getDate()
    ) {
      dispatch(setAnalyseLastUpdate());
      let actualDay = await getConso({
        ...initialData,
        token: authRedux.token,
        refreshToken: authRedux.refreshToken,
        begin: beginDateHour,
        end: endDateHour,
        consoType: "getConsoHour",
      });
      let actualMonth = await getConso({
        ...initialData,
        token: actualDay.token,
        refreshToken: actualDay.refreshToken,
        begin: beignDateDay,
        end: endDateDay,
        consoType: "getConsoDay",
      });
      initialActualDay = actualDay.result;
      initialActualMonth = actualMonth.result;
      dispatch(
        setAnalyseActualMonth({
          day: actualDay.result,
          month: actualMonth.result,
        })
      );
    } else if (
      new Date(analyseRedux.lastUpdate).getHours() !==
      new Date(Date.now()).getHours()
    ) {
      dispatch(setAnalyseLastUpdate());

      let actualDay = await getConso({
        ...initialData,
        token: authRedux.token,
        refreshToken: authRedux.refreshToken,
        begin: beginDateHour,
        end: endDateHour,
        consoType: "getConsoHour",
      });
      initialActualDay = actualDay.result;
      dispatch(
        setAnalyseActualDay({
          day: actualDay.result,
        })
      );
    }
    data.firstFetch &&
      dispatch(
        setAnalyseResult({
          result: makeAnalyse({
            conso: initialActualDay.concat(initialActualMonth),
          }),
        })
      );
    if (data.updatePeriod === 1) {
      if (data.updateYear !== new Date(Date.now()).getFullYear()) {
        if (
          analyseRedux.actualFetchedPeriod !== 1 ||
          analyseRedux.actualFetchedPeriodDetail !== data.updateYear
        ) {
          let beginDate = `${data.updateYear}-01`;
          let endDate = `${data.updateYear}-12`;
          let newData = await getConso({
            boardId: boardsRedux.boards[boardsRedux.actualBoard - 1].id,
            dispatch: dispatch,
            addSnack: enqueueSnackbar,
            mustLoading: true,
            token: authRedux.token,
            refreshToken: authRedux.refreshToken,
            begin: beginDate,
            end: endDate,
            consoType: "getConsoMonth",
          });
          fetchedData = newData.result;
          dispatch(
            setAnalyseActualFetched({
              fetched: fetchedData,
              period: 1,
              periodDetail: data.updateYear,
            })
          );
        } else {
          fetchedData = analyseRedux.actualFetched;
        }
        dispatch(
          setAnalyseResult({
            result: makeAnalyse({
              conso: fetchedData,
            }),
          })
        );
      } else {
        dispatch(
          setAnalyseResult({
            result: makeAnalyse({
              conso: initialActualDay.concat(
                initialActualMonth.concat(initialActualYear)
              ),
            }),
          })
        );
      }
    } else if (data.updatePeriod === 2) {
      if (analyseRedux.actualFetchedPeriod !== 2) {
        let beginDate = `${new Date(
          boardsRedux.boards[boardsRedux.actualBoard - 1].createdAt
        ).getFullYear()}-${initialZero(
          new Date(
            boardsRedux.boards[boardsRedux.actualBoard - 1].createdAt
          ).getMonth() + 1
        )}`;
        let endDate = `${new Date(Date.now()).getFullYear()}-12`;
        let newData = await getConso({
          boardId: boardsRedux.boards[boardsRedux.actualBoard - 1].id,
          dispatch: dispatch,
          addSnack: enqueueSnackbar,
          mustLoading: true,
          token: authRedux.token,
          refreshToken: authRedux.refreshToken,
          begin: beginDate,
          end: endDate,
          consoType: "getConsoMonth",
        });
        fetchedData = newData.result;
        dispatch(
          setAnalyseActualFetched({
            fetched: fetchedData,
            period: 2,
            periodDetail: "all",
          })
        );
      } else {
        fetchedData = analyseRedux.actualFetched;
      }
      dispatch(
        setAnalyseResult({
          result: makeAnalyse({
            conso: fetchedData.concat(
              initialActualDay.concat(
                initialActualMonth.concat(initialActualYear)
              )
            ),
          }),
        })
      );
    } else if (data.updatePeriod === 0) {
      if (
        data.updateMonthYear !== new Date(Date.now()).getFullYear() ||
        data.updateMonthMonth !== new Date(Date.now()).getMonth()
      ) {
        if (
          analyseRedux.actualFetchedPeriod !== 0 ||
          analyseRedux.actualFetchedPeriodDetail !==
            `${data.updateMonthYear}-${data.updateMonthMonth}`
        ) {
          let beginDate = `${data.updateMonthYear}-${initialZero(
            data.updateMonthMonth + 1
          )}-01`;
          let endDate = `${data.updateMonthYear}-${initialZero(
            data.updateMonthMonth + 1
          )}-${new Date(
            data.updateMonthYear,
            data.updateMonthMonth + 1,
            0
          ).getDate()}`;
          let newData = await getConso({
            boardId: boardsRedux.boards[boardsRedux.actualBoard - 1].id,
            dispatch: dispatch,
            addSnack: enqueueSnackbar,
            mustLoading: true,
            token: authRedux.token,
            refreshToken: authRedux.refreshToken,
            begin: beginDate,
            end: endDate,
            consoType: "getConsoDay",
          });
          fetchedData = newData.result;
          dispatch(
            setAnalyseActualFetched({
              fetched: fetchedData,
              period: 0,
              periodDetail: `${data.updateMonthYear}-${data.updateMonthMonth}`,
            })
          );
        } else {
          fetchedData = analyseRedux.actualFetched;
        }
        dispatch(
          setAnalyseResult({
            result: makeAnalyse({
              conso: fetchedData,
            }),
          })
        );
      } else {
        dispatch(
          setAnalyseResult({
            result: makeAnalyse({
              conso: initialActualDay.concat(initialActualMonth),
            }),
          })
        );
      }
    }
    dispatch(disableAnalyseLoading());
  };

  // Global function : Update Boards => Circuits => Events
  const updateBoards = async (data) => {
    if (!isLoading) {
      setIsLoading(true);
      let boards = await fetchBoards(data);
      let circuits = await fetchCircuits(boards);
      await fetchEvents(circuits);
      data.mustFetchProfile && (await fetchProfile(circuits));
      setIsLoading(false);
    }
  };

  // Global function : Update Circuits => Events
  const updateCircuits = async (data) => {
    if (!isLoading) {
      setIsLoading(true);
      let circuits = await fetchCircuits(data);
      await fetchEvents(circuits);
      setIsLoading(false);
    }
  };

  // Global function : Update Events
  const updateEvents = async (data) => {
    if (!isLoading) {
      setIsLoading(true);
      await fetchEvents(data);
      setIsLoading(false);
    }
  };

  // Global function : Update Profile
  const updateProfile = async (data) => {
    if (!isLoading) {
      setIsLoading(true);
      await fetchProfile(data);
      setIsLoading(false);
    }
  };

  // Update data when forced by changing state (config / mustRefresh) with loading choice
  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      if (configRedux.mustRefresh === "boards") {
        dispatch(clearMustRefresh());
        updateBoards({
          token: authRedux.token,
          refreshToken: authRedux.refreshToken,
          actualBoard: boardsRedux.actualBoard,
          mustLoading: true,
        });
      } else if (configRedux.mustRefresh === "circuits") {
        dispatch(clearMustRefresh());
        updateCircuits({
          token: authRedux.token,
          refreshToken: authRedux.refreshToken,
          mustLoading: true,
        });
      } else if (configRedux.mustRefresh === "circuitsWithoutLoading") {
        dispatch(clearMustRefresh());
        updateCircuits({
          token: authRedux.token,
          refreshToken: authRedux.refreshToken,
          mustLoading: false,
        });
      } else if (configRedux.mustRefresh === "events") {
        dispatch(clearMustRefresh());
        updateEvents({
          token: authRedux.token,
          refreshToken: authRedux.refreshToken,
          mustLoading: true,
        });
      }
      setIsLoading(false);
    } // eslint-disable-next-line
  }, [configRedux.mustRefresh]);

  // Execute Update boards when login
  useEffect(() => {
    if (authRedux.status) {
      updateBoards({
        token: authRedux.token,
        refreshToken: authRedux.refreshToken,
        actualBoard: boardsRedux.actualBoard,
        mustLoading: true,
        mustFetchProfile: true,
      });
    } // eslint-disable-next-line
  }, [authRedux.status]);

  // Make board circuits when all circuits are saved
  useEffect(() => {
    if (authRedux.status) {
      console.log("Save circuits of selected board");
      getBoardCircuits({
        circuits: circuitsRedux.allCircuits,
        boards: boardsRedux.boards,
        actualBoard: boardsRedux.actualBoard,
        circuitsIdBefore: eventsRedux.initialFilterEventsCircuits,
        dispatch: dispatch,
      });
    } // eslint-disable-next-line
  }, [circuitsRedux.allCircuits]);

  // Make board active events when all active events are saved
  useEffect(() => {
    if (authRedux.status) {
      console.log("Save active events of selected board");
      getBoardActiveEvents({
        boards: boardsRedux.boards,
        events: eventsRedux.eventsActive,
        actualBoard: boardsRedux.actualBoard,
        dispatch: dispatch,
      });
    } // eslint-disable-next-line
  }, [eventsRedux.eventsActive]);

  // Make board events when all events are saved
  useEffect(() => {
    if (authRedux.status) {
      console.log("Save events of selected board");
      getBoardEvents({
        boards: boardsRedux.boards,
        events: eventsRedux.allEvents,
        actualBoard: boardsRedux.actualBoard,
        dispatch: dispatch,
      });
    } // eslint-disable-next-line
  }, [eventsRedux.allEvents]);

  // Update conso data when change display options
  useEffect(() => {
    if (authRedux.status) {
      updateConso({
        firstFetch: false,
        updatePeriod: consoRedux.periodChoice,
        updateYear: consoRedux.periodDefineYear,
        updateMonthMonth: consoRedux.periodDefineMonthMonth,
        updateMonthYear: consoRedux.periodDefineMonthYear,
        updateDayDay: consoRedux.periodDefineDayDay,
        updateDayMonth: consoRedux.periodDefineDayMonth,
        updateDayYear: consoRedux.periodDefineDayYear,
      });
    } // eslint-disable-next-line
  }, [
    consoRedux.periodChoice,
    consoRedux.periodDefineYear,
    consoRedux.periodDefineMonthYear,
    consoRedux.periodDefineMonthMonth,
    consoRedux.periodDefineDayDay,
    consoRedux.periodDefineDayMonth,
    consoRedux.periodDefineDayYear,
  ]);

  // Update conso data when change display options
  useEffect(() => {
    if (authRedux.status) {
      updateAnalyse({
        firstFetch: false,
        updatePeriod: analyseRedux.periodChoice,
        updateYear: analyseRedux.periodDefineYear,
        updateMonthMonth: analyseRedux.periodDefineMonthMonth,
        updateMonthYear: analyseRedux.periodDefineMonthYear,
      });
    } // eslint-disable-next-line
  }, [
    analyseRedux.periodChoice,
    analyseRedux.periodDefineYear,
    analyseRedux.periodDefineMonthYear,
    analyseRedux.periodDefineMonthMonth,
  ]);

  // Make board circuits Result when board circuits change / when search of filters change
  useEffect(() => {
    makeBoardCircuitsResult({
      circuits: circuitsRedux.boardCircuits,
      search: circuitsRedux.circuitsSearch,
      sortBy: circuitsRedux.circuitsSortBy,
      dispatch: dispatch,
    }); // eslint-disable-next-line
  }, [
    circuitsRedux.circuitsSearch,
    circuitsRedux.boardCircuits,
    circuitsRedux.circuitsSortBy,
  ]);

  // Execute refreshData when location change to check limit before updates + close FAQ questions when changepathname
  useEffect(() => {
    dispatch(changeFaqQuestionOpened({ question: 0 }));
    refreshData();
    location.pathname === "/conso" &&
      authRedux.status &&
      updateConso({
        firstFetch: true,
        updatePeriod: consoRedux.periodChoice,
        updateYear: consoRedux.periodDefineYear,
        updateMonthMonth: consoRedux.periodDefineMonthMonth,
        updateMonthYear: consoRedux.periodDefineMonthYear,
        updateDayDay: consoRedux.periodDefineDayDay,
        updateDayMonth: consoRedux.periodDefineDayMonth,
        updateDayYear: consoRedux.periodDefineDayYear,
      });
    location.pathname === "/analyse" &&
      authRedux.status &&
      updateAnalyse({
        firstFetch: true,
        updatePeriod: analyseRedux.periodChoice,
        updateYear: analyseRedux.periodDefineYear,
        updateMonthMonth: analyseRedux.periodDefineMonthMonth,
        updateMonthYear: analyseRedux.periodDefineMonthYear,
      });
    let title = "Oghji - Page introuvable";
    pageTitle.forEach((onePath) => {
      if (location.pathname.includes(onePath.path)) {
        title = onePath.title;
      }
    });
    document.title = title; // eslint-disable-next-line
  }, [location.pathname]);

  // Change configRedux / width when size window change
  useEffect(() => {
    dispatch(changeScreenWidth({ screenWidth: window.innerWidth }));
    const handleResize = () => {
      dispatch(
        changeScreenWidth({
          screenWidth: window.innerWidth,
        })
      );
    };
    window.addEventListener("resize", handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    }; // eslint-disable-next-line
  }, []);

  // Change html background color + background image when darkMode / lightMode
  useEffect(() => {
    document.body.style = `margin: 0 0 0 0;${` background: url(${
      window.innerHeight > 1024
        ? loginBackgroundAvif
        : window.innerHeight > 800
        ? loginBackground1024Avif
        : window.innerHeight > 768
        ? loginBackground800Avif
        : loginBackground768Avif
    })`}; background-size: cover; background-attachment: fixed; background-repeat: no-repeat; background-color: ${
      configRedux.darkMode ? "#0f0f0f" : "#ffffff"
    }`; // eslint-disable-next-line
  }, [configRedux.darkMode]);
};

export default Configurator;
