import BASE_URL from "../../utils/api";
import endpoints from "../../utils/endpoints";

export const signup = async (signUpData) => {
  try {
    const response = await fetch(`${BASE_URL}${endpoints.signup}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(signUpData.user),
      signal: AbortSignal.timeout(3000),
    });
    if (response.ok) {
      console.log("Signup function : 200");
      signUpData.enqueueSnackbar("Inscription validée.", {
        variant: "success",
      });
    } else {
      signUpData.enqueueSnackbar("Une erreur est survenue", {
        variant: "error",
      });
    }
    return response.ok;
  } catch (error) {
    signUpData.enqueueSnackbar("Une erreur est survenue", {
      variant: "error",
    });
    return false;
  }
};
