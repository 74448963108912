import { createSlice } from "@reduxjs/toolkit";

// Circuits configuration feature

export const circuits = createSlice({
  name: "circuits",
  initialState: {
    // To save circuits display mode
    circuitsDisplay: "list",
    // To save circuits search input
    circuitsSearch: "",
    // isLoading : true (during circuits fetch) / false
    circuitsIsLoading: false,
    // To save all user's circuits
    allCircuits: [],
    // To save datetime of the last circuits update
    lastUpdate: undefined,
    // To save sort by
    circuitsSortBy: "abc",
    // To save circuits which must be displayed
    boardCircuits: [],
    boardCircuitsIds: [],
    boardCircuitsHigherConso: [],
    boardCircuitsResult: [],
  },
  reducers: {
    // To activate loading during circuits fetch
    enableCircuitsLoading: (state) => {
      state.circuitsIsLoading = true;
    },
    // To disable loading after circuits fetch
    disableCircuitsLoading: (state) => {
      state.circuitsIsLoading = false;
    },
    // To save all user's circuits and save the laset update datetime
    setAllCircuits: (state, action) => {
      state.allCircuits = action.payload.allCircuits;
      state.lastUpdate = action.payload.lastUpdate;
      state.mustRefresh = false;
    },
    // To save user's circuits which must be displayed and save the laset update datetime
    setBoardCircuits: (state, action) => {
      state.boardCircuits = action.payload.boardCircuits;
      state.boardCircuitsHigherConso = action.payload.higherConso;
      state.boardCircuitsIds = action.payload.ids;
    },
    // To change the display between cards and list
    changeCircuitsDisplay: (state, action) => {
      state.circuitsDisplay = state.circuitsDisplay === action.payload.display;
    },
    // To change circuit text search
    changeCircuitsSearch: (state, action) => {
      state.circuitsSearch = action.payload.search;
    },
    changeCircuitsSortBy: (state, action) => {
      state.circuitsSortBy = action.payload.sortBy;
    },
    setBoardCircuitsResult: (state, action) => {
      state.boardCircuitsResult = action.payload.result;
    },
    // To reset all feature
    resetAllCircuits: (state) => {
      state.circuitsDisplay = "list";
      state.circuitsSearch = "";
      state.circuitsIsLoading = false;
      state.circuitsSortBy = "abc";
      state.allCircuits = [];
      state.lastUpdate = undefined;
      state.boardCircuits = [];
      state.boardCircuitsHigherConso = [];
      state.boardCircuitsIds = [];
      state.mustRefresh = false;
      state.boardCircuitsResult = [];
    },
    // To reset only filters
    resetFiltersCircuits: (state) => {
      state.circuitsSearch = "";
      state.circuitsSortBy = "abc";
    },
  },
});

export const {
  enableCircuitsLoading,
  disableCircuitsLoading,
  setAllCircuits,
  setBoardCircuits,
  changeCircuitsDisplay,
  changeCircuitsSearch,
  changeCircuitsSortBy,
  setBoardCircuitsResult,
  resetAllCircuits,
  resetFiltersCircuits,
} = circuits.actions;
