import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { patchBoardMode } from "../../functions/boards/patchBoardMode";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PoolIcon from "@mui/icons-material/Pool";
import TuneIcon from "@mui/icons-material/Tune";
import { breakpoints } from "../../utils/responsive";

const ModeSelector = () => {
  const boardsRedux = useSelector((state) => state.boards);
  const authRedux = useSelector((state) => state.auth);
  const configRedux = useSelector((state) => state.config);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [actualMode, setActualMode] = useState(
    boardsRedux.boards[boardsRedux.actualBoard - 1].mode
  );

  const choices = [
    { id: 0, name: "Présent", icon: <PersonIcon /> },
    { id: 1, name: "Absent", icon: <PersonOffIcon /> },
    { id: 2, name: "Vacances", icon: <PoolIcon /> },
  ];

  return (
    <Box
      sx={{
        backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.secondary`,
        pl: 2,
        pb: 3,
        pr: 2,
        pt: 5,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent:
          configRedux.screenWidth > breakpoints.minTablet
            ? "space-around"
            : "center",
        position: "relative",
        boxShadow: 3,
        flexBasis: "30%",
        alignItems: "center",
        rowGap: 2,
        // height: "100%",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: -12,
          left: -12,
          backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
          borderRadius: 4,
          pl: 1,
          pr: 2,
          // width: 32,
          height: 32,
          display: "flex",
          flexDirection: "row",
          columnGap: 1,
          justifyContent: "center",
          alignItems: "center",
          boxShadow: 5,
        }}
      >
        <TuneIcon />
        <Typography
          sx={{
            color: configRedux.darkMode ? "dark.secondary" : "light.primary",
          }}
        >
          Mode
        </Typography>
      </Box>
      {choices.map((oneChoice) => {
        return (
          <Button
            disabled={isLoading}
            onClick={async () => {
              if (actualMode !== oneChoice.id) {
                setIsLoading(true);
                let result = await patchBoardMode({
                  token: authRedux.token,
                  refreshToken: authRedux.refreshToken,
                  newMode: oneChoice.id,
                  boardId: boardsRedux.boards[boardsRedux.actualBoard - 1].id,
                  dispatch: dispatch,
                  addSnack: enqueueSnackbar,
                });
                result === 200 && setActualMode(oneChoice.id);
                setIsLoading(false);
              }
            }}
            key={oneChoice.name}
            sx={{
              backgroundColor:
                boardsRedux.actualBoard &&
                boardsRedux.boards[boardsRedux.actualBoard - 1]
                  ? actualMode === oneChoice.id
                    ? `${configRedux.darkMode ? "dark" : "light"}.link`
                    : `${configRedux.darkMode ? "dark" : "light"}.background`
                  : `${configRedux.darkMode ? "dark" : "light"}.background`,
              "&:hover": {
                backgroundColor:
                  boardsRedux.actualBoard &&
                  boardsRedux.boards[boardsRedux.actualBoard - 1] &&
                  actualMode === oneChoice.id &&
                  `${configRedux.darkMode ? "dark" : "light"}.link`,
              },
              pt: 0.8,
              pb: 0.8,
              pl: 2,
              pr: 2,
              borderRadius: 10,
              boxShadow: 3,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: 2,
              color: configRedux.darkMode
                ? boardsRedux.boards[boardsRedux.actualBoard - 1] &&
                  actualMode === oneChoice.id
                  ? "dark.secondary"
                  : "dark.primary"
                : "light.primary",
              textTransform: "none",
              minWidth: "70%",
            }}
          >
            {oneChoice.icon}
            <Typography>{oneChoice.name}</Typography>
          </Button>
        );
      })}
    </Box>
  );
};

export default ModeSelector;
