import { useSelector } from "react-redux";
import { Box, alpha } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import oghjiLogo from "../assets/images/logoOnly.png";
import { breakpoints } from "../utils/responsive";
import MyInformations from "../components/profile/myInformations";
import PasswordProfile from "../components/profile/passwordProfile";
import ProfileChoice from "../components/profile/profileChoice";

const ProfilePage = () => {
  const profileRedux = useSelector((state) => state.profile);
  const configRedux = useSelector((state) => state.config);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexBasis: "100%",
        justifyContent: profileRedux.isLoading ? "center" : "flex-start",
        flexDirection: "column",
        // alignItems: 'center',
        rowGap: 4,
      }}
    >
      {profileRedux.isLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Box
            sx={{
              p: 2,
              backgroundColor: alpha(
                configRedux.darkMode ? "#000000" : "#ffffff",
                0.8
              ),
              borderRadius: 100,
              position: "relative",
            }}
          >
            <CircularProgress
              size={70}
              sx={{ color: `${configRedux.darkMode ? "dark" : "light"}.link` }}
            />
            <img
              src={oghjiLogo}
              alt={"Chargement"}
              height={40}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            columnGap: 4,
            rowGap: 4,
            pt:1,
          }}
        >
          <MyInformations />
          <Box
            sx={{
              display: "flex",
              flexDirection:
                configRedux.screenWidth > breakpoints.minTablet
                  ? "row"
                  : "column",
              justifyContent: "space-between",
              width: "100%",
              columnGap: 4,
              rowGap: 4,
            }}
          >
            <Box sx={{ flexBasis: "50%" }}>
              <PasswordProfile />
            </Box>
            <Box sx={{ flexBasis: "50%" }}>
              <ProfileChoice />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProfilePage;
