import BASE_URL from "../../utils/api";
import endpoints from "../../utils/endpoints";
import getBoards from "../boards/getBoards";
import refreshTokenFunction from "../auth/refreshToken";
import { forceRefresh } from "../../redux/features/config";

const addElectronicBoard = async (data) => {
  let responseStatus = 0;
  let token = data.token;
  let refreshToken = data.refreshToken;
  let message = "";
  const tryFetch = async (fetchToken) => {
    try {
      let fetchAddBoard = await fetch(`${BASE_URL}${endpoints.addBoard}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${fetchToken}`,
        },
        body: JSON.stringify({ shareCode: data.shareCode }),
        signal: AbortSignal.timeout(3000),
      });
      responseStatus = fetchAddBoard.status;
      let fetchData = await fetchAddBoard.json();
      message = fetchData.message;
      if (fetchAddBoard.ok) {
        console.log("Add board function : 201");
        console.log(fetchData);
        getBoards({
          actual: data.actual,
          dispatch: data.dispatch,
          token: data.token,
          refreshToken: data.refreshToken,
        });
      }
    } catch (error) {
      responseStatus = error.status;
    }
  };

  await tryFetch(data.token);

  if (
    responseStatus === 401 &&
    message === "Electronic Board not found or code invalid"
  ) {
    console.log("Add board function : 401");
    let refreshFunction = await refreshTokenFunction({
      refreshToken: refreshToken,
      dispatch: data.dispatch,
    });
    if (refreshFunction.status === 200) {
      token = refreshFunction.token;
      refreshToken = refreshFunction.refreshToken;
      await tryFetch(refreshFunction.token);
    }
  }
  if (responseStatus.ok) {
    data.addSnack("Félicitations ! Vous avez désormais accès à ce tableau.", {
      variant: "success",
    });
    data.dispatch(forceRefresh({ highTarget: "boards" }));
    data.navigate("/home");
  } else {
    data.addSnack("Erreur lors de l'ajout du tableau. Veuillez réessayer.", {
      variant: "error",
    });
  }
  return { status: responseStatus, token: token, refreshToken: refreshToken };
};

export default addElectronicBoard;
