import { Box, Switch, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import pictos from "../../utils/pictos";
import { patchCircuit } from "../../functions/circuits/patchCircuit";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { breakpoints } from "../../utils/responsive";

const OneCircuitBox = ({ circuit }) => {
  const configRedux = useSelector((state) => state.config);
  const authRedux = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [isActive, setIsActive] = useState(circuit.status);
  const [isPatching, setIsPatching] = useState(false);

  const changeStatus = async () => {
    if (!isPatching) {
      // setOneIsLoading(true);
      setIsPatching(true);
      let result = await patchCircuit({
        dispatch: dispatch,
        circuit: circuit,
        newStatus: !isActive,
        token: authRedux.token,
        refreshToken: authRedux.refreshToken,
        addSnack: enqueueSnackbar,
      });
      // setOneIsLoading(false);
      setIsPatching(false);
      result === 200 && setIsActive(isActive === true ? false : true);
    }
  };

  return (
    <Box
      sx={{
        width:
          configRedux.screenWidth <= breakpoints.minTablet
            ? "100%"
            : configRedux.screenWidth <= breakpoints.minDesktop
            ? "calc((100% - 2 * 16px) / 2)"
            : "calc((100% - 2 * 16px) / 3)",
        backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.secondary`,
        borderRadius: 2,
        p: 0,
        m: 0,
        display: "flex",
        flexDirection: "column",
        rowGap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: 1,
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            columnGap: 2,
          }}
        >
          <Box
            sx={{
              backgroundColor: `${
                configRedux.darkMode ? "dark" : "light"
              }.background`,
              p: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 2,
            }}
          >
            <img
              // CHANGE IF ID > LIST LENGTH
              alt={
                pictos[
                  circuit.pictogramme.id < 63 ? circuit.pictogramme.id - 1 : 0
                ].name
              }
              src={
                pictos[
                  circuit.pictogramme.id < 63 ? circuit.pictogramme.id - 1 : 0
                ].picto
              }
              style={{
                filter: configRedux.darkMode
                  ? "brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(1%) hue-rotate(198deg) brightness(87%) contrast(98%)"
                  : "brightness(0) saturate(100%) invert(3%) sepia(8%) saturate(6%) hue-rotate(332deg) brightness(99%) contrast(101%)",
              }}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
              }}
            >
              {circuit.name}
            </Typography>
            <Typography
              sx={{
                color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
              }}
            >
              {circuit.location}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ color: `${configRedux.darkMode ? "dark" : "light"}.primary` }}
          >
            {Math.round(circuit.consommationInstantanees[0].consommation)}
            {" W"}
          </Typography>
          <Switch
            disabled={isPatching}
            checked={isActive}
            id={`switch-circuit-${circuit.id}`}
            sx={{
              width: 60,
              height: 26,
              padding: 0,
              "& .MuiSwitch-switchBase": {
                padding: 0,
                // margin: 2,
                transitionDuration: "300ms",
                "&.Mui-checked": {
                  transform: "translateX(34px)",
                  color: "#fff",
                  "& + .MuiSwitch-track": {
                    backgroundColor: configRedux.darkMode
                      ? "#2ECA45"
                      : "#65C466",
                    opacity: 1,
                    border: 0,
                  },
                  "&.Mui-disabled + .MuiSwitch-track": {
                    opacity: 0.5,
                  },
                },
                "&.Mui-focusVisible .MuiSwitch-thumb": {
                  color: "#33cf4d",
                  border: "6px solid #fff",
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                  opacity: !configRedux.darkMode ? 0.7 : 0.3,
                },
              },
              "& .MuiSwitch-thumb": {
                boxSizing: "border-box",
                width: 26,
                height: 26,
              },
              "& .MuiSwitch-track": {
                borderRadius: 26 / 2,
                backgroundColor: !configRedux.darkMode ? "#E9E9EA" : "#39393D",
                opacity: 1,
                // transition: theme.transitions.create(["background-color"], {
                //   duration: 500,
                // }),
              },
            }}
            onChange={changeStatus}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OneCircuitBox;
