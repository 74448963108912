import { setBoardCircuitsResult } from "../../redux/features/circuits";

export const makeBoardCircuitsResult = (data) => {
  let finalArray = [];
  data.circuits.forEach((oneCircuit) => {
    (oneCircuit.name
      .toLowerCase()
      .trim()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .includes(data.search.toLowerCase().trim()) ||
      oneCircuit.location
        .toLowerCase()
        .trim()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(data.search.toLowerCase().trim())) &&
      finalArray.push(oneCircuit);
  });
  finalArray.sort((a, b) => {
    if (data.sortBy === "abc") {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      } else {
        return 0;
      }
    } else if (data.sortBy === "lowFirst") {
      return (
        a.consommationInstantanees[0].consommation -
        b.consommationInstantanees[0].consommation
      );
    } else if (data.sortBy === "highFirst") {
      return (
        b.consommationInstantanees[0].consommation -
        a.consommationInstantanees[0].consommation
      );
    } else if (data.sortBy === "onFirst") {
      return b.status - a.status;
    } else if (data.sortBy === "offFirst") {
      return a.status - b.status;
    } else {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      } else {
        return 0;
      }
    }
  });
  data.dispatch(setBoardCircuitsResult({ result: finalArray }));
};
