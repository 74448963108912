import { Box } from "@mui/material";
import { questions } from "../components/faq/questions";
import { QuestionBox } from "../components/faq/questionBox";

export const FaqPage = () => {
  return (
    <Box>
      {questions.map((oneQuestion) => (
        <QuestionBox key={oneQuestion.question} question={oneQuestion} />
      ))}
    </Box>
  );
};
