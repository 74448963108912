import { useSelector } from "react-redux";
import { checkBoardPermissions } from "../../functions/settings/checkBoardPersmissions";
import BatimentInfosOwner from "./batimentInfosOwner";
import BatimentInfosUser from "./batimentInfosUser";
import { useNavigate, useParams } from "react-router-dom";

const BatimentInfosSwitch = () => {
  const boardsRedux = useSelector((state) => state.boards);
  const profileRedux = useSelector((state) => state.profile);

  const params = useParams();
  const navigate = useNavigate();

  if (
    !checkBoardPermissions({
      boardId: params.boardId,
      userId: profileRedux.id,
      boards: boardsRedux.boards,
    }).havePermissions
  ) {
    navigate("/error");
  } else {
    if (
      checkBoardPermissions({
        boardId: params.boardId,
        userId: profileRedux.id,
        boards: boardsRedux.boards,
      }).isOwner
    ) {
      return <BatimentInfosOwner />;
    } else {
      return <BatimentInfosUser />;
    }
  }
};

export default BatimentInfosSwitch;
