import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import KeyIcon from "@mui/icons-material/Key";
import ShareIcon from "@mui/icons-material/Share";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import InfoIcon from "@mui/icons-material/Info";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ReplyIcon from "@mui/icons-material/Reply";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import { useEffect, useState } from "react";
import { checkBoardPermissions } from "../../functions/settings/checkBoardPersmissions";
import { enqueueSnackbar } from "notistack";

const OneBoardMenu = () => {
  const configRedux = useSelector((state) => state.config);
  const boardsRedux = useSelector((state) => state.boards);
  const profileRedux = useSelector((state) => state.profile);

  const params = useParams();
  const navigate = useNavigate();

  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    if (
      !checkBoardPermissions({
        boardId: params.boardId,
        userId: profileRedux.id,
        boards: boardsRedux.boards,
      }).havePermissions
    ) {
      navigate("/error");
    } else {
      setIsOwner(
        checkBoardPermissions({
          boardId: params.boardId,
          userId: profileRedux.id,
          boards: boardsRedux.boards,
        }).isOwner
      );
    } // eslint-disable-next-line
  }, []);

  const boardMenu = {
    owner: [
      {
        text: "Gestion des accès",
        icon: (
          <KeyIcon
            sx={{
              color: `${configRedux.darkMode ? "dark" : "light"}.link`,
            }}
          />
        ),
        function: () => {
          navigate(`/boards/${params.boardId}/access-management`);
        },
      },
      {
        text: "Partager le tableau",
        icon: (
          <ShareIcon
            sx={{
              color: `${configRedux.darkMode ? "dark" : "light"}.link`,
            }}
          />
        ),
        function: () => {
          console.log("share board");
        },
      },
      {
        text: "Informations du bâtiment",
        icon: (
          <HomeWorkIcon
            sx={{
              color: `${configRedux.darkMode ? "dark" : "light"}.link`,
            }}
          />
        ),
        function: () => {
          navigate(`/boards/${params.boardId}/batiment-informations`);
        },
      },
      {
        text: "Informations techniques",
        icon: (
          <InfoIcon
            sx={{
              color: `${configRedux.darkMode ? "dark" : "light"}.link`,
            }}
          />
        ),
        function: () => {
          navigate(`/boards/${params.boardId}/technical-informations`);
        },
      },
      {
        text: "Tension Réseau",
        icon: (
          <OfflineBoltIcon
            sx={{
              color: `${configRedux.darkMode ? "dark" : "light"}.link`,
            }}
          />
        ),
        function: () => {
          navigate(`/boards/${params.boardId}/tension`);
        },
      },
    ],
    user: [
      {
        text: "Informations du bâtiment",
        icon: (
          <HomeWorkIcon
            sx={{
              color: `${configRedux.darkMode ? "dark" : "light"}.link`,
            }}
          />
        ),
        function: () => {
          navigate(`/boards/${params.boardId}/batiment-informations`);
        },
      },
      {
        text: "Informations techniques",
        icon: (
          <InfoIcon
            sx={{
              color: `${configRedux.darkMode ? "dark" : "light"}.link`,
            }}
          />
        ),
        function: () => {
          navigate(`/boards/${params.boardId}/technical-informations`);
        },
      },
      {
        text: "Tension Réseau",
        icon: (
          <OfflineBoltIcon
            sx={{
              color: `${configRedux.darkMode ? "dark" : "light"}.link`,
            }}
          />
        ),
        function: () => {
          navigate(`/boards/${params.boardId}/tension`);
        },
      },
      {
        text: "Supprimer ce tableau",
        icon: (
          <DeleteSweepIcon
            sx={{
              color: `${configRedux.darkMode ? "dark" : "light"}.link`,
            }}
          />
        ),
        function: () => {
          enqueueSnackbar("Bientôt disponible.", {
            variant: "info",
          });
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        rowGap: 2,
      }}
    >
      <List
        sx={{
          width: "100%",
          backgroundColor: `${
            configRedux.darkMode ? "dark" : "light"
          }.secondary`,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          mt: 1,
          pt: 4,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: -12,
            left: -12,
            backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
            borderRadius: 4,
            pl: 1,
            pr: 2,
            // width: 32,
            height: 32,
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            justifyContent: "center",
            alignItems: "center",
            boxShadow: 5,
          }}
        >
          <ElectricMeterIcon />
          <Typography
            sx={{
              color: configRedux.darkMode ? "dark.secondary" : "light.primary",
            }}
          >
            {boardsRedux.boards.filter(
              (oneBoard) => oneBoard.id === Number(params.boardId)
            ).length === 1 &&
              boardsRedux.boards.filter(
                (oneBoard) => oneBoard.id === Number(params.boardId)
              )[0].name}
          </Typography>
        </Box>
        {boardMenu[isOwner ? "owner" : "user"].map((oneItem) => {
          return (
            <ListItem
              onClick={oneItem.function}
              key={oneItem.text}
              sx={{
                width: "calc(100% - 32px)",
                backgroundColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.background`,
                mt: 1,
                mb: 1,
                mr: 2,
                ml: 2,
                p: 0,
                borderRadius: 2,
              }}
              secondaryAction={
                <IconButton edge="end" aria-label={oneItem.text}>
                  <KeyboardDoubleArrowRightIcon />
                </IconButton>
              }
            >
              <ListItemButton sx={{ borderRadius: 2 }}>
                <ListItemAvatar sx={{ minWidth: "36px" }}>
                  {oneItem.icon}
                </ListItemAvatar>
                <ListItemText id={"test"} primary={oneItem.text} />
              </ListItemButton>
            </ListItem>
          );
        })}
        <Box></Box>
      </List>
      <Button
        onClick={() => {
          navigate("/boards");
        }}
        aria-label="add-board-button"
        size="small"
        // fullWidth
        variant="contained"
        sx={{
          mt: 0,
          mr: 0,
          ml: 0,
          mb: 2,
          backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
          color: `${configRedux.darkMode ? "dark" : "light"}.background`,
          "&:hover": {
            backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
            opacity: 0.8,
          },
          textTransform: "none",
          display: "flex",
          flexDirection: "row",
          columnGap: 1,
          alignItems: "center",
        }}
      >
        <ReplyIcon />
        Retour
      </Button>
    </Box>
  );
};

export default OneBoardMenu;
