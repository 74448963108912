import { Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { eehPowerValue } from "../../utils/eehDefinition";
import { changeAnalyseDetailChoice } from "../../redux/features/analyse";

const DetailChoice = () => {
  const analyseRedux = useSelector((state) => state.analyse);
  const configRedux = useSelector((state) => state.config);
  const boardsRedux = useSelector((state) => state.boards);

  const dispatch = useDispatch();

  const [totalEEH, setTotalEEH] = useState(0);

  const handleChangeDetailChoice = (event, value) => {
    event.preventDefault();
    dispatch(changeAnalyseDetailChoice({ detail: value }));
  };

  const initialZero = (num) => (num < 10 ? `0${num}` : num);

  const calculateStartDate = (boardCreationDate) => {
    if (
      analyseRedux.periodChoice === 2 ||
      (analyseRedux.periodChoice === 1 &&
        boardCreationDate.getFullYear() === analyseRedux.periodDefineYear)
    ) {
      return dayjs(
        `${boardCreationDate.getFullYear()}-${initialZero(
          boardCreationDate.getMonth() + 1
        )}-${initialZero(boardCreationDate.getDate())}`
      ).startOf("day");
    } else if (analyseRedux.periodChoice === 1) {
      return dayjs(`${analyseRedux.periodDefineYear}-01-01`).startOf("year");
    } else if (analyseRedux.periodChoice === 0) {
      return dayjs(
        `${analyseRedux.periodDefineMonthYear}-${initialZero(
          analyseRedux.periodDefineMonthMonth + 1
        )}-01`
      ).startOf("month");
    }
  };

  const calculateEndDate = (boardCreationDate, nowDate) => {
    if (
      analyseRedux.periodChoice === 2 ||
      (analyseRedux.periodChoice === 1 &&
        nowDate.getFullYear() === analyseRedux.periodDefineYear)
    ) {
      return dayjs().startOf("hour");
    } else if (analyseRedux.periodChoice === 1) {
      return dayjs(`${boardCreationDate.getFullYear()}-12-31`).endOf("year");
    } else if (analyseRedux.periodChoice === 0) {
      return dayjs(
        `${analyseRedux.periodDefineMonthYear}-${initialZero(
          analyseRedux.periodDefineMonthMonth + 1
        )}-01`
      ).endOf("month");
    }
  };

  useEffect(() => {
    const boardCreationDate = new Date(
      boardsRedux.boards[boardsRedux.actualBoard - 1].createdAt
    );
    const nowDate = new Date(Date.now());
    const startDate = calculateStartDate(boardCreationDate);
    const endDate = calculateEndDate(boardCreationDate, nowDate);
    const baseEEh =
      analyseRedux.analyseResultTotalPower /
      Math.round(endDate.diff(startDate, "hour", true)) /
      eehPowerValue;
    const finalEEh =
      baseEEh < 10 ? Math.round(baseEEh * 10) / 10 : Math.round(baseEEh);
    setTotalEEH(finalEEh); // eslint-disable-next-line
  }, [
    analyseRedux.periodDefineYear,
    analyseRedux.periodDefineMonthYear,
    analyseRedux.periodDefineMonthMonth,
    analyseRedux.periodChoice,
    analyseRedux.analyseResult,
  ]);

  const detailChoiceTabs = [
    { text: "power" },
    { text: "price" },
    { text: "EEH" },
  ];

  return (
    <Tabs
      value={analyseRedux.detailChoice}
      onChange={handleChangeDetailChoice}
      centered
      indicatorColor={`${configRedux.darkMode ? "dark" : "light"}.primary`}
      variant="fullWidth"
      sx={{
        width: "100%",
        borderRadius: 2,
        backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.secondary`,
      }}
    >
      {detailChoiceTabs.map((oneTab) => {
        return (
          <Tab
            disabled={analyseRedux.isLoading}
            key={oneTab.text}
            sx={{
              textTransform: "none",
              borderRadius: 2,
              "&.MuiTab-root": {
                backgroundColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.secondary`,
                color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
              },
              "&.Mui-selected": {
                backgroundColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.link`,
                color: `${
                  configRedux.darkMode ? "dark.background" : "light.primary"
                }`,
              },
            }}
            label={
              !analyseRedux.isLoading
                ? `${
                    oneTab.text === "power"
                      ? analyseRedux.analyseResultTotalPower < 1000
                        ? Math.round(analyseRedux.analyseResultTotalPower)
                        : Math.round(
                            analyseRedux.analyseResultTotalPower / 1000
                          )
                      : oneTab.text === "price"
                      ? analyseRedux.analyseResultTotalPrice < 100
                        ? Math.round(
                            analyseRedux.analyseResultTotalPrice * 100
                          ) / 100
                        : Math.round(analyseRedux.analyseResultTotalPrice)
                      : totalEEH
                  } ${
                    oneTab.text === "power"
                      ? analyseRedux.analyseResultTotalPower < 1000
                        ? "Wh"
                        : "kWh"
                      : oneTab.text === "price"
                      ? "€"
                      : "EEH"
                  }`
                : `--- ${
                    oneTab.text === "power"
                      ? "kWh"
                      : oneTab.text === "price"
                      ? "€"
                      : "EEH"
                  }`
            }
          />
        );
      })}
    </Tabs>
  );
};

export default DetailChoice;
