import { setBoardEventsActive } from "../../redux/features/events";

function getBoardActiveEvents(data) {
  let circuitsOfSelectedBoard = [];
  data.boards.length > 0 &&
    data.actualBoard &&
    data.boards[data.actualBoard - 1].box.map((oneBox) =>
      oneBox.circuits.map((oneCircuit) =>
        circuitsOfSelectedBoard.push(oneCircuit.id)
      )
    );
  let resultEvents = [];
  data.events.length > 0 &&
    data.boards.length > 0 &&
    data.actualBoard &&
    data.events.forEach((oneEvent) => {
      if (
        oneEvent.electronicBoard &&
        oneEvent.electronicBoard.id === data.boards[data.actualBoard - 1].id
      ) {
        resultEvents.push(oneEvent);
      } else if (
        oneEvent.circuit &&
        circuitsOfSelectedBoard.includes(oneEvent.circuit.id)
      ) {
        resultEvents.push(oneEvent);
      }
    });
  data.dispatch(setBoardEventsActive({ events: resultEvents }));
  return true;
}

export default getBoardActiveEvents;
