import { createSlice } from "@reduxjs/toolkit";

export const events = createSlice({
  name: "events",
  initialState: {
    eventsActive: [],
    boardEventsActive: [],
    allEvents: [],
    lastUpdate: undefined,
    boardEvents: [],
    initialFilterEventsCircuits: [],
    filterEventsCircuits: [],
    initialFilterEventsCategories: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23,
    ],
    filterEventsCategories: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23,
    ],
    eventsResult: [],
    isLoading: false,
  },
  reducers: {
    // To enable loading during fetch
    enableEventsLoading: (state) => {
      state.isLoading = true;
    },
    // To disable loading during fetch
    disableEventsLoading: (state) => {
      state.isLoading = false;
    },
    // To save data when fetch response is OK
    setActiveEvents: (state, action) => {
      state.eventsActive = action.payload.activeEvents;
      state.isLoading = false;
    },
    // To save active board events
    setBoardEventsActive: (state, action) => {
      state.boardEventsActive = action.payload.events;
      state.isLoading = false;
    },
    setAllEvents: (state, action) => {
      state.allEvents = action.payload.allEvents;
      state.lastUpdate = action.payload.update;
    },
    setBoardEvents: (state, action) => {
      state.boardEvents = action.payload.boardEvents;
    },
    // Filter circuits actions
    setinitialFilterEventsCircuits: (state, action) => {
      state.initialFilterEventsCircuits = action.payload.circuits;
      state.filterEventsCircuits = action.payload.circuits;
    },
    setfilterEventsCircuits: (state, action) => {
      state.filterEventsCircuits = action.payload.circuits;
    },
    // Filter categories actions
    setfilterEventsCategories: (state, action) => {
      state.filterEventsCategories = action.payload.categories;
    },
    // To reset all the feature
    resetEvents: (state) => {
      state.isLoading = false;
      state.eventsActive = [];
      state.boardEventsActive = [];
      state.boardEvents = [];
      state.allEvents = [];
      state.lastUpdate = undefined;
      state.filterEventsCategories = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23,
      ];
      state.initialFilterEventsCategories = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23,
      ];
      state.filterEventsCircuits = [];
      state.initialFilterEventsCircuits = [];
      state.eventsResult = [];
    },
  },
});

export const {
  enableEventsLoading,
  disableEventsLoading,
  setActiveEvents,
  setBoardEventsActive,
  setAllEvents,
  setBoardEvents,
  setinitialFilterEventsCircuits,
  setfilterEventsCircuits,
  setfilterEventsCategories,
  resetEvents,
} = events.actions;
