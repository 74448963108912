import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import PeriodChoice from "../components/analyse/periodChoice";
import PeriodDefine from "../components/analyse/periodDefine";
import DetailChoice from "../components/analyse/detailChoice";
import { breakpoints } from "../utils/responsive";
import AnalyseCircuitBox from "../components/analyse/analyseCircuitBox";

const AnalysePage = () => {
  const analyseRedux = useSelector((state) => state.analyse);
  const configRedux = useSelector((state) => state.config);
//   const boardsRedux = useSelector((state) => state.boards);

//   const dispatch = useDispatch();

  //   const eehCalc = (data) => {
  //     let boardCreationDate = dayjs(
  //       boardsRedux.boards[boardsRedux.actualBoard - 1].createdAt
  //     );
  //     let dateNow = dayjs().startOf("hour");
  //     let beginDate, endDate;

  //     const calculateBeginEndDates = (year, month, date, hour, period) => {
  //       if (period === "year") {
  //         if (boardCreationDate.year() === year) {
  //           beginDate = boardCreationDate;
  //         } else {
  //           beginDate = dayjs().year(year).startOf("year");
  //         }
  //         if (dateNow.year() === year) {
  //           endDate = dateNow;
  //         } else {
  //           endDate = dayjs().year(year).endOf("year");
  //         }
  //       } else if (period === "month") {
  //         if (
  //           boardCreationDate.year() === year &&
  //           boardCreationDate.month() === month
  //         ) {
  //           beginDate = boardCreationDate;
  //         } else {
  //           beginDate = dayjs().year(year).month(month).startOf("month");
  //         }
  //         if (dateNow.year() === year && dateNow.month() === month) {
  //           endDate = dateNow;
  //         } else {
  //           endDate = dayjs().year(year).month(month).endOf("month");
  //         }
  //       } else if (period === "day") {
  //         if (
  //           boardCreationDate.year() === year &&
  //           boardCreationDate.month() === month &&
  //           boardCreationDate.date() === date
  //         ) {
  //           beginDate = boardCreationDate;
  //         } else {
  //           beginDate = dayjs().year(year).month(month).date(date).startOf("day");
  //         }
  //         if (
  //           dateNow.year() === year &&
  //           dateNow.month() === month &&
  //           dateNow.date() === date
  //         ) {
  //           endDate = dateNow;
  //         } else {
  //           endDate = dayjs().year(year).month(month).date(date).endOf("day");
  //         }
  //       } else if (period === "hour") {
  //         if (
  //           boardCreationDate.year() === year &&
  //           boardCreationDate.month() === month &&
  //           boardCreationDate.date() === date &&
  //           boardCreationDate.hour() === hour
  //         ) {
  //           beginDate = boardCreationDate;
  //         } else {
  //           beginDate = dayjs()
  //             .year(year)
  //             .month(month)
  //             .date(date)
  //             .hour(hour)
  //             .startOf("hour");
  //         }
  //         if (
  //           dateNow.year() === year &&
  //           dateNow.month() === month &&
  //           dateNow.date() === date &&
  //           dateNow.hour() === hour
  //         ) {
  //           endDate = dateNow;
  //         } else {
  //           endDate = dayjs()
  //             .year(year)
  //             .month(month)
  //             .date(date)
  //             .hour(hour)
  //             .endOf("hour");
  //         }
  //       }
  //     };
  //     if (consoRedux.periodChoice === 4) {
  //       calculateBeginEndDates(data.date, null, null, null, "year");
  //     } else if (consoRedux.periodChoice === 3) {
  //       calculateBeginEndDates(
  //         consoRedux.periodDefineYear,
  //         data.date,
  //         null,
  //         null,
  //         "month"
  //       );
  //     } else if (consoRedux.periodChoice === 2) {
  //       calculateBeginEndDates(
  //         consoRedux.periodDefineMonthYear,
  //         consoRedux.periodDefineMonthMonth,
  //         data.date,
  //         null,
  //         "day"
  //       );
  //     } else if (consoRedux.periodChoice === 0 || consoRedux.periodChoice === 1) {
  //       calculateBeginEndDates(
  //         consoRedux.periodDefineDayYear,
  //         consoRedux.periodDefineDayMonth,
  //         consoRedux.periodDefineDayDay,
  //         data.date,
  //         "hour"
  //       );
  //     }
  //     return (
  //       data.consommation / endDate.diff(beginDate, "hour", true) / eehPowerValue
  //     );
  //   };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexBasis: "100%",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        rowGap: 3,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          position: "sticky",
          justifyContent: "flex-start",
          alignItems: "center",
          rowGap: 3,
          top: -24,
          zIndex: 1,
        //   backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.background`,
          borderRadius: 2,
        }}
      >
        <PeriodChoice />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Box
            sx={{
              flexBasis: "30%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PeriodDefine />
          </Box>
          <Box
            sx={{
              flexBasis: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DetailChoice />
          </Box>
        </Box>
      </Box>
      {/* <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          rowGap: 3,
        }}
      > */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection:
            configRedux.screenWidth <= breakpoints.minTablet ? "column" : "row",
          flexWrap: "wrap",
          justifyContent: analyseRedux.isLoading ? "center" : "flex-start",
          rowGap: 2,
          columnGap: 2,
        }}
      >
        {analyseRedux.analyseResult.map((oneCircuit) => {
          return (
            <AnalyseCircuitBox key={oneCircuit.circuit} circuit={oneCircuit} />
          );
        })}
      </Box>
      {/* </Box> */}
    </Box>
  );
};

export default AnalysePage;
