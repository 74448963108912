import "./style.css";

export const colorsTheme = {
  palette: {
    dark: {
      primary: "#d9d9d9",
      background: "#0f0f0f",
      secondary: "#212121",
      link: "#00ff93",
      button: {
        before: "#434343",
        hover: "#848484",
      },
    },
    light: {
      primary: "#0f0f0f",
      background: "#ffffff",
      secondary: "#f4f4f4",
      link: "#00c321",
      button: {
        before: "#aaaaaa",
        hover: "#aaaaaa",
      },
    },
    alert: {
      dark: {
        success: {
          bg: "#00ff93",
          txt: "#212121",
          progress: "#212121",
        },
        error: {
          bg: "#ff2d2d",
          txt: "#d9d9d9",
          progress: "#d9d9d9",
        },
        info: {
          bg: "#212121",
          txt: "#d9d9d9",
          progress: "#d9d9d9",
        },
      },
      light: {
        success: {
          bg: "#00c321",
          txt: "#0f0f0f",
          progress: "#0f0f0f",
        },
        error: {
          bg: "#ff2d2d",
          txt: "#f4f4f4",
          progress: "#f4f4f4",
        },
        info: {
          bg: "#f4f4f4",
          txt: "#0f0f0f",
          progress: "#0f0f0f",
        },
      },
    },
  },
  typography: {
    fontFamily: "Trenda Regular",
  },
};

// dark: '#00ff93',
// light: '#00c321',
