import {
  fetchLoginSuccess,
  resetAuth,
  startFetchLogin,
} from "../../redux/features/auth";
import BASE_URL from "../../utils/api";
import endpoints from "../../utils/endpoints";

const loginFacebook = async (data) => {
  data.dispatch(startFetchLogin());
  let fetchFunction = await fetch(`${BASE_URL}${endpoints.loginFacebook}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ facebook_token: data.accessToken }),
    signal: AbortSignal.timeout(3000),
  });
  if (fetchFunction.ok) {
    console.log("Login facebook function : 200");
    let dataFromServer = await fetchFunction.json();
    data.dispatch(fetchLoginSuccess(dataFromServer));
    data.navigate("/home");
    data.enqueueSnackbar("Connexion avec Facebook réussie.", {
      variant: "success",
    });
  } else {
    data.dispatch(resetAuth());
  }
};

export default loginFacebook;
