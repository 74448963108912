import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import { useNavigate } from "react-router-dom";

const MyBoardsPage = () => {
  const configRedux = useSelector((state) => state.config);
  const boardsRedux = useSelector((state) => state.boards);

  const navigate = useNavigate();

  return (
    <>
      <List
        sx={{
          width: "100%",
          backgroundColor: `${
            configRedux.darkMode ? "dark" : "light"
          }.secondary`,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 1,
          pt: 4,
        }}
      >
        {boardsRedux.boards.map((oneBoard) => {
          return (
            <ListItem
              onClick={() => {
                navigate(`/boards/${oneBoard.id}`);
              }}
              key={oneBoard.id}
              sx={{
                width: "calc(100% - 32px)",
                backgroundColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.background`,
                mt: 1,
                mb: 1,
                mr: 2,
                ml: 2,
                p: 0,
                borderRadius: 2,
              }}
              secondaryAction={
                <IconButton edge="end" aria-label={`detail 1`}>
                  <KeyboardDoubleArrowRightIcon />
                </IconButton>
              }
            >
              <ListItemButton sx={{ borderRadius: 2 }}>
                <ListItemAvatar sx={{ minWidth: "36px" }}>
                  <ElectricMeterIcon
                    sx={{
                      color: `${configRedux.darkMode ? "dark" : "light"}.link`,
                    }}
                  />
                </ListItemAvatar>
                <ListItemText id={"test"} primary={oneBoard.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
        <Box
          sx={{
            position: "absolute",
            top: -12,
            left: -12,
            backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
            borderRadius: 4,
            pl: 1,
            pr: 2,
            // width: 32,
            height: 32,
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            justifyContent: "center",
            alignItems: "center",
            boxShadow: 5,
          }}
        >
          <ElectricMeterIcon />
          <Typography
            sx={{
              color: configRedux.darkMode ? "dark.secondary" : "light.primary",
            }}
          >
            Mes tableaux
          </Typography>
        </Box>
        <Box>
          <Button
            onClick={() => {
              navigate("/addboard");
            }}
            aria-label="add-board-button"
            size="small"
            fullWidth
            variant="contained"
            sx={{
              m: 1,
              backgroundColor: `${
                configRedux.darkMode ? "dark" : "light"
              }.link`,
              color: `${configRedux.darkMode ? "dark" : "light"}.background`,
              "&:hover": {
                backgroundColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.link`,
                opacity: 0.8,
              },
              textTransform: "none",
            }}
          >
            Ajouter un tableau
          </Button>
        </Box>
      </List>
    </>
  );
};

export default MyBoardsPage;
