import { Box, Button, Typography } from "@mui/material";
import { LoginSocialFacebook } from "reactjs-social-login";
import loginFacebook from "../../functions/auth/loginFacebook";
import { resetAuth, startFetchLogin } from "../../redux/features/auth";
import { useSelector } from "react-redux";
import facebookLogo from "../../assets/images/facebookLogo.png";

const FacebookLoginButton = ({
  data: { dispatch, enqueueSnackbar, navigate },
}) => {
  const authRedux = useSelector((state) => state.auth);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoginSocialFacebook
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        fieldsProfile={
          "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
        }
        scope={"email,public_profile"}
        onLoginStart={() => {
          dispatch(startFetchLogin());
        }}
        redirect_uri={"/home"}
        onResolve={({ provider, data }) => {
          loginFacebook({
            accessToken: data.accessToken,
            dispatch: dispatch,
            enqueueSnackbar: enqueueSnackbar,
            navigate: navigate,
          });
        }}
        onReject={() => {
          dispatch(resetAuth());
        }}
      >
        <Button disabled={authRedux.isLoading} sx={{ p: 0 }}>
          <Box
            sx={{
              height: 30,
              backgroundColor: "#3b579d",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: 1,
              paddingRight: 1,
              borderRadius: 1,
              columnGap: 2,
            }}
          >
            <img
              alt="Se connecter avec Facebook"
              src={facebookLogo}
              style={{ height: 20, width: 20 }}
            />
            <Typography textTransform={"none"} color={"#ffffff"} fontSize={12}>
              Se connecter avec Facebook
            </Typography>
          </Box>
        </Button>
      </LoginSocialFacebook>
    </Box>
  );
};

export default FacebookLoginButton;
