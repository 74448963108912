import { Box, Container, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const configRedux = useSelector((state) => state.config);
  const navigate = useNavigate();

  return (
    <Container sx={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          backgroundColor: `rgba(${
            configRedux.darkMode ? "15,15,15" : "255,255,255"
          },0.8)`,
          position: "relative",
          paddingTop: 3,
          paddingBottom: 2,
          borderRadius: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            component="h1"
            variant="h5"
            sx={{
              textAlign: "center",
              color: configRedux.darkMode ? "dark.primary" : "light.primary",
              mb: 3,
            }}
          >
            404
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              color: configRedux.darkMode ? "dark.primary" : "light.primary",
            }}
          >
            La page demandée n'existe pas.
          </Typography>
          <Button
            onClick={() => {
              navigate("/");
            }}
            size="small"
            fullWidth
            variant="contained"
            sx={{
              mt: 5,
              mb: 4,
              backgroundColor: configRedux.darkMode
                ? "dark.link"
                : "light.link",
              color: configRedux.darkMode
                ? "dark.background"
                : "light.background",
              "&:hover": {
                backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
                opacity: 0.8,
              },
              textTransform: "none",
            }}
          >
            Retourner à l'accueil
          </Button>
        </Box>
      </Container>
    </Container>
  );
}
