import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Radio,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { breakpoints } from "../../utils/responsive";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BadgeIcon from "@mui/icons-material/Badge";
import { colorsTheme } from "../../assets/theme/theme";
import { patchMyProfile } from "../../functions/settings/patchMyProfile";
import { enqueueSnackbar } from "notistack";

const ProfileChoice = () => {
  const configRedux = useSelector((state) => state.config);
  const profileRedux = useSelector((state) => state.profile);
  const authRedux = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [isOpened, setIsOpened] = useState(false);
  const [newProfile, setNewProfile] = useState(profileRedux.profile);
  const [isFetching, setIsFetching] = useState(false);

  const inputElements = [
    {
      id: "particulier-input",
      name: "Particulier",
      value: 0,
    },
    {
      id: "professionnel-input",
      name: "Professionnel",
      value: 1,
    },
    {
      id: "installateur-input",
      name: "Installateur",
      value: 2,
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFetching(true);
    await patchMyProfile({
      token: authRedux.token,
      refreshToken: authRedux.refreshToken,
      id: profileRedux.id,
      dispatch: dispatch,
      addSnack: enqueueSnackbar,
      newProfil: {
        id: profileRedux.id,
        email: profileRedux.email,
        firstName: profileRedux.firstName,
        lastName: profileRedux.lastName,
        phoneNumber: profileRedux.phoneNumber,
        profile: newProfile,
      },
    });
    setIsFetching(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.secondary`,
        pt: 5,
        pr: 2,
        pl: 2,
        pb: 2,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent:
          configRedux.screenWidth > breakpoints.minTablet
            ? "space-around"
            : "center",
        position: "relative",
        boxShadow: 3,
        alignItems: "center",
        rowGap: 2,
        // height: "100%",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: -12,
          left: -12,
          backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
          borderRadius: 4,
          pl: 1,
          pr: 2,
          // width: 32,
          height: 32,
          display: "flex",
          flexDirection: "row",
          columnGap: 1,
          justifyContent: "center",
          alignItems: "center",
          boxShadow: 5,
        }}
      >
        <BadgeIcon />
        <Typography
          sx={{
            color: configRedux.darkMode ? "dark.secondary" : "light.primary",
          }}
        >
          Profil
        </Typography>
      </Box>
      <Accordion
        expanded={isOpened}
        sx={{
          backgroundColor: `${
            configRedux.darkMode ? "dark" : "light"
          }.secondary`,
          color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
          p: 0,
          m: 0,
          width: "100%",
          "&.Mui-expanded": { m: 0, minHeight: 48 },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ m: 0 }}
          onClick={() => {
            setIsOpened(!isOpened);
          }}
        >
          <Typography sx={{ fontSize: 16 }}>
            Votre profil :{" "}
            {profileRedux.profile === 0
              ? "Particulier"
              : profileRedux.profile === 1
              ? "Professionnel"
              : profileRedux.profile === 3 && "Installateur"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: `${
              configRedux.darkMode ? "dark" : "light"
            }.secondary`,
            p: 0,
            mt: 0,
            ml: 0,
            mr: 0,
            mb: 2,
          }}
        >
          <Box
            onSubmit={handleSubmit}
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              m: 0,
              p: 0,
            }}
          >
            <FormGroup
              sx={{
                width: "90%",
              }}
            >
              {inputElements.map((oneInput) => {
                return (
                  <FormControlLabel
                    name={oneInput.name}
                    value={"Test"}
                    key={oneInput.id}
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      justifyContent: "space-between",
                      columnGap: 2,
                      mr: 0,
                      ml: 0,
                      mt: 0,
                      mb: 0.5,
                      width: "100%",
                    }}
                    label={
                      <Typography
                        sx={{
                          color: `${
                            configRedux.darkMode ? "dark" : "light"
                          }.primary`,
                        }}
                      >
                        {oneInput.name}
                      </Typography>
                    }
                    control={
                      <Radio
                        size="small"
                        sx={{
                          "&.MuiButtonBase-root": {
                            color:
                              colorsTheme.palette[
                                configRedux.darkMode ? "dark" : "light"
                              ].link,
                            pl: 0,
                            pr: 0,
                            pb: 0.3,
                            pt: 0.3,
                          },
                        }}
                        id={`profile-choice${oneInput.name}`}
                        checked={oneInput.value === newProfile}
                        onChange={() => {
                          setNewProfile(oneInput.value);
                        }}
                      />
                    }
                  />
                );
              })}
            </FormGroup>
            <Button
              aria-label="save-profile-button"
              disabled={isFetching}
              type="submit"
              size="small"
              variant="contained"
              sx={{
                mt: 1,
                backgroundColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.link`,
                color: `${configRedux.darkMode ? "dark" : "light"}.background`,
                "&:hover": {
                  backgroundColor: `${
                    configRedux.darkMode ? "dark" : "light"
                  }.link`,
                  opacity: 0.8,
                },
                textTransform: "none",
              }}
            >
              Enregistrer
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ProfileChoice;
