const makeDateTime = (date) => {
  let day = new Date(date).getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  let month = new Date(date).getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let year = new Date(date).getFullYear();
  let hours = new Date(date).getHours();
  if (hours < 10) {
    hours = `0${hours}`;
  }
  let minutes = new Date(date).getMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  return `${day}/${month}/${year} • ${hours}:${minutes}`;
};

export default makeDateTime;
