import { createSlice } from "@reduxjs/toolkit";

export const analyse = createSlice({
  name: "analyse",
  initialState: {
    isLoading: true,
    periodChoice: 0,
    detailChoice: 0,
    actualMonth: [],
    actualYear: [],
    actualDay: [],
    actualFetched: [],
    actualFetchedPeriod: "",
    actualFetchedPeriodDetail: "",
    periodDefineYear: new Date(Date.now()).getFullYear(),
    periodDefineMonthMonth: new Date(Date.now()).getMonth(),
    periodDefineMonthYear: new Date(Date.now()).getFullYear(),
    analyseResult: [],
    analyseResultTotalPower: 0,
    analyseResultTotalPrice: 0,
    lastUpdate: undefined,
  },
  reducers: {
    enableAnalyseLoading: (state) => {
      state.isLoading = true;
    },
    disableAnalyseLoading: (state) => {
      state.isLoading = false;
    },
    changeAnalyseDetailChoice: (state, action) => {
      state.detailChoice = action.payload.detail;
    },
    changeAnalysePeriodChoice: (state, action) => {
      state.periodChoice = action.payload.period;
    },
    setAnalyseLastUpdate: (state) => {
      state.lastUpdate = Date.now();
    },
    setAnalyseActualDay: (state, action) => {
      state.actualDay = action.payload.day;
    },
    setAnalyseActualMonth: (state, action) => {
      state.actualDay = action.payload.day;
      state.actualMonth = action.payload.month;
    },
    setAnalyseActualYear: (state, action) => {
      state.actualDay = action.payload.day;
      state.actualMonth = action.payload.month;
      state.actualYear = action.payload.year;
    },
    setAnalyseResult: (state, action) => {
      state.analyseResult = action.payload.result;
      state.analyseResultTotalPower = action.payload.result.reduce(
        (acc, obj) => acc + obj.consommation,
        0
      );
      state.analyseResultTotalPrice = action.payload.result.reduce(
        (acc, obj) => acc + obj.price,
        0
      );
    },
    setAnalysePeriodDefineYear: (state, action) => {
      state.periodDefineYear = action.payload.year;
    },
    setAnalysePeriodDefineMonth: (state, action) => {
      state.periodDefineMonthMonth = action.payload.month;
      state.periodDefineMonthYear = action.payload.year;
    },
    setAnalyseActualFetched: (state, action) => {
      state.actualFetched = action.payload.fetched;
      state.actualFetchedPeriod = action.payload.period;
      state.actualFetchedPeriodDetail = action.payload.periodDetail;
    },
    resetAnalyse: (state) => {
      state.isLoading = true;
      state.actualMonth = [];
      state.actualYear = [];
      state.actualDay = [];
      state.lastUpdate = undefined;
      state.periodChoice = 0;
      state.detailChoice = 0;
      state.analyseResult = [];
      state.periodDefineYear = new Date(Date.now()).getFullYear();
      state.periodDefineMonthMonth = new Date(Date.now()).getMonth();
      state.periodDefineMonthYear = new Date(Date.now()).getFullYear();
      state.actualFetched = [];
      state.actualFetchedPeriod = "";
      state.actualFetchedPeriodDetail = "";
      state.analyseResultTotalPower = 0;
      state.analyseResultTotalPrice = 0;
    },
  },
});

export const {
  enableAnalyseLoading,
  disableAnalyseLoading,
  changeAnalyseDetailChoice,
  changeAnalysePeriodChoice,
  setAnalyseLastUpdate,
  setAnalyseActualDay,
  setAnalyseActualMonth,
  setAnalyseActualYear,
  setAnalyseResult,
  setAnalysePeriodDefineYear,
  setAnalysePeriodDefineMonth,
  setAnalyseActualFetched,
  resetAnalyse,
} = analyse.actions;
