import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EventIcon from "@mui/icons-material/Event";
import makeDateTime from "../../utils/makeDateTime";
import { breakpoints } from "../../utils/responsive";
import eventsCategories from "../../utils/eventsCategories";

const EventsFalse = () => {
  const configRedux = useSelector((state) => state.config);
  const eventsRedux = useSelector((state) => state.events);
  return (
    <Box
      sx={{
        backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.secondary`,
        p: 2,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        position: "relative",
        rowGap: 2,
        boxShadow: 3,
        pl: 2,
        pb: 2,
        pr: 2,
        pt: 5,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: -12,
          left: -12,
          backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
          borderRadius: 4,
          pl: 1,
          pr: 2,
          // width: 32,
          height: 32,
          display: "flex",
          flexDirection: "row",
          columnGap: 1,
          justifyContent: "center",
          alignItems: "center",
          boxShadow: 5,
        }}
      >
        <CalendarMonthIcon />
        <Typography
          sx={{
            color: configRedux.darkMode ? "dark.secondary" : "light.primary",
          }}
        >
          Les évènements marquants
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: 2,
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        {eventsRedux.boardEventsActive.map((oneEvent) => {
          return (
            <Box
              key={`${oneEvent.id}${Math.random()}`}
              sx={{
                width: "100%",
                backgroundColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.background`,
                boxShadow: 2,
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection:
                    configRedux.screenWidth > breakpoints.minTablet
                      ? "row"
                      : "column",
                  rowGap: 1,
                  alignItems: "center",
                  columnGap: 2,
                }}
              >
                <Box
                  sx={{
                    backgroundColor: `${
                      configRedux.darkMode ? "dark" : "light"
                    }.secondary`,
                    p: 1,
                    borderRadius: 2,
                    boxShadow: 1,
                  }}
                >
                  <img
                    // CHANGE IF ID > LIST LENGTH
                    alt={
                      eventsCategories[
                        oneEvent.eventCategory.id < 24
                          ? oneEvent.eventCategory.id - 1
                          : 0
                      ].name
                    }
                    src={
                      eventsCategories[
                        oneEvent.eventCategory.id < 24
                          ? oneEvent.eventCategory.id - 1
                          : 0
                      ].picto
                    }
                    style={{
                      filter: configRedux.darkMode
                        ? "brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(1%) hue-rotate(198deg) brightness(87%) contrast(98%)"
                        : "brightness(0) saturate(100%) invert(3%) sepia(8%) saturate(6%) hue-rotate(332deg) brightness(99%) contrast(101%)",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 1,
                    alignItems:
                      configRedux.screenWidth > breakpoints.minTablet
                        ? "flex-start"
                        : "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: `${
                        configRedux.darkMode ? "dark" : "light"
                      }.primary`,
                    }}
                  >
                    {
                      // CHANGE IF ID > LIST LENGTH
                      eventsCategories[
                        oneEvent.eventCategory.id < 24
                          ? oneEvent.eventCategory.id - 1
                          : 0
                      ].name
                    }
                  </Typography>
                  <Box
                    sx={{ display: "flex", flexDirection: "row", columnGap: 1 }}
                  >
                    <Typography
                      sx={{
                        color: `${
                          configRedux.darkMode ? "dark" : "light"
                        }.primary`,
                      }}
                    >
                      {oneEvent.electronicBoard &&
                        oneEvent.electronicBoard.name}
                      {oneEvent.circuit && oneEvent.circuit.name}
                    </Typography>
                    {oneEvent.circuit && (
                      <>
                        <Typography
                          sx={{
                            color: `${
                              configRedux.darkMode ? "dark" : "light"
                            }.primary`,
                          }}
                        >
                          {"•"}
                        </Typography>
                        <Typography
                          sx={{
                            color: `${
                              configRedux.darkMode ? "dark" : "light"
                            }.primary`,
                          }}
                        >
                          {oneEvent.circuit.location}
                        </Typography>
                      </>
                    )}
                  </Box>
                  <Box
                    sx={{ display: "flex", flexDirection: "row", columnGap: 1 }}
                  >
                    <EventIcon
                      sx={{
                        color: configRedux.darkMode
                          ? "dark.primary"
                          : "light.primary",
                      }}
                    />
                    <Typography
                      sx={{
                        color: `${
                          configRedux.darkMode ? "dark" : "light"
                        }.primary`,
                      }}
                    >
                      {makeDateTime(oneEvent.createdAt)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default EventsFalse;
