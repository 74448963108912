export const makeConsoMonth = (data) => {
  let result = [];
  data.conso // eslint-disable-next-line
    .filter((testConso) => testConso.circuit.sense === "Consommateur") // eslint-disable-next-line
    .map((oneConso) => {
      let indexOfConso = -1;
      let newDate = undefined;
      if (oneConso.month) {
        newDate = new Date(oneConso.month).getDate();
        const isLargeNumber = (element) =>
          element.date === new Date(oneConso.month).getDate();
        indexOfConso = result.findIndex(isLargeNumber);
      } else if (oneConso.day) {
        newDate = new Date(oneConso.day).getDate();
        const isLargeNumber = (element) =>
          element.date === new Date(oneConso.day).getDate();
        indexOfConso = result.findIndex(isLargeNumber);
      } else if (oneConso.startDate) {
        newDate = new Date(oneConso.startDate).getDate();
        const isLargeNumber = (element) =>
          element.date === new Date(oneConso.startDate).getDate();
        indexOfConso = result.findIndex(isLargeNumber);
      }
      if (indexOfConso === -1) {
        result.push({
          date: newDate,
          consommation: oneConso.consommation,
          price: oneConso.prix,
          base: {
            consommation:
              oneConso.codeAbonnement === "BASE" ? oneConso.consommation : 0,
            price: oneConso.codeAbonnement === "BASE" ? oneConso.prix : 0,
          },
          hc: {
            consommation:
              oneConso.codeAbonnement === "HC" ? oneConso.hc.consommation : 0,
            price: oneConso.codeAbonnement === "HC" ? oneConso.hc.prix : 0,
          },
          hp: {
            consommation:
              oneConso.codeAbonnement === "HC" ? oneConso.hp.consommation : 0,
            price: oneConso.codeAbonnement === "HC" ? oneConso.hp.prix : 0,
          },
          blancHc: {
            consommation:
              oneConso.codeAbonnement === "Tempo"
                ? oneConso.blancHc.consommation
                : 0,
            price:
              oneConso.codeAbonnement === "Tempo" ? oneConso.blancHc.prix : 0,
          },
          blancHp: {
            consommation:
              oneConso.codeAbonnement === "Tempo"
                ? oneConso.blancHp.consommation
                : 0,
            price:
              oneConso.codeAbonnement === "Tempo" ? oneConso.blancHp.prix : 0,
          },
          bleuHc: {
            consommation:
              oneConso.codeAbonnement === "Tempo"
                ? oneConso.bleuHc.consommation
                : 0,
            price:
              oneConso.codeAbonnement === "Tempo" ? oneConso.bleuHc.prix : 0,
          },
          bleuHp: {
            consommation:
              oneConso.codeAbonnement === "Tempo"
                ? oneConso.bleuHp.consommation
                : 0,
            price:
              oneConso.codeAbonnement === "Tempo" ? oneConso.bleuHp.prix : 0,
          },
          rougeHc: {
            consommation:
              oneConso.codeAbonnement === "Tempo"
                ? oneConso.rougeHc.consommation
                : 0,
            price:
              oneConso.codeAbonnement === "Tempo" ? oneConso.rougeHc.prix : 0,
          },
          rougeHp: {
            consommation:
              oneConso.codeAbonnement === "Tempo"
                ? oneConso.rougeHp.consommation
                : 0,
            price:
              oneConso.codeAbonnement === "Tempo" ? oneConso.rougeHp.prix : 0,
          },
        });
      } else {
        result[indexOfConso].consommation += oneConso.consommation;
        result[indexOfConso].price += oneConso.prix;
        if (oneConso.codeAbonnement === "BASE") {
          result[indexOfConso].base.consommation += oneConso.consommation;
          result[indexOfConso].base.price += oneConso.prix;
        } else if (oneConso.codeAbonnement === "HC") {
          result[indexOfConso].hc.consommation += oneConso.hc.consommation;
          result[indexOfConso].hc.price += oneConso.hc.prix;
          result[indexOfConso].hp.consommation += oneConso.hp.consommation;
          result[indexOfConso].hp.price += oneConso.hp.prix;
        } else if (oneConso.codeAbonnement === "Tempo") {
          result[indexOfConso].blancHc.consommation +=
            oneConso.blancHc.consommation;
          result[indexOfConso].blancHc.price += oneConso.blancHc.prix;
          result[indexOfConso].blancHp.consommation +=
            oneConso.blancHp.consommation;
          result[indexOfConso].blancHp.price += oneConso.blancHp.prix;
          result[indexOfConso].bleuHc.consommation +=
            oneConso.bleuHc.consommation;
          result[indexOfConso].bleuHc.price += oneConso.bleuHc.prix;
          result[indexOfConso].bleuHp.consommation +=
            oneConso.bleuHp.consommation;
          result[indexOfConso].bleuHp.price += oneConso.bleuHp.prix;
          result[indexOfConso].rougeHc.consommation +=
            oneConso.rougeHc.consommation;
          result[indexOfConso].rougeHc.price += oneConso.rougeHc.prix;
          result[indexOfConso].rougeHp.consommation +=
            oneConso.rougeHp.consommation;
          result[indexOfConso].rougeHp.price += oneConso.rougeHp.prix;
        }
      }
    });
  result.sort((a, b) => a.date - b.date);
  return result;
};
