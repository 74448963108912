import { setActiveEvents } from "../../redux/features/events";
import BASE_URL from "../../utils/api";
import endpoints from "../../utils/endpoints";
import refreshTokenFunction from "../auth/refreshToken";

const getActiveEvents = async (data) => {
  let responseStatus = 0;
  let token = data.token;
  let refreshToken = data.refreshToken;
  const tryFetch = async (fetchToken) => {
    try {
      let fetchActiveEvents = await fetch(
        `${BASE_URL}${endpoints.getActiveEvents}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${fetchToken}`,
          },
          signal: AbortSignal.timeout(3000),
        }
      );
      responseStatus = fetchActiveEvents.status;
      if (fetchActiveEvents.status === 200) {
        console.log("Get active events function : 200");
        let fetchData = await fetchActiveEvents.json();
        data.dispatch(
          setActiveEvents({
            activeEvents:
              fetchData["hydra:totalItems"] > 0
                ? fetchData["hydra:member"]
                : [],
          })
        );
      }
    } catch (error) {
      responseStatus = error.status;
    }
  };

  await tryFetch(token);

  if (responseStatus === 401) {
    console.log("Get active events function : 401");
    let refreshFunction = await refreshTokenFunction({
      refreshToken: data.refreshToken,
      dispatch: data.dispatch,
    });
    if (refreshFunction.status === 200) {
      token = refreshFunction.token;
      refreshToken = refreshFunction.refreshToken;
      await tryFetch(refreshFunction.token);
    }
  }
  responseStatus !== 200 &&
    data.addSnack("Impossible de récupérer les évènements.", {
      variant: "error",
    });
  return {
    status: responseStatus,
    token: token,
    refreshToken: refreshToken,
    mustLoading: data.mustLoading,
  };
};

export default getActiveEvents;
