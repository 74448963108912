import { useSelector } from "react-redux";
import { Box, CircularProgress, alpha } from "@mui/material";
import OneCircuitBox from "../components/circuits/oneCircuitBox";
import NoBoards from "../components/home/noBoards";
import oghjiLogo from "../assets/images/logoOnly.png";
import SearchLine from "../components/circuits/searchLine";
import { breakpoints } from "../utils/responsive";

const CircuitPages = () => {
  const circuitsRedux = useSelector((state) => state.circuits);
  const boardsRedux = useSelector((state) => state.boards);
  const configRedux = useSelector((state) => state.config);

  return (
    <Box
      sx={{
        flexBasis: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems:
          boardsRedux.isLoading || circuitsRedux.circuitsIsLoading
            ? "center"
            : "flex-start",
      }}
    >
      {boardsRedux.isLoading || circuitsRedux.circuitsIsLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              p: 2,
              backgroundColor: alpha(
                configRedux.darkMode ? "#000000" : "#ffffff",
                0.8
              ),
              borderRadius: 100,
              position: "relative",
            }}
          >
            <CircularProgress
              size={70}
              sx={{ color: `${configRedux.darkMode ? "dark" : "light"}.link` }}
            />
            <img
              src={oghjiLogo}
              alt={"Chargement"}
              height={40}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </Box>
        </Box>
      ) : boardsRedux.boards.length === 0 ? (
        <NoBoards />
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            rowGap: 3,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              position: "sticky",
              top: -24,
              zIndex: 1,
            }}
          >
            <SearchLine />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection:
                configRedux.screenWidth <= breakpoints.minTablet
                  ? "column"
                  : "row",
              flexWrap: "wrap",
              justifyContent:
                boardsRedux.isLoading ||
                circuitsRedux.circuitsIsLoading ||
                (!boardsRedux.isLoading && boardsRedux.boards.length === 0)
                  ? "center"
                  : "flex-start",
              rowGap: 2,
              columnGap: 2,
            }}
          >
            {circuitsRedux.boardCircuitsResult.map((oneCircuit) => {
              return (
                <OneCircuitBox
                  circuit={oneCircuit}
                  key={`${oneCircuit.id}${Math.random()}`}
                />
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CircuitPages;
