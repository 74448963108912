const pictos = [
  {
    id: 1,
    picto: require("../assets/images/pictos/1.png"),
    name: "Plaque de cuisson",
  },
  { id: 2, picto: require("../assets/images/pictos/2.png"), name: "Arrosage" },
  { id: 3, picto: require("../assets/images/pictos/3.png"), name: "Ascenseur" },
  { id: 4, picto: require("../assets/images/pictos/4.png"), name: "Balnéo" },
  {
    id: 5,
    picto: require("../assets/images/pictos/5.png"),
    name: "Barrière électrique",
  },
  { id: 6, picto: require("../assets/images/pictos/6.png"), name: "Batterie" },
  {
    id: 7,
    picto: require("../assets/images/pictos/7.png"),
    name: "Box internet",
  },
  {
    id: 8,
    picto: require("../assets/images/pictos/8.png"),
    name: "Caméras de surveillance",
  },
  {
    id: 9,
    picto: require("../assets/images/pictos/9.png"),
    name: "Cave à vin",
  },
  {
    id: 10,
    picto: require("../assets/images/pictos/10.png"),
    name: "Chaudière",
  },
  {
    id: 11,
    picto: require("../assets/images/pictos/11.png"),
    name: "Chauffage",
  },
  {
    id: 12,
    picto: require("../assets/images/pictos/12.png"),
    name: "Chauffe-eau",
  },
  {
    id: 13,
    picto: require("../assets/images/pictos/13.png"),
    name: "Cheminée",
  },
  {
    id: 14,
    picto: require("../assets/images/pictos/14.png"),
    name: "Climatisation réversible",
  },
  {
    id: 15,
    picto: require("../assets/images/pictos/15.png"),
    name: "Climatisation",
  },
  {
    id: 16,
    picto: require("../assets/images/pictos/16.png"),
    name: "Congélateur",
  },
  { id: 17, picto: require("../assets/images/pictos/17.png"), name: "Couture" },
  {
    id: 18,
    picto: require("../assets/images/pictos/18.png"),
    name: "Cuisinière",
  },
  {
    id: 29,
    picto: require("../assets/images/pictos/19.png"),
    name: "Détecteur incendie",
  },
  {
    id: 20,
    picto: require("../assets/images/pictos/20.png"),
    name: "Digicode",
  },
  {
    id: 21,
    picto: require("../assets/images/pictos/21.png"),
    name: "Ecran cinéma",
  },
  {
    id: 22,
    picto: require("../assets/images/pictos/22.png"),
    name: "Eolienne",
  },
  {
    id: 23,
    picto: require("../assets/images/pictos/23.png"),
    name: "Fontaine",
  },
  { id: 24, picto: require("../assets/images/pictos/24.png"), name: "Four" },
  {
    id: 25,
    picto: require("../assets/images/pictos/25.png"),
    name: "Réfrigérateur",
  },
  {
    id: 26,
    picto: require("../assets/images/pictos/26.png"),
    name: "Réfrigérateur américain",
  },
  {
    id: 27,
    picto: require("../assets/images/pictos/27.png"),
    name: "Haut-parleur",
  },
  { id: 28, picto: require("../assets/images/pictos/28.png"), name: "Hi-fi" },
  { id: 29, picto: require("../assets/images/pictos/29.png"), name: "Horloge" },
  { id: 30, picto: require("../assets/images/pictos/30.png"), name: "Hotte" },
  {
    id: 31,
    picto: require("../assets/images/pictos/31.png"),
    name: "Interphone",
  },
  {
    id: 32,
    picto: require("../assets/images/pictos/32.png"),
    name: "Recharge véhicule électrique",
  },
  { id: 33, picto: require("../assets/images/pictos/33.png"), name: "Jacuzzi" },
  {
    id: 34,
    picto: require("../assets/images/pictos/34.png"),
    name: "Jeux extérieurs",
  },
  {
    id: 35,
    picto: require("../assets/images/pictos/35.png"),
    name: "Lave-linge",
  },
  {
    id: 36,
    picto: require("../assets/images/pictos/36.png"),
    name: "Lave-vaisselle",
  },
  {
    id: 37,
    picto: require("../assets/images/pictos/37.png"),
    name: "Lumières",
  },
  {
    id: 38,
    picto: require("../assets/images/pictos/38.png"),
    name: "Machine industrielle",
  },
  {
    id: 39,
    picto: require("../assets/images/pictos/39.png"),
    name: "Micro-ondes",
  },
  {
    id: 40,
    picto: require("../assets/images/pictos/40.png"),
    name: "Monte charge",
  },
  {
    id: 41,
    picto: require("../assets/images/pictos/41.png"),
    name: "Moteur électrique",
  },
  {
    id: 42,
    picto: require("../assets/images/pictos/42.png"),
    name: "Outillage",
  },
  {
    id: 43,
    picto: require("../assets/images/pictos/43.png"),
    name: "Panneaux solaires",
  },
  {
    id: 44,
    picto: require("../assets/images/pictos/44.png"),
    name: "Parabole",
  },
  {
    id: 45,
    picto: require("../assets/images/pictos/45.png"),
    name: "Ordinateur",
  },
  { id: 46, picto: require("../assets/images/pictos/46.png"), name: "Piscine" },
  { id: 47, picto: require("../assets/images/pictos/47.png"), name: "Poele" },
  {
    id: 48,
    picto: require("../assets/images/pictos/48.png"),
    name: "Pompe à chaleur",
  },
  {
    id: 49,
    picto: require("../assets/images/pictos/49.png"),
    name: "Pompe de relevage",
  },
  { id: 50, picto: require("../assets/images/pictos/50.png"), name: "Portail" },
  {
    id: 51,
    picto: require("../assets/images/pictos/51.png"),
    name: "Porte de garage",
  },
  {
    id: 52,
    picto: require("../assets/images/pictos/52.png"),
    name: "Prises et lumières",
  },
  { id: 53, picto: require("../assets/images/pictos/53.png"), name: "Prises" },
  {
    id: 54,
    picto: require("../assets/images/pictos/54.png"),
    name: "Projecteur",
  },
  { id: 55, picto: require("../assets/images/pictos/55.png"), name: "Sauna" },
  {
    id: 56,
    picto: require("../assets/images/pictos/56.png"),
    name: "Sèche-linge",
  },
  {
    id: 57,
    picto: require("../assets/images/pictos/57.png"),
    name: "Sèche-serviettes",
  },
  { id: 58, picto: require("../assets/images/pictos/58.png"), name: "Stores" },
  {
    id: 59,
    picto: require("../assets/images/pictos/59.png"),
    name: "Télévision",
  },
  {
    id: 60,
    picto: require("../assets/images/pictos/60.png"),
    name: "Thermostat",
  },
  {
    id: 61,
    picto: require("../assets/images/pictos/61.png"),
    name: "Ventilation VMC",
  },
  {
    id: 62,
    picto: require("../assets/images/pictos/62.png"),
    name: "Volets roulants",
  },
];

export default pictos;
