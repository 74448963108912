const monthes = [
  { id: 0, name: "Janvier", min: "J" },
  { id: 1, name: "Février", min: "F" },
  { id: 2, name: "Mars", min: "M" },
  { id: 3, name: "Avril", min: "A" },
  { id: 4, name: "Mai", min: "M" },
  { id: 5, name: "Juin", min: "J" },
  { id: 6, name: "Juillet", min: "J" },
  { id: 7, name: "Août", min: "A" },
  { id: 8, name: "Septembre", min: "S" },
  { id: 9, name: "Octobre", min: "O" },
  { id: 10, name: "Novembre", min: "N" },
  { id: 11, name: "Décembre", min: "D" },
];

export const convertMonth = (month) => {
  return monthes.filter((oneMonth) => oneMonth.id === month)[0];
};
