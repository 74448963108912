import { createSlice } from "@reduxjs/toolkit";

export const config = createSlice({
  name: "config",
  initialState: {
    darkMode: false,
    screenWidth: 1240,
    menuIsOpened: false,
    faqQuestionOpened: 0,
    mustRefresh: undefined,
  },
  reducers: {
    // To change mode
    changeDisplayMode: (state) => {
      state.darkMode = !state.darkMode;
    },
    changeScreenWidth: (state, action) => {
      state.screenWidth = action.payload.screenWidth;
    },
    openCloseMenu: (state) => {
      state.menuIsOpened = !state.menuIsOpened;
    },
    closeMenu: (state) => {
      state.menuIsOpened = false;
    },
    changeFaqQuestionOpened: (state, action) => {
      state.faqQuestionOpened = action.payload.question;
    },
    forceRefresh: (state, action) => {
      state.mustRefresh = action.payload.highTarget;
    },
    clearMustRefresh: (state) => {
      state.mustRefresh = undefined;
    },
    resetConfig: (state) => {
      state.menuIsOpened = false;
      state.mustRefresh = undefined;
    },
  },
});

export const {
  changeDisplayMode,
  changeScreenWidth,
  openCloseMenu,
  closeMenu,
  changeFaqQuestionOpened,
  forceRefresh,
  clearMustRefresh,
  resetConfig,
} = config.actions;
