import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress, alpha } from "@mui/material";
import { useSelector } from "react-redux";
import makeDateTime from "../utils/makeDateTime";
import NoBoards from "../components/home/noBoards";
import oghjiLogo from "../assets/images/logoOnly.png";
import FilterLine from "../components/events/filterLine";
import eventsCategories from "../utils/eventsCategories";

const EventsPage = () => {
  const configRedux = useSelector((state) => state.config);
  const circuitsRedux = useSelector((state) => state.circuits);
  const boardsRedux = useSelector((state) => state.boards);
  const eventsRedux = useSelector((state) => state.events);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexBasis: "100%",
        justifyContent:
          boardsRedux.isLoading ||
          circuitsRedux.circuitsIsLoading ||
          eventsRedux.isLoading
            ? "center"
            : "flex-start",
        flexDirection: "column",
        // alignItems: 'center',
        rowGap: 4,
      }}
    >
      {boardsRedux.isLoading ||
      circuitsRedux.circuitsIsLoading ||
      eventsRedux.isLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              p: 2,
              backgroundColor: alpha(
                configRedux.darkMode ? "#000000" : "#ffffff",
                0.8
              ),
              borderRadius: 100,
              position: "relative",
            }}
          >
            <CircularProgress
              size={70}
              sx={{ color: `${configRedux.darkMode ? "dark" : "light"}.link` }}
            />
            <img
              src={oghjiLogo}
              alt={"Chargement"}
              height={40}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </Box>
        </Box>
      ) : boardsRedux.boards.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent:
              boardsRedux.isLoading ||
              circuitsRedux.circuitsIsLoading ||
              (!boardsRedux.isLoading && boardsRedux.boards.length === 0)
                ? "center"
                : "space-between",
            rowGap: 3,
          }}
        >
          <NoBoards />
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            rowGap: 3,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              position: "sticky",
              top: -24,
              zIndex: 1,
            }}
          >
            <FilterLine />
          </Box>
          <Timeline
            sx={{
              // display: "flex",
              // flexDirection: "column",
              // alignItems: "center",
              p: 0,
              m: 0,
            }}
            //   position="left-reverse"
          >
            {eventsRedux.boardEvents.map((oneEvent) => {
              if (
                eventsRedux.filterEventsCategories.includes(
                  Number(oneEvent.eventCategory.id)
                ) &&
                ((oneEvent.circuit &&
                  eventsRedux.filterEventsCircuits.includes(
                    oneEvent.circuit.id
                  )) ||
                  oneEvent.electronicBoard)
              ) {
                return (
                  <TimelineItem
                    key={oneEvent.id}
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <TimelineContent
                      sx={{
                        m: "auto 0",
                        color: `${
                          configRedux.darkMode ? "dark" : "light"
                        }.primary`,
                      }}
                      align="left"
                      variant="body2"
                      color="dark.secondary"
                      maxWidth={125}
                      minWidth={125}
                    >
                      {makeDateTime(oneEvent.createdAt)}
                    </TimelineContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot>
                        <Box
                          sx={{
                            width: 20,
                            height: 20,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={
                              eventsCategories[
                                oneEvent.eventCategory.id < 24
                                  ? oneEvent.eventCategory.id - 1
                                  : 0
                              ].picto
                            }
                            alt={
                              eventsCategories[
                                oneEvent.eventCategory.id < 24
                                  ? oneEvent.eventCategory.id - 1
                                  : 0
                              ].name
                            }
                            style={{
                              filter:
                                "brightness(0) saturate(100%) invert(3%) sepia(8%) saturate(6%) hue-rotate(332deg) brightness(99%) contrast(101%)",
                            }}
                          />
                        </Box>
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineOppositeContent
                      sx={{
                        py: "12px",
                        px: 2,
                        backgroundColor: `${
                          configRedux.darkMode ? "dark" : "light"
                        }.secondary`,
                        borderRadius: 2,
                        m: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        maxWidth: "80%",
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="span"
                        sx={{
                          color: `${
                            configRedux.darkMode ? "dark" : "light"
                          }.primary`,
                        }}
                      >
                        {
                          eventsCategories[
                            oneEvent.eventCategory.id < 24
                              ? oneEvent.eventCategory.id - 1
                              : 0
                          ].name
                        }
                      </Typography>
                      <Typography
                        sx={{
                          color: `${
                            configRedux.darkMode ? "dark" : "light"
                          }.primary`,
                        }}
                      >
                        {oneEvent.electronicBoard
                          ? oneEvent.electronicBoard.name
                          : `${oneEvent.circuit.name} • ${oneEvent.circuit.location}`}
                      </Typography>
                    </TimelineOppositeContent>
                  </TimelineItem>
                );
              } else {
                return <></>;
              }
            })}
          </Timeline>
        </Box>
      )}
    </Box>
  );
};

export default EventsPage;
