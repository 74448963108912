import {
  Box,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Menu,
  Radio,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  changeCircuitsSearch,
  changeCircuitsSortBy,
} from "../../redux/features/circuits";
import { useState } from "react";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { breakpoints } from "../../utils/responsive";
import { colorsTheme } from "../../assets/theme/theme";

const SearchLine = () => {
  const configRedux = useSelector((state) => state.config);
  const circuitsRedux = useSelector((state) => state.circuits);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const filters = [
    { text: "Par ordre alphabétique", attribute: "abc" },
    { text: "Circuits allumés en premier", attribute: "onFirst" },
    { text: "Circuits éteints en premier", attribute: "offFirst" },
    { text: "Par consommation croissante", attribute: "lowFirst" },
    { text: "Par consommation décroissante", attribute: "highFirst" },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.secondary`,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 2,
        p: 2,
      }}
    >
      <TextField
        value={circuitsRedux.circuitsSearch}
        size="small"
        sx={{
          flexBasis:
            configRedux.screenWidth > breakpoints.minTablet ? "50%" : "80%",
          ".MuiOutlinedInput-root": {
            borderRadius: 50,
          },
          input: {
            color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: configRedux.darkMode
                ? "dark.primary"
                : "light.primary",
            },
            "&:hover fieldset": {
              borderColor: configRedux.darkMode ? "#848484" : "#aaaaaa",
            },
            "&.Mui-focused fieldset": {
              borderColor: configRedux.darkMode
                ? "dark.primary"
                : "light.primary",
            },
          },
        }}
        onChange={(element) => {
          dispatch(changeCircuitsSearch({ search: element.target.value }));
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon
                sx={{
                  color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
                }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title={circuitsRedux.circuitsSearch === "" ? "" : "Effacer"}
              >
                <span>
                  <IconButton
                    disabled={circuitsRedux.circuitsSearch === ""}
                    aria-label="delete search"
                    onClick={() => {
                      dispatch(changeCircuitsSearch({ search: "" }));
                    }}
                    edge="end"
                  >
                    <HighlightOffIcon
                      sx={{
                        color: `${
                          configRedux.darkMode ? "dark" : "light"
                        }.primary`,
                      }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <Tooltip title="Trier par">
        <IconButton
          onClick={handleClick}
          sx={{ ml: 1 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <SwapVertIcon
            sx={{ color: `${configRedux.darkMode ? "dark" : "light"}.primary` }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "& .MuiPaper-root": {
            backgroundColor: configRedux.darkMode
              ? "dark.background"
              : "light.background",
          },
          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <FormGroup>
          {filters.map((oneFilter) => (
            <FormControlLabel
              value={oneFilter.attribute}
              key={oneFilter.attribute}
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "space-between",
                columnGap: 2,
                pl: 2,
                pr: 2,
                m: 0,
              }}
              // label={`${oneCircuit.name} • ${oneCircuit.location}`}
              label={
                <Typography
                  sx={{
                    fontSize: 13,
                    color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
                  }}
                >
                  {oneFilter.text}
                </Typography>
              }
              control={
                <Radio
                  size="small"
                  sx={{
                    "&.MuiButtonBase-root": {
                      color:
                        colorsTheme.palette[
                          configRedux.darkMode ? "dark" : "light"
                        ].link,
                      pl: 0,
                      pr: 0,
                      pb: 0.3,
                      pt: 0.3,
                    },
                  }}
                  id={`sortby-${oneFilter.attribute}`}
                  checked={circuitsRedux.circuitsSortBy === oneFilter.attribute}
                  onChange={() => {
                    handleClose();
                    dispatch(
                      changeCircuitsSortBy({ sortBy: oneFilter.attribute })
                    );
                  }}
                />
              }
            />
          ))}
        </FormGroup>
      </Menu>
    </Box>
  );
};

export default SearchLine;
