import { Box, Typography, alpha } from "@mui/material";
import { useSelector } from "react-redux";
import BoltIcon from "@mui/icons-material/Bolt";

const NoBoards = () => {
  const configRedux = useSelector((state) => state.config);

  return (
    <Box
      sx={{
        mt: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: alpha(
            configRedux.darkMode ? "#000000" : "#ffffff",
            0.8
          ),
          p: 3,
          display: "flex",
          flexDirection: "column",
          rowGap: 2,
          alignItems: "center",
          maxWidth: 500,
          borderRadius: 3,
        }}
      >
        <BoltIcon
          sx={{
            fontSize: 50,
            color: `${configRedux.darkMode ? "dark" : "light"}.link`,
          }}
        />
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: "600",
            color: `${configRedux.darkMode ? "dark" : "light"}.link`,
          }}
        >
          Pas de tableau, pas de page d'accueil !
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "400",
            textAlign: "justify",
            color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
          }}
        >
          Cet écran est aussi vide qu'une batterie déchargée. Branchez votre
          tableau électrique pour charger votre expérience avec Oghji !
        </Typography>
      </Box>
    </Box>
  );
};

export default NoBoards;
