import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import { breakpoints } from "../../utils/responsive";
import TextFieldProfile from "./textFieldProfile";
import { useState } from "react";
import { patchMyProfile } from "../../functions/settings/patchMyProfile";
import { enqueueSnackbar } from "notistack";

const MyInformations = () => {
  const configRedux = useSelector((state) => state.config);
  const profileRedux = useSelector((state) => state.profile);
  const authRedux = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [newLastName, setNewLastName] = useState(profileRedux.lastName);
  const [newFirstName, setNewFirstName] = useState(profileRedux.firstName);
  const [newPhoneNumber, setNewPhoneNumber] = useState(
    profileRedux.phoneNumber
  );
  const [newEmail, setNewEmail] = useState(profileRedux.email);
  const [isFetching, setIsFetching] = useState(false);

  const inputElements = [
    {
      id: "myLastName",
      type: "text",
      error: false,
      errorTxt: "Format incorrect",
      textId: "input-lastname-profile",
      label: "Nom",
      required: false,
      value: newLastName,
      prefix: "",
      onChange: (text) => {
        setNewLastName(text.target.value);
      },
    },
    {
      id: "myFirstName",
      type: "text",
      error: false,
      errorTxt: "Format incorrect",
      textId: "input-firstname-profile",
      label: "Prénom",
      required: false,
      prefix: "",
      value: newFirstName,
      onChange: (text) => {
        setNewFirstName(text.target.value);
      },
    },
    {
      id: "myPhoneNumber",
      type: "text",
      error: false,
      errorTxt: "Format incorrect",
      textId: "input-phonenumber-profile",
      label: "Numéro de téléphone",
      required: false,
      prefix: "+33 ",
      value: newPhoneNumber,
      onChange: (text) => {
        setNewPhoneNumber(text.target.value.replace(/[^0-9]/g, ""));
      },
    },
    {
      id: "myEmail",
      type: "email",
      error: false,
      errorTxt: "Format incorrect",
      textId: "input-email-profile",
      label: "Adresse email",
      required: true,
      prefix: "",
      value: newEmail,
      onChange: (text) => {
        setNewEmail(text.target.value);
      },
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFetching(true);
    await patchMyProfile({
      token: authRedux.token,
      refreshToken: authRedux.refreshToken,
      id: profileRedux.id,
      dispatch: dispatch,
      addSnack: enqueueSnackbar,
      newProfil: {
        id: profileRedux.id,
        email: newEmail,
        firstName: newFirstName,
        lastName: newLastName,
        phoneNumber: newPhoneNumber,
        profile: profileRedux.profile,
      },
    });
    setIsFetching(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.secondary`,
        p: 2,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        rowGap: 2,
        boxShadow: 3,
        pl: 2,
        pb: 2,
        pr: 2,
        pt: 5,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: -12,
          left: -12,
          backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
          borderRadius: 4,
          pl: 1,
          pr: 2,
          // width: 32,
          height: 32,
          display: "flex",
          flexDirection: "row",
          columnGap: 1,
          justifyContent: "center",
          alignItems: "center",
          boxShadow: 5,
        }}
      >
        <FolderSharedIcon />
        <Typography
          sx={{
            color: configRedux.darkMode ? "dark.secondary" : "light.primary",
          }}
        >
          Mes informations
        </Typography>
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: "100%",
          p: 0,
          m: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          rowGap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection:
              configRedux.screenWidth > breakpoints.minTablet
                ? "row"
                : "column",
            rowGap: 0,
            flexWrap:
              configRedux.screenWidth > breakpoints.minTablet
                ? "wrap"
                : "nowrap",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          {inputElements.map((oneInupt) => {
            return (
              <TextFieldProfile
                data={oneInupt}
                key={`${oneInupt.id}-element`}
              />
            );
          })}
        </Box>
        <Button
          aria-label="save-profile-button"
          disabled={isFetching}
          type="submit"
          size="small"
          variant="contained"
          sx={{
            backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
            color: `${configRedux.darkMode ? "dark" : "light"}.background`,
            "&:hover": {
              backgroundColor: `${
                configRedux.darkMode ? "dark" : "light"
              }.link`,
              opacity: 0.8,
            },
            textTransform: "none",
          }}
        >
          Enregistrer
        </Button>
      </Box>
    </Box>
  );
};

export default MyInformations;
