// src/components/Footer.js
import React from "react";
import { Box, Typography, Button, Link } from "@mui/material";
import { useSelector } from "react-redux";
import { breakpoints } from "../../utils/responsive";

const Footer = () => {
  const configRedux = useSelector((state) => state.config);
  return (
    <Box
      component="footer"
      sx={{
        width: "100vw",
        bgcolor: configRedux.darkMode ? "dark.secondary" : "light.secondary",
        paddingBottom: 1,
        paddingTop: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingRight: 0,
        paddingLeft: 0,
        alignItems: "center",
        position: "fixed",
        bottom: 0,
        zIndex: 1399,
      }}
    >
      {/* {configRedux.screenWidth > breakpoints.minDesktop && (
        <Box sx={{ flexBasis: "40%", height: 1 }}></Box>
      )} */}
      <Box
        sx={{
          flexBasis:
            configRedux.screenWidth > breakpoints.minTablet ? "auto%" : "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent:
            configRedux.screenWidth > breakpoints.minTablet ? "left" : "center",
        }}
      >
        {configRedux.screenWidth > breakpoints.minTablet && (
          <Box sx={{ width: 20, height: 1 }}></Box>
        )}
        <Typography
          variant="body2"
          color={configRedux.darkMode ? "dark.primary" : "light.primary"}
          align="center"
        >
          © Oghji {new Date().getFullYear()}
        </Typography>
      </Box>
      {configRedux.screenWidth > breakpoints.minTablet && (
        <Box
          sx={{
            flexBasis: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
          }}
        >
          <Button
            component={Link}
            target="_blank"
            href="https://www.oghji.com"
            sx={{
              color: configRedux.darkMode ? "dark.primary" : "light.primary",
              textTransform: "none",
            }}
          >
            Oghji
          </Button>
          <Button
            component={Link}
            target="_blank"
            href="https://www.oghji.com"
            sx={{
              color: configRedux.darkMode ? "dark.primary" : "light.primary",
              textTransform: "none",
            }}
          >
            FAQ
          </Button>
          <Button
            component={Link}
            target="_blank"
            href="https://www.oghji.com"
            sx={{
              color: configRedux.darkMode ? "dark.primary" : "light.primary",
              textTransform: "none",
            }}
          >
            Conditions d'utilisation
          </Button>
          <Box sx={{ width: 20, height: 1 }}></Box>
        </Box>
      )}
    </Box>
  );
};

export default Footer;
