import {
  fetchLoginSuccess,
  resetAuth,
  startFetchLogin,
} from "../../redux/features/auth";
import BASE_URL from "../../utils/api";
import endpoints from "../../utils/endpoints";

const loginClassic = async (data) => {
  data.dispatch(startFetchLogin());
  try {
    const response = await fetch(`${BASE_URL}${endpoints.login}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data.credentials),
      signal: AbortSignal.timeout(3000),
    });
    const fetchData = await response.json();
    if (response.ok) {
      console.log("Login classic function : 200");
      data.enqueueSnackbar("Connexion réussie.", {
        variant: "success",
      });
      data.dispatch(fetchLoginSuccess(fetchData));
      data.navigate("/home");
    } else {
      data.dispatch(resetAuth());
      data.enqueueSnackbar("Une erreur est survenue. Veuillez réessayer.", {
        variant: "error",
      });
    }
  } catch (error) {
    data.dispatch(resetAuth());
    data.enqueueSnackbar("Une erreur est survenue. Veuillez réessayer.", {
      variant: "error",
    });
  }
};

export default loginClassic;
