import { closeSnackbar, enqueueSnackbar } from "notistack";
import { resetAuth } from "../../redux/features/auth";
import { resetElectronicBoards } from "../../redux/features/boards";
import { resetAllCircuits } from "../../redux/features/circuits";
import { resetEvents } from "../../redux/features/events";
import { resetConfig } from "../../redux/features/config";
import { resetProfile } from "../../redux/features/profile";
import { resetConso } from "../../redux/features/conso";
import { resetAnalyse } from "../../redux/features/analyse";

const logout = (dispatch, navigate) => {
  closeSnackbar();
  dispatch(resetAuth());
  dispatch(resetAllCircuits());
  dispatch(resetElectronicBoards());
  dispatch(resetEvents());
  dispatch(resetConfig());
  dispatch(resetProfile());
  dispatch(resetConso());
  dispatch(resetAnalyse());
  navigate("/");
  enqueueSnackbar("Vous avez bien été déconnecté.", { variant: "success" });
};

export default logout;
