import { forceRefresh } from "../../redux/features/config";
import BASE_URL from "../../utils/api";
import endpoints from "../../utils/endpoints";
import refreshTokenFunction from "../auth/refreshToken";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PoolIcon from "@mui/icons-material/Pool";

export const patchBoardMode = async (data) => {
  let responseStatus = 0;
  const tryFetch = async (token) => {
    try {
      let fetchPatchCircuit = await fetch(
        `${BASE_URL}${endpoints.patchBoardMode}/${data.boardId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/merge-patch+json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ mode: data.newMode }),
          signal: AbortSignal.timeout(3000),
        }
      );
      responseStatus = fetchPatchCircuit.status;
      if (fetchPatchCircuit.ok) {
        console.log("Patch board mode function : 200");
        data.dispatch(forceRefresh({ highTarget: "circuitsWithoutLoading" }));
      }
    } catch (error) {
      responseStatus = error.status;
    }
  };

  await tryFetch(data.token);

  if (responseStatus === 401) {
    console.log("Patch circuits function : 401");
    let refreshFunction = await refreshTokenFunction({
      refreshToken: data.refreshToken,
      dispatch: data.dispatch,
    });
    refreshFunction.status === 200 && (await tryFetch(refreshFunction.token));
  }
  responseStatus === 200
    ? data.addSnack(
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: "10px",
          }}
        >
          <span>
            {"Le mode "}
            <em>
              <u>
                {data.newMode === 0
                  ? "Présent"
                  : data.newMode === 1
                  ? "Absent"
                  : "Vacances"}
              </u>
            </em>
            {" a eté activé"}
          </span>
          {data.newMode === 0 ? (
            <PersonIcon />
          ) : data.newMode === 1 ? (
            <PersonOffIcon />
          ) : (
            <PoolIcon />
          )}
        </div>,
        {
          variant: "success",
        }
      )
    : data.addSnack("Impossible de modifier le mode", {
        variant: "error",
      });
  return responseStatus;
};
