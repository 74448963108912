export const technicalInfosTypes = {
  typeChauffage: [
    { id: 0, name: "Electrique" },
    { id: 1, name: "Gaz" },
    { id: 2, name: "Fuel" },
    { id: 3, name: "Solaire" },
    { id: 4, name: "Bois" },
  ],
  typePlaque: [
    { id: 0, name: "Electrique" },
    { id: 1, name: "Gaz" },
    { id: 2, name: "Mixte" },
  ],
  typeChauffeEau: [
    { id: 0, name: "Electrique" },
    { id: 1, name: "Gaz" },
    { id: 2, name: "Solaire" },
  ],
  typeFour: [
    { id: 0, name: "Electrique" },
    { id: 1, name: "Gaz" },
  ],
};
