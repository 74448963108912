import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import { useEffect, useState } from "react";
import { checkBoardPermissions } from "../../functions/settings/checkBoardPersmissions";
import oghjiLogo from "../../assets/images/logoOnly.png";
import KeyIcon from "@mui/icons-material/Key";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const AccessManagement = () => {
  const configRedux = useSelector((state) => state.config);
  const boardsRedux = useSelector((state) => state.boards);
  const profileRedux = useSelector((state) => state.profile);

  const params = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteUser = async () => {
    setIsLoading(true);
    setIsLoading(false);
  };

  useEffect(() => {
    // console.log(boardsRedux.boards[boardsRedux.actualBoard - 1]);
    if (
      !checkBoardPermissions({
        boardId: params.boardId,
        userId: profileRedux.id,
        boards: boardsRedux.boards,
      }).isOwner
    ) {
      navigate("/error");
    } // eslint-disable-next-line
  }, []);

  return isLoading ? (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexBasis: "100%",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: alpha(
            configRedux.darkMode ? "#000000" : "#ffffff",
            0.8
          ),
          borderRadius: 100,
          position: "relative",
        }}
      >
        <CircularProgress
          size={70}
          sx={{ color: `${configRedux.darkMode ? "dark" : "light"}.link` }}
        />
        <img
          src={oghjiLogo}
          alt={"Chargement"}
          height={40}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        rowGap: 2,
      }}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-delete-user"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-delete-userr">
          {"Suppression de l'utilisateur"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Confirmer la suppression de l'utilisateur ${
              userToDelete ? userToDelete.user.email : ""
            } ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            onClick={() => {
              handleClose();
              deleteUser();
            }}
            autoFocus
          >
            Supprimer l'utilisateur
          </Button>
        </DialogActions>
      </Dialog>
      <List
        sx={{
          width: "100%",
          backgroundColor: `${
            configRedux.darkMode ? "dark" : "light"
          }.secondary`,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          mt: 1,
          pt: 4,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: -12,
            left: -12,
            backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
            borderRadius: 4,
            pl: 1,
            pr: 2,
            // width: 32,
            height: 32,
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            justifyContent: "center",
            alignItems: "center",
            boxShadow: 5,
          }}
        >
          <KeyIcon />
          <Typography
            sx={{
              color: configRedux.darkMode ? "dark.secondary" : "light.primary",
            }}
          >
            {`${
              boardsRedux.boards.filter(
                (oneBoard) => oneBoard.id === Number(params.boardId)
              ).length === 1 &&
              boardsRedux.boards.filter(
                (oneBoard) => oneBoard.id === Number(params.boardId)
              )[0].name
            } - Gestion des accès`}
          </Typography>
        </Box>
        {boardsRedux.boards[boardsRedux.actualBoard - 1].configurations.map(
          (oneItem) => {
            return (
              <ListItem
                key={oneItem.user.email}
                sx={{
                  width: "calc(100% - 32px)",
                  backgroundColor: `${
                    configRedux.darkMode ? "dark" : "light"
                  }.background`,
                  mt: 1,
                  mb: 1,
                  mr: 2,
                  ml: 2,
                  p: 0,
                  borderRadius: 2,
                }}
                secondaryAction={
                  oneItem.user.id !== profileRedux.id && (
                    <IconButton
                      disabled={isLoading}
                      onClick={async () => {
                        // deleteUser();
                        setUserToDelete(oneItem);
                        handleClickOpen();
                      }}
                    >
                      <DeleteForeverIcon
                        sx={{
                          color: `${
                            configRedux.darkMode ? "dark" : "light"
                          }.primary`,
                        }}
                      />
                    </IconButton>
                  )
                }
              >
                <Box sx={{ borderRadius: 2, pt: 1, pr: 2, pb: 1, pl: 2 }}>
                  <ListItemText
                    sx={{
                      color: `${
                        configRedux.darkMode ? "dark" : "light"
                      }.primary`,
                    }}
                    id={"test"}
                    primary={oneItem.user.email}
                  />
                </Box>
              </ListItem>
            );
          }
        )}
      </List>
      <Button
        onClick={() => {
          navigate(`/boards/${params.boardId}`);
        }}
        aria-label="add-board-button"
        size="small"
        // fullWidth
        variant="contained"
        sx={{
          mt: 0,
          mr: 0,
          ml: 0,
          mb: 2,
          backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
          color: `${configRedux.darkMode ? "dark" : "light"}.background`,
          "&:hover": {
            backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
            opacity: 0.8,
          },
          textTransform: "none",
          display: "flex",
          flexDirection: "row",
          columnGap: 1,
          alignItems: "center",
        }}
      >
        <ReplyIcon />
        Retour
      </Button>
    </Box>
  );
};

export default AccessManagement;
