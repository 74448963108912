// src/pages/LoginPage.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Box, Typography, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import loginClassic from "../../functions/auth/loginClassic";
import emailValidator from "../../helpers/emailValidator";
import TextFieldWelcome from "../welcome/textfieldWelcome";
import FacebookLoginButton from "../welcome/facebookLoginButton";

export default function LoginPage() {
  const configRedux = useSelector((state) => state.config);
  const authRedux = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    emailValidator(credentials.username) &&
      (await loginClassic({
        dispatch: dispatch,
        credentials: credentials,
        enqueueSnackbar: enqueueSnackbar,
        navigate: navigate,
      }));
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        p: 0,
      }}
    >
      <Box
        sx={{
          marginTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextFieldWelcome
            data={{
              error:
                !emailValidator(credentials.username) &&
                credentials.username.length > 0,
              errorText: "Adresse email incorrecte",
              id: "username",
              type: "email",
              autoComplete: "username",
              testId: "username-input",
              label: "Adresse mail",
              value: credentials.username,
              onChange: handleChange,
            }}
          />
          <TextFieldWelcome
            data={{
              error:
                credentials.password.length < 8 &&
                credentials.password.length !== 0,
              errorText: "Minimum 8 caractères",
              id: "password",
              type: "password",
              autoComplete: "current-password",
              testId: "password-input",
              label: "Mot de passe",
              value: credentials.password,
              onChange: handleChange,
            }}
          />
          <Button
            aria-label="connect-button"
            disabled={authRedux.isLoading}
            type="submit"
            size="small"
            fullWidth
            variant="contained"
            sx={{
              mt: 7,
              mb: 4,
              backgroundColor: `${
                configRedux.darkMode ? "dark" : "light"
              }.link`,
              color: `${configRedux.darkMode ? "dark" : "light"}.background`,
              "&:hover": {
                backgroundColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.link`,
                opacity: 0.8,
              },
              textTransform: "none",
            }}
          >
            Se connecter
          </Button>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              columnGap: 5,
              paddingBottom: 25,
            }}
          >
            <Divider
              color={configRedux.darkMode ? "#d9d9d9" : "#0f0f0f"}
              sx={{ width: "25%", marginBottom: 0 }}
            />
            <Typography
              sx={{ m: 0, p: 0, fontSize: 10, backgroundColor: "transparent" }}
              color={`${configRedux.darkMode ? "dark" : "light"}.primary`}
            >
              | |
            </Typography>
            <Divider
              color={configRedux.darkMode ? "#d9d9d9" : "#0f0f0f"}
              sx={{ width: "25%", marginBottom: 0 }}
            />
          </div>
          <FacebookLoginButton
            data={{
              dispatch: dispatch,
              enqueueSnackbar: enqueueSnackbar,
              navigate: navigate,
            }}
          />
        </Box>
      </Box>
    </Container>
  );
}
