import { createSlice } from "@reduxjs/toolkit";

export const conso = createSlice({
  name: "conso",
  initialState: {
    isLoading: true,
    periodChoice: 2,
    detailChoice: 0,
    actualDay: [],
    actualMonth: [],
    actualYear: [],
    actualFetched: [],
    actualFetchedPeriod: "",
    actualFetchedPeriodDetail: "",
    periodDefineYear: new Date(Date.now()).getFullYear(),
    periodDefineMonthMonth: new Date(Date.now()).getMonth(),
    periodDefineMonthYear: new Date(Date.now()).getFullYear(),
    periodDefineDayDay: new Date(Date.now()).getDate(),
    periodDefineDayMonth: new Date(Date.now()).getMonth(),
    periodDefineDayYear: new Date(Date.now()).getFullYear(),
    consoResult: [],
    consoResultTotalPower: 0,
    consoResultTotalPrice: 0,
    lastUpdate: undefined,
  },
  reducers: {
    enableConsoLoading: (state) => {
      state.isLoading = true;
    },
    disableConsoLoading: (state) => {
      state.isLoading = false;
    },
    changeDetailChoice: (state, action) => {
      state.detailChoice = action.payload.detail;
    },
    changePeriodChoice: (state, action) => {
      state.periodChoice = action.payload.period;
    },
    setLastUpdate: (state) => {
      state.lastUpdate = Date.now();
    },
    setActualDay: (state, action) => {
      state.actualDay = action.payload.day;
    },
    setActualMonth: (state, action) => {
      state.actualDay = action.payload.day;
      state.actualMonth = action.payload.month;
    },
    setActualYear: (state, action) => {
      state.actualDay = action.payload.day;
      state.actualMonth = action.payload.month;
      state.actualYear = action.payload.year;
    },
    setConsoResult: (state, action) => {
      state.consoResult = action.payload.result;
      state.consoResultTotalPower = action.payload.result.reduce(
        (acc, obj) => acc + obj.consommation,
        0
      );
      state.consoResultTotalPrice = action.payload.result.reduce(
        (acc, obj) => acc + obj.price,
        0
      );
    },
    setPeriodDefineYear: (state, action) => {
      state.periodDefineYear = action.payload.year;
    },
    setPeriodDefineMonth: (state, action) => {
      state.periodDefineMonthMonth = action.payload.month;
      state.periodDefineMonthYear = action.payload.year;
    },
    setPeriodDefineDay: (state, action) => {
      state.periodDefineDayDay = action.payload.day;
      state.periodDefineDayMonth = action.payload.month;
      state.periodDefineDayYear = action.payload.year;
    },
    setActualFetched: (state, action) => {
      state.actualFetched = action.payload.fetched;
      state.actualFetchedPeriod = action.payload.period;
      state.actualFetchedPeriodDetail = action.payload.periodDetail;
    },
    clickOnAxisFromAll: (state, action) => {
      state.periodChoice = 3;
      state.periodDefineYear = action.payload.year;
    },
    clickOnAxisFromYear: (state, action) => {
      state.periodChoice = 2;
      state.periodDefineMonthMonth = action.payload.month;
      state.periodDefineMonthYear = state.periodDefineYear;
    },
    clickOnAxisFromMonth: (state, action) => {
      state.periodChoice = 1;
      state.periodDefineDayDay = action.payload.day;
      state.periodDefineDayMonth = state.periodDefineMonthMonth;
      state.periodDefineDayYear = state.periodDefineMonthYear;
    },
    resetConso: (state) => {
      state.isLoading = true;
      state.actualDay = [];
      state.actualMonth = [];
      state.actualYear = [];
      state.lastUpdate = undefined;
      state.periodChoice = 2;
      state.detailChoice = 0;
      state.consoResult = [];
      state.periodDefineYear = new Date(Date.now()).getFullYear();
      state.periodDefineMonthMonth = new Date(Date.now()).getMonth();
      state.periodDefineMonthYear = new Date(Date.now()).getFullYear();
      state.periodDefineDayDay = new Date(Date.now()).getDate();
      state.periodDefineDayMonth = new Date(Date.now()).getMonth();
      state.periodDefineDayYear = new Date(Date.now()).getFullYear();
      state.actualFetched = [];
      state.actualFetchedPeriod = "";
      state.actualFetchedPeriodDetail = "";
      state.consoResultTotalPower = 0;
      state.consoResultTotalPrice = 0;
    },
  },
});

export const {
  enableConsoLoading,
  disableConsoLoading,
  changePeriodChoice,
  changeDetailChoice,
  setLastUpdate,
  setActualDay,
  setActualMonth,
  setActualYear,
  setPeriodDefineYear,
  setPeriodDefineMonth,
  setPeriodDefineDay,
  setConsoResult,
  setActualFetched,
  clickOnAxisFromAll,
  clickOnAxisFromYear,
  clickOnAxisFromMonth,
  resetConso,
} = conso.actions;
