import BASE_URL from "../../utils/api";
import endpoints from "../../utils/endpoints";
import refreshTokenFunction from "../auth/refreshToken";

export const changePassword = async (data) => {
  let responseStatus = 0;
  const tryFetch = async (token) => {
    try {
      let fetchChangePAssword = await fetch(
        `${BASE_URL}${endpoints.changePassword}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/merge-patch+json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data.newPassword),
          signal: AbortSignal.timeout(3000),
        }
      );
      responseStatus = fetchChangePAssword.status;
      if (fetchChangePAssword.status === 200) {
        console.log("Patch change password function : 200");
      }
    } catch (error) {
      responseStatus = error.status;
    }
  };

  await tryFetch(data.token);

  if (responseStatus === 401) {
    console.log("Patch change password function : 401");
    let refreshFunction = await refreshTokenFunction({
      refreshToken: data.refreshToken,
      dispatch: data.dispatch,
    });
    refreshFunction.status === 200 && (await tryFetch(refreshFunction.token));
  }
  responseStatus === 200
    ? data.addSnack("Mot de passe mis à jour avec succès !", {
        variant: "success",
      })
    : data.addSnack("impossible de mettre à jour votre mot de passe", {
        variant: "error",
      });
  return responseStatus;
};
