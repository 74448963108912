import { createSlice } from "@reduxjs/toolkit";

export const auth = createSlice({
  name: "auth",
  initialState: {
    status: false,
    welcomeComponent: 0,
    token: undefined,
    refreshToken: undefined,
    isLoading: false,
  },
  reducers: {
    // To disconnect
    logout: (state) => {
      state.status = false;
      state.token = undefined;
      state.refreshToken = undefined;
      state.isLoading = false;
    },
    // To activate loading during fetch
    startFetchLogin: (state) => {
      state.isLoading = true;
    },
    // To save data when fetch response is OK
    fetchLoginSuccess: (state, action) => {
      state.status = true;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refresh_token;
      state.welcomeComponent = 0;
    },
    switchToLogin: (state) => {
      state.welcomeComponent = 0;
    },
    switchToRegister: (state) => {
      state.welcomeComponent = 1;
    },
    // To reset all the feature
    resetAuth: (state) => {
      state.status = false;
      state.welcomeComponent = 0;
      state.token = undefined;
      state.refreshToken = undefined;
      state.isLoading = false;
    },
  },
});

export const {
  logout,
  startFetchLogin,
  fetchLoginSuccess,
  switchToLogin,
  switchToRegister,
  resetAuth,
} = auth.actions;
