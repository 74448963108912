import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { menuElements } from "./menuElements";
import { useLocation, useNavigate } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import { closeMenu } from "../../redux/features/config";

export const MenuMobileMaker = () => {
  const configRedux = useSelector((state) => state.config);
  const authRedux = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const drawerWidth = 240;

  return (
    <Drawer
      open={configRedux.menuIsOpened}
      onClose={() => {
        dispatch(closeMenu());
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: configRedux.darkMode
            ? "dark.secondary"
            : "light.secondary",
        },
      }}
    >
      <Toolbar sx={{ backgroundColor: "blue", minHeight: "64px" }} />
      <Box
        sx={{
          overflow: "auto",
          backgroundColor: configRedux.darkMode
            ? "dark.secondary"
            : "light.secondary",
        }}
      >
        <List>
          {menuElements[authRedux.status ? "logged" : "unlogged"].map(
            (text, index) => (
              <ListItem key={text.name}>
                <ListItemButton
                  sx={{
                    borderRadius: 2,
                    backgroundColor:
                      location.pathname === text.link
                        ? configRedux.darkMode
                          ? "dark.link"
                          : "light.link"
                        : configRedux.darkMode
                        ? "dark.secondary"
                        : "light.secondary",
                    "&:hover": {
                      backgroundColor:
                        location.pathname === text.link
                          ? configRedux.darkMode
                            ? "dark.link"
                            : "light.link"
                          : configRedux.darkMode
                          ? "dark.background"
                          : "light.background",
                    },
                  }}
                  onClick={() => {
                    dispatch(closeMenu());
                    navigate(text.link);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: configRedux.darkMode
                        ? location.pathname === text.link
                          ? "dark.secondary"
                          : "dark.primary"
                        : "light.primary",
                    }}
                  >
                    {text.icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color: configRedux.darkMode
                        ? location.pathname === text.link
                          ? "dark.secondary"
                          : "dark.primary"
                        : "light.primary",
                    }}
                    primary={text.name}
                  />
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
        <Divider />
        <List>
          {[{ name: "FAQ", icon: <HelpIcon />, link: "/faq" }].map(
            (text, index) => (
              <ListItem key={text.name}>
                <ListItemButton
                  sx={{
                    borderRadius: 2,
                    backgroundColor:
                      location.pathname === text.link
                        ? configRedux.darkMode
                          ? "dark.link"
                          : "light.link"
                        : configRedux.darkMode
                        ? "dark.secondary"
                        : "light.secondary",
                    "&:hover": {
                      backgroundColor:
                        location.pathname === text.link
                          ? configRedux.darkMode
                            ? "dark.link"
                            : "light.link"
                          : configRedux.darkMode
                          ? "dark.background"
                          : "light.background",
                    },
                  }}
                  onClick={() => {
                    navigate(text.link);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: configRedux.darkMode
                        ? location.pathname === text.link
                          ? "dark.secondary"
                          : "dark.primary"
                        : "light.primary",
                    }}
                  >
                    {text.icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color: configRedux.darkMode
                        ? location.pathname === text.link
                          ? "dark.secondary"
                          : "dark.primary"
                        : "light.primary",
                    }}
                    primary={text.name}
                  />
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
      </Box>
    </Drawer>
  );
};
