import {
  alpha,
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import { useEffect, useState } from "react";
import { checkBoardPermissions } from "../../functions/settings/checkBoardPersmissions";
import getBatimentInfos from "../../functions/settings/getBatimentInfos";
import oghjiLogo from "../../assets/images/logoOnly.png";
import makeDate from "../../utils/makeDate";
import { technicalInfosTypes } from "../../utils/technicalInfosTypes";

const BatimentInfosUser = () => {
  const configRedux = useSelector((state) => state.config);
  const boardsRedux = useSelector((state) => state.boards);
  const profileRedux = useSelector((state) => state.profile);
  const authRedux = useSelector((state) => state.auth);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState(undefined);

  const fetchBatiment = async () => {
    setIsLoading(true);
    let result = await getBatimentInfos({
      token: authRedux.token,
      refreshToken: authRedux.refreshToken,
      batimentId: boardsRedux.boards.filter(
        (oneBoard) => oneBoard.id === Number(params.boardId)
      )[0].batiment.id,
      dispatch: dispatch,
      addSnack: enqueueSnackbar,
    });
    result.status === 200 && setResult(result.batiment);
    setIsLoading(false);
  };

  useEffect(() => {
    // console.log(boardsRedux.boards[boardsRedux.actualBoard - 1]);
    if (
      !checkBoardPermissions({
        boardId: params.boardId,
        userId: profileRedux.id,
        boards: boardsRedux.boards,
      }).havePermissions
    ) {
      navigate("/error");
    } else {
      fetchBatiment();
    } // eslint-disable-next-line
  }, []);

  const technicalElements = [
    { text: "Adresse", attribute: "adresse", type: "text" },
    { text: "Code Postal", attribute: "codePostal", type: "text" },
    { text: "Ville", attribute: "ville", type: "text" },
    { text: "Niveau", attribute: "niveau", type: "text" },
    { text: "Superficie", attribute: "superficie", type: "text" },
    { text: "Nombre de pièces", attribute: "nbPieces", type: "text" },
    { text: "Nombre d'occupants", attribute: "nbOccupants", type: "text" },
    {
      text: "Date de construction / rénovation",
      attribute: "dateConstruction",
      type: "date",
    },
    {
      text: "Type de chauffage",
      attribute: "typeChauffage",
      type: "attribute",
    },
    { text: "Type de plaque", attribute: "typePlaque", type: "attribute" },
    {
      text: "Type de chauffe-eau",
      attribute: "typeChauffeEau",
      type: "attribute",
    },
    { text: "Type de four", attribute: "typeFour", type: "attribute" },
    {
      text: "Voiture électrique",
      attribute: "nbVoitureElectrique",
      type: "text",
    },
    { text: "Panneaux photovolatïques", attribute: "panneau", type: "boolean" },
  ];

  return isLoading ? (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexBasis: "100%",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: alpha(
            configRedux.darkMode ? "#000000" : "#ffffff",
            0.8
          ),
          borderRadius: 100,
          position: "relative",
        }}
      >
        <CircularProgress
          size={70}
          sx={{ color: `${configRedux.darkMode ? "dark" : "light"}.link` }}
        />
        <img
          src={oghjiLogo}
          alt={"Chargement"}
          height={40}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        rowGap: 2,
      }}
    >
      <List
        sx={{
          width: "100%",
          backgroundColor: `${
            configRedux.darkMode ? "dark" : "light"
          }.secondary`,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          mt: 1,
          pt: 4,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: -12,
            left: -12,
            backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
            borderRadius: 4,
            pl: 1,
            pr: 2,
            // width: 32,
            height: 32,
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            justifyContent: "center",
            alignItems: "center",
            boxShadow: 5,
          }}
        >
          <HomeWorkIcon />
          <Typography
            sx={{
              color: configRedux.darkMode ? "dark.secondary" : "light.primary",
            }}
          >
            {`${
              boardsRedux.boards.filter(
                (oneBoard) => oneBoard.id === Number(params.boardId)
              ).length === 1 &&
              boardsRedux.boards.filter(
                (oneBoard) => oneBoard.id === Number(params.boardId)
              )[0].name
            } - Informations du bâtiment`}
          </Typography>
        </Box>
        {technicalElements.map((oneItem) => {
          return (
            <ListItem
              key={oneItem.text}
              sx={{
                width: "calc(100% - 32px)",
                backgroundColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.background`,
                mt: 1,
                mb: 1,
                mr: 2,
                ml: 2,
                p: 0,
                borderRadius: 2,
              }}
              secondaryAction={
                <Typography
                  sx={{
                    color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
                  }}
                >
                  {result[oneItem.attribute] !== undefined
                    ? oneItem.type === "text"
                      ? result[oneItem.attribute]
                      : oneItem.type === "date"
                      ? makeDate(result[oneItem.attribute])
                      : oneItem.type === "attribute"
                      ? technicalInfosTypes[oneItem.attribute].filter(
                          (oneType) =>
                            oneType.id === Number(result[oneItem.attribute])
                        )[0].name
                      : oneItem.type === "boolean" &&
                        result[oneItem.attribute] === false
                      ? "Non"
                      : "Oui"
                    : "N/A"}
                </Typography>
              }
            >
              <Box
                sx={{ borderRadius: 2, pt: 1, pr: 2, pb: 1, pl: 2 }}
                // onClick={() => {
                //   if (result[oneItem.attribute]) {
                //     navigator.clipboard.writeText(result[oneItem.attribute]);
                //     enqueueSnackbar(`Information - ${oneItem.text} - copiée.`, {
                //       variant: "success",
                //     });
                //   }
                // }}
              >
                <ListItemText
                  sx={{
                    color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
                  }}
                  id={"test"}
                  primary={oneItem.text}
                />
              </Box>
            </ListItem>
          );
        })}
      </List>
      <Button
        onClick={() => {
          navigate(`/boards/${params.boardId}`);
        }}
        aria-label="add-board-button"
        size="small"
        // fullWidth
        variant="contained"
        sx={{
          mt: 0,
          mr: 0,
          ml: 0,
          mb: 2,
          backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
          color: `${configRedux.darkMode ? "dark" : "light"}.background`,
          "&:hover": {
            backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.link`,
            opacity: 0.8,
          },
          textTransform: "none",
          display: "flex",
          flexDirection: "row",
          columnGap: 1,
          alignItems: "center",
        }}
      >
        <ReplyIcon />
        Retour
      </Button>
    </Box>
  );
};

export default BatimentInfosUser;
