import { setBoardCircuits } from "../../redux/features/circuits";
import {
  setinitialFilterEventsCircuits,
} from "../../redux/features/events";

function getBoardCircuits(data) {
  let circuitsOfSelectedBoard = [];
  let boardCircuitsIds = [];
  data.boards.length > 0 &&
    data.actualBoard &&
    data.boards[data.actualBoard - 1].box.map((oneBox) =>
      oneBox.circuits.map((oneCircuit) =>
        circuitsOfSelectedBoard.push(oneCircuit.id)
      )
    );
  JSON.stringify(circuitsOfSelectedBoard) !==
    JSON.stringify(data.circuitsIdBefore) &&
    data.dispatch(
      setinitialFilterEventsCircuits({ circuits: circuitsOfSelectedBoard })
    );
  let resultCircuits = [];
  data.circuits.length > 0 &&
    data.circuits.forEach((oneCircuit) => {
      if (
        circuitsOfSelectedBoard.includes(oneCircuit.id) &&
        oneCircuit.circuitUse
      ) {
        resultCircuits.push(oneCircuit);
        boardCircuitsIds.push(oneCircuit.id);
      }
    });
  let higherConso = [];
  resultCircuits?.map(
    (circuit) => circuit.sense !== "Producteur" && higherConso.push(circuit)
  );
  higherConso.sort(
    (a, b) =>
      b.consommationInstantanees[0].consommation -
      a.consommationInstantanees[0].consommation
  );
  data.dispatch(
    setBoardCircuits({
      boardCircuits: resultCircuits,
      higherConso: higherConso,
    })
  );
  return resultCircuits.length > 0;
}

export default getBoardCircuits;
