import { useSelector } from "react-redux";
import { Box, alpha } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import NoBoards from "../components/home/noBoards";
import ModeSelector from "../components/home/modeSelector";
import HigherConso from "../components/home/higherConso";
import EventsFalse from "../components/home/eventsFalse";
import oghjiLogo from "../assets/images/logoOnly.png";
import { breakpoints } from "../utils/responsive";

const HomePage = () => {
  const boardsRedux = useSelector((state) => state.boards);
  const circuitsRedux = useSelector((state) => state.circuits);
  const eventsRedux = useSelector((state) => state.events);
  const configRedux = useSelector((state) => state.config);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexBasis: "100%",
        justifyContent:
          boardsRedux.isLoading ||
          circuitsRedux.circuitsIsLoading ||
          eventsRedux.isLoading
            ? "center"
            : "flex-start",
        flexDirection: "column",
        // alignItems: 'center',
        rowGap: 4,
      }}
    >
      {boardsRedux.isLoading ||
      circuitsRedux.circuitsIsLoading ||
      eventsRedux.isLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Box
            sx={{
              p: 2,
              backgroundColor: alpha(
                configRedux.darkMode ? "#000000" : "#ffffff",
                0.8
              ),
              borderRadius: 100,
              position: "relative",
            }}
          >
            <CircularProgress
              size={70}
              sx={{ color: `${configRedux.darkMode ? "dark" : "light"}.link` }}
            />
            <img
              src={oghjiLogo}
              alt={"Chargement"}
              height={40}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </Box>
        </Box>
      ) : boardsRedux.boards.length === 0 ? (
        <NoBoards />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection:
                configRedux.screenWidth > breakpoints.minTablet
                  ? "row"
                  : "column",
              justifyContent: "space-between",
              width: "100%",
              columnGap: 4,
              rowGap: 4,
              pt: 1,
            }}
          >
            <ModeSelector />
            <HigherConso />
          </Box>
          <EventsFalse />
        </>
      )}
    </Box>
  );
};

export default HomePage;
