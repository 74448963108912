import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { breakpoints } from "../../utils/responsive";

const TextFieldProfile = (data) => {
  const configRedux = useSelector((state) => state.config);

  return (
    <Box
      sx={{
        flexBasis:
          configRedux.screenWidth > breakpoints.minTablet ? "50%" : "100%",
      }}
    >
      <Box sx={{ ml: 2, mr: 2, mt: 1, mb: 0 }}>
        <TextField
          sx={{
            m: 0,
            "& label": {
              color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
            },
            "& label.Mui-focused": {
              color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
            },
            "& .MuiInput-root": {
              "&:after": {
                borderColor: configRedux.darkMode
                  ? "dark.primary"
                  : "light.primary",
                borderWidth: "2px",
              },
              "&:before": {
                borderColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.button.before`,
              },
              ":hover:not(.Mui-focused)": {
                "&:before": {
                  borderColor: `${
                    configRedux.darkMode ? "dark" : "light"
                  }.button.hover`,
                  borderWidth: "2px",
                },
              },
            },
            input: {
              color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
            },
          }}
          // margin={data.data.margin ? data.data.margin : "normal"}
          // margin="normal"
          InputProps={{
            startAdornment: data.data.prefix && (
              <InputAdornment
                position="start"
                sx={{
                  mb: 0.3,
                }}
              >
                <Typography>{data.data.prefix}</Typography>
              </InputAdornment>
            ),
          }}
          type={data.data.type}
          autoComplete={data.data.autoComplete}
          error={data.data.error}
          helperText={data.data.error ? data.data.errorText : ""}
          required={data.data.required}
          fullWidth
          id={data.data.id}
          inputProps={{ "data-testid": data.data.testId }}
          label={data.data.label}
          name={data.data.id}
          value={data.data.value}
          onChange={data.data.onChange}
          size="small"
          variant="standard"
        />
      </Box>
    </Box>
  );
};

export default TextFieldProfile;
