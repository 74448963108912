import { Box, styled } from "@mui/material";
import PeriodChoice from "../components/conso/periodChoice";
import DetailChoice from "../components/conso/detailChoice";
import PeriodDefine from "../components/conso/periodDefine";
import { useDispatch, useSelector } from "react-redux";
import { BarChart, useDrawingArea, useYScale } from "@mui/x-charts";
import { colorsTheme } from "../assets/theme/theme";
import { convertMonth } from "../helpers/convertMonth";
import dayjs from "dayjs";
import { eehPowerValue } from "../utils/eehDefinition";
import {
  clickOnAxisFromAll,
  clickOnAxisFromMonth,
  clickOnAxisFromYear,
} from "../redux/features/conso";

const ConsoPage = () => {
  const consoRedux = useSelector((state) => state.conso);
  const configRedux = useSelector((state) => state.config);
  const boardsRedux = useSelector((state) => state.boards);

  const dispatch = useDispatch();

  const eehCalc = (data) => {
    let boardCreationDate = dayjs(
      boardsRedux.boards[boardsRedux.actualBoard - 1].createdAt
    );
    let dateNow = dayjs().startOf("hour");
    let beginDate, endDate;

    const calculateBeginEndDates = (year, month, date, hour, period) => {
      if (period === "year") {
        if (boardCreationDate.year() === year) {
          beginDate = boardCreationDate;
        } else {
          beginDate = dayjs().year(year).startOf("year");
        }
        if (dateNow.year() === year) {
          endDate = dateNow;
        } else {
          endDate = dayjs().year(year).endOf("year");
        }
      } else if (period === "month") {
        if (
          boardCreationDate.year() === year &&
          boardCreationDate.month() === month
        ) {
          beginDate = boardCreationDate;
        } else {
          beginDate = dayjs().year(year).month(month).startOf("month");
        }
        if (dateNow.year() === year && dateNow.month() === month) {
          endDate = dateNow;
        } else {
          endDate = dayjs().year(year).month(month).endOf("month");
        }
      } else if (period === "day") {
        if (
          boardCreationDate.year() === year &&
          boardCreationDate.month() === month &&
          boardCreationDate.date() === date
        ) {
          beginDate = boardCreationDate;
        } else {
          beginDate = dayjs().year(year).month(month).date(date).startOf("day");
        }
        if (
          dateNow.year() === year &&
          dateNow.month() === month &&
          dateNow.date() === date
        ) {
          endDate = dateNow;
        } else {
          endDate = dayjs().year(year).month(month).date(date).endOf("day");
        }
      } else if (period === "hour") {
        if (
          boardCreationDate.year() === year &&
          boardCreationDate.month() === month &&
          boardCreationDate.date() === date &&
          boardCreationDate.hour() === hour
        ) {
          beginDate = boardCreationDate;
        } else {
          beginDate = dayjs()
            .year(year)
            .month(month)
            .date(date)
            .hour(hour)
            .startOf("hour");
        }
        if (
          dateNow.year() === year &&
          dateNow.month() === month &&
          dateNow.date() === date &&
          dateNow.hour() === hour
        ) {
          endDate = dateNow;
        } else {
          endDate = dayjs()
            .year(year)
            .month(month)
            .date(date)
            .hour(hour)
            .endOf("hour");
        }
      }
    };
    if (consoRedux.periodChoice === 4) {
      calculateBeginEndDates(data.date, null, null, null, "year");
    } else if (consoRedux.periodChoice === 3) {
      calculateBeginEndDates(
        consoRedux.periodDefineYear,
        data.date,
        null,
        null,
        "month"
      );
    } else if (consoRedux.periodChoice === 2) {
      calculateBeginEndDates(
        consoRedux.periodDefineMonthYear,
        consoRedux.periodDefineMonthMonth,
        data.date,
        null,
        "day"
      );
    } else if (consoRedux.periodChoice === 0 || consoRedux.periodChoice === 1) {
      calculateBeginEndDates(
        consoRedux.periodDefineDayYear,
        consoRedux.periodDefineDayMonth,
        consoRedux.periodDefineDayDay,
        data.date,
        "hour"
      );
    }
    return (
      data.consommation / endDate.diff(beginDate, "hour", true) / eehPowerValue
    );
  };

  const LoadingText = styled("text")(() => ({
    stroke: "none",
    fill: colorsTheme.palette[configRedux.darkMode ? "dark" : "light"].primary,
    shapeRendering: "crispEdges",
    textAnchor: "middle",
    dominantBaseline: "middle",
    fontFamily: "Trenda Regular",
  }));

  function NoDataOverlay() {
    const yScale = useYScale();
    const { left, width, height } = useDrawingArea();

    const [bottom, top] = yScale.range();

    return (
      <g>
        <LoadingText
          x={bottom - bottom + left + width / 2}
          y={top + height / 2}
        >
          Aucune donnée à afficher
        </LoadingText>
      </g>
    );
  }

  const LoadingReact = styled("rect")({
    opacity: 0.5,
    fill: colorsTheme.palette[configRedux.darkMode ? "dark" : "light"]
      .secondary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });

  function LoadingOverlay() {
    return (
      <g>
        <LoadingReact x={0} width={"100%"} y={0} height={"100%"} />
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
          <circle
            fill="#FF156D"
            stroke="#FF156D"
            strokeWidth="6"
            r="2"
            cx="85"
            cy="100"
          >
            <animate
              attributeName="opacity"
              calcMode="spline"
              dur="1"
              values="1;0;1;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="-.4"
            ></animate>
          </circle>
          <circle
            fill="#FF156D"
            stroke="#FF156D"
            strokeWidth="6"
            r="2"
            cx="100"
            cy="100"
          >
            <animate
              attributeName="opacity"
              calcMode="spline"
              dur="1"
              values="1;0;1;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="-.2"
            ></animate>
          </circle>
          <circle
            fill="#FF156D"
            stroke="#FF156D"
            strokeWidth="6"
            r="2"
            cx="115"
            cy="100"
          >
            <animate
              attributeName="opacity"
              calcMode="spline"
              dur="1"
              values="1;0;1;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="0"
            ></animate>
          </circle>
        </svg>
      </g>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexBasis: "100%",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        rowGap: 3,
      }}
    >
      <PeriodChoice />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            flexBasis: "30%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PeriodDefine />
        </Box>
        <Box
          sx={{
            flexBasis: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DetailChoice />
        </Box>
      </Box>
      <BarChart
        loading={consoRedux.isLoading}
        slots={{
          loadingOverlay: LoadingOverlay,
          noDataOverlay: NoDataOverlay,
        }}
        tooltip={{ trigger: "axis" }}
        slotProps={{
          popper: {
            sx: {
              "& .MuiChartsTooltip-mark": {
                display: "none",
              },
              "& .MuiChartsTooltip-markCell": {
                display: "none",
              },
              "& .MuiChartsTooltip-labelCell": {
                display: "none",
              },
              "& .MuiChartsTooltip-valueCell": {
                p: 0,
                m: 0,
                textAlign: "center",
              },
              "& .MuiChartsTooltip-cell": {
                pl: 2,
                m: 0,
                textAlign: "center",
              },
              "& .MuiChartsTooltip-root": {
                backgroundColor:
                  colorsTheme.palette[configRedux.darkMode ? "dark" : "light"]
                    .secondary,
                border: 1,
                borderColor:
                  colorsTheme.palette[configRedux.darkMode ? "dark" : "light"]
                    .link,
              },
              "& .MuiTypography-root": {
                color:
                  colorsTheme.palette[configRedux.darkMode ? "dark" : "light"]
                    .primary,
                textAlign: "center",
                p: 0,
                m: 0,
              },
            },
          },
        }}
        onAxisClick={(event, data) => {
          if (!consoRedux.isLoading) {
            if (consoRedux.periodChoice === 4) {
              dispatch(clickOnAxisFromAll({ year: data.axisValue }));
            } else if (consoRedux.periodChoice === 3) {
              dispatch(clickOnAxisFromYear({ month: data.dataIndex }));
            } else if (consoRedux.periodChoice === 2) {
              dispatch(clickOnAxisFromMonth({ day: data.axisValue }));
            }
          }
        }}
        borderRadius={10}
        xAxis={[
          {
            id: "periods",
            data: consoRedux.consoResult.map((oneConso) => oneConso.date),
            valueFormatter: (value, context) => {
              return context.location === "tick"
                ? consoRedux.isLoading
                  ? "•"
                  : consoRedux.periodChoice === 4
                  ? `${value}`
                  : consoRedux.periodChoice === 3
                  ? value < 12
                    ? `${convertMonth(value).min}`
                    : `${value}`
                  : consoRedux.periodChoice === 2
                  ? "•"
                  : consoRedux.periodChoice === 1
                  ? "•"
                  : "•"
                : context.location === "tooltip" &&
                  consoRedux.periodChoice === 4
                ? `Année ${value}`
                : consoRedux.periodChoice === 3
                ? value < 12
                  ? `${convertMonth(value).name} ${consoRedux.periodDefineYear}`
                  : `${value}`
                : consoRedux.periodChoice === 2
                ? `${value < 10 ? `0${value}` : `${value}`} / ${
                    consoRedux.periodDefineMonthMonth < 9
                      ? `0${consoRedux.periodDefineMonthMonth + 1}`
                      : `${consoRedux.periodDefineMonthMonth}`
                  } / ${consoRedux.periodDefineMonthYear}`
                : consoRedux.periodChoice === 1
                ? `${value < 10 ? `0${value}` : value}h - ${
                    value < 9 ? `0${value + 1}` : value + 1
                  }h`
                : "---";
            },
            scaleType: "band",
            disableLine: true,
            disableTicks: true,
          },
        ]}
        series={[
          {
            data: consoRedux.consoResult.map((oneConso) =>
              consoRedux.detailChoice === 2
                ? eehCalc({
                    consommation: oneConso.consommation,
                    date: oneConso.date,
                  })
                : oneConso[
                    consoRedux.detailChoice === 0
                      ? "consommation"
                      : consoRedux.detailChoice === 1 && "price"
                  ]
            ),
            valueFormatter: (value) => {
              return `${
                consoRedux.detailChoice === 0
                  ? value < 1000
                    ? `${Math.round(value)}Wh`
                    : `${Math.round(value / 10) / 100} kWH`
                  : consoRedux.detailChoice === 1
                  ? value < 100
                    ? `${Math.round(value * 100) / 100} €`
                    : `${Math.round(value * 10) / 10} €`
                  : consoRedux.detailChoice === 2 &&
                    `${Math.round(value * 10) / 10} EEH`
              }`;
            },
            color: consoRedux.consoResult.map(
              (oneConso) =>
                colorsTheme.palette[configRedux.darkMode ? "dark" : "light"]
                  .link
            ),
          },
        ]}
        yAxis={[
          {
            valueFormatter: (value) =>
              consoRedux.detailChoice === 0
                ? value > 1000
                  ? `${Math.round(value / 100) / 10} kWh`
                  : `${value} Wh`
                : consoRedux.detailChoice === 1
                ? `${Math.round(value * 10) / 10} €`
                : `${Math.round(value)} EEH`,
            labelStyle: { color: "yellow" },
            disableLine: true,
            disableTicks: true,
            tickLabelStyle: {
              fontSize: 12,
            },
          },
        ]}
        sx={{
          backgroundColor: `${
            configRedux.darkMode ? "dark" : "light"
          }.secondary`,
          borderRadius: 2,
          minHeight: 200,
          "& .MuiBarElement-root": { "&:hover": { cursor: "pointer" } },
          "& .MuiChartsAxis-tickLabel": {
            fill: colorsTheme.palette[configRedux.darkMode ? "dark" : "light"]
              .primary,
          },
          "&.MuiChartsTooltip-root": { backgroundColor: "blue" },
        }}
        margin={{ top: 30, bottom: 30, left: 85, right: 15 }}
      />
    </Box>
  );
};

export default ConsoPage;
