import { fetchLoginSuccess } from "../../redux/features/auth";
import BASE_URL from "../../utils/api";
import endpoints from "../../utils/endpoints";

const refreshTokenFunction = async (data) => {
  let response = { status: 0, token: "", refreshToken: "" };
  try {
    let refreshFetch = await fetch(`${BASE_URL}${endpoints.refreshToken}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh_token: data.refreshToken }),
      signal: AbortSignal.timeout(3000),
    });
    response.status = refreshFetch.status;
    if (refreshFetch.status === 200) {
      console.log("Refresh function : 200");
      let refreshData = await refreshFetch.json();
      data.dispatch(
        fetchLoginSuccess({
          token: refreshData.token,
          refresh_token: refreshData.refresh_token,
        })
      );
      response.token = refreshData.token;
      response.refreshToken = refreshData.refresh_token;
    }
  } catch (error) {}
  return response;
};

export default refreshTokenFunction;
