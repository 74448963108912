// src/pages/RegisterPage.js
import React, { useState } from "react";
import { Button, Container, Box, Typography, Divider } from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import { useDispatch, useSelector } from "react-redux";
import emailValidator from "../../helpers/emailValidator";
import passwordValidator from "../../helpers/passwordValidator";
import { signup } from "../../functions/auth/signup";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import FacebookLoginButton from "../welcome/facebookLoginButton";
import TextFieldWelcome from "../welcome/textfieldWelcome";

export default function RegisterPage() {
  const configRedux = useSelector((state) => state.config);
  const authRedux = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({
    email: "",
    plainPassword: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const passwordCheck = [
    {
      label: "Au moins 8 caractères",
      code: "length",
      validation: passwordValidator(user.plainPassword).length,
    },
    {
      label: "Au moins 1 majuscule",
      code: "upperCase",
      validation: passwordValidator(user.plainPassword).upperCase,
    },
    {
      label: "Au moins 1 minuscule",
      code: "lowerCase",
      validation: passwordValidator(user.plainPassword).lowerCase,
    },
    {
      label: "Au moins 1 chiffre",
      code: "digit",
      validation: passwordValidator(user.plainPassword).digit,
    },
    {
      label: "Au moins 1 caractère spécial",
      code: "specialChar",
      validation: passwordValidator(user.plainPassword).specialChar,
    },
  ];

  // EXTRACT FUNCTION IN SPECIFIC FILE !

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      passwordValidator(user.plainPassword).global &&
      emailValidator(user.email)
    ) {
      setIsLoading(true);
      await signup({ user: user, enqueueSnackbar: enqueueSnackbar });
      setIsLoading(false);
    } else {
      enqueueSnackbar("Veuillez vérifiez les données saisies puis réessayer.", {
        variant: "error",
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ p: 0 }}>
      <Box
        sx={{
          marginTop: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextFieldWelcome
            data={{
              error: !emailValidator(user.email) && user.email.length > 0,
              errorText: "Adresse email incorrecte",
              id: "email",
              type: "email",
              autoComplete: "username",
              testId: "username-input-register",
              label: "Adresse mail",
              value: user.email,
              onChange: handleChange,
            }}
          />
          <TextFieldWelcome
            data={{
              error:
                !passwordValidator(user.plainPassword).global &&
                user.plainPassword.length > 0,
              errorText: "Mot de passe non conforme",
              id: "plainPassword",
              type: "password",
              autoComplete: "new-password",
              testId: "password-input-register",
              label: "Mot de passe",
              value: user.plainPassword,
              onChange: handleChange,
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              mt: 0.5,
            }}
          >
            {passwordCheck.map((oneCheck) => {
              return (
                <Box
                  key={oneCheck.label}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: 1,
                    alignItems: "center",
                    minWidth: "50%",
                  }}
                >
                  {oneCheck.validation ? (
                    <CheckCircleOutlinedIcon
                      sx={{
                        fontSize: 16,
                        color: `alert.${
                          configRedux.darkMode ? "dark" : "light"
                        }.success.bg`,
                      }}
                    />
                  ) : (
                    <RadioButtonUncheckedOutlinedIcon
                      sx={{
                        fontSize: 16,
                        color: `alert.${
                          configRedux.darkMode ? "dark" : "light"
                        }.error.bg`,
                      }}
                    />
                  )}
                  <Typography
                    sx={{
                      fontSize: 9,
                      color: `${
                        configRedux.darkMode ? "dark" : "light"
                      }.primary`,
                    }}
                  >
                    {oneCheck.label}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <Button
            // TouchRippleProps={{ "data-testid": "register-button" }}
            disabled={authRedux.isLoading || isLoading}
            size="small"
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 7,
              mb: 4,
              backgroundColor: configRedux.darkMode
                ? "dark.link"
                : "light.link",
              color: configRedux.darkMode
                ? "dark.background"
                : "light.background",
              "&:hover": {
                backgroundColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.link`,
                opacity: 0.8,
              },
              textTransform: "none",
            }}
          >
            S'inscrire
          </Button>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              columnGap: 5,
              paddingBottom: 25,
            }}
          >
            <Divider
              color={configRedux.darkMode ? "#d9d9d9" : "#0f0f0f"}
              sx={{ width: "25%", marginBottom: 0 }}
            />
            <Typography
              sx={{ m: 0, p: 0, fontSize: 10, backgroundColor: "transparent" }}
              color={`${configRedux.darkMode ? "dark" : "light"}.primary`}
            >
              | |
            </Typography>
            <Divider
              color={configRedux.darkMode ? "#d9d9d9" : "#0f0f0f"}
              sx={{ width: "25%", marginBottom: 0 }}
            />
          </div>
          <FacebookLoginButton
            data={{
              dispatch: dispatch,
              enqueueSnackbar: enqueueSnackbar,
              navigate: navigate,
            }}
          />
        </Box>
      </Box>
    </Container>
  );
}
