import BASE_URL from "../../utils/api";
import endpoints from "../../utils/endpoints";
import refreshTokenFunction from "../auth/refreshToken";
import PowerIcon from "@mui/icons-material/Power";
import PowerOffIcon from "@mui/icons-material/PowerOff";

export const patchCircuit = async (data) => {
  let responseStatus = 0;
  const tryFetch = async (token) => {
    try {
      let fetchPatchCircuit = await fetch(
        `${BASE_URL}${endpoints.patchCircuit}/${data.circuit.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/merge-patch+json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ status: data.newStatus }),
          signal: AbortSignal.timeout(3000),
        }
      );
      responseStatus = fetchPatchCircuit.status;
      if (fetchPatchCircuit.status === 200) {
        console.log("Patch circuits function : 200");
      }
    } catch (error) {
      responseStatus = error.status;
    }
  };

  await tryFetch(data.token);

  if (responseStatus === 401) {
    console.log("Patch circuits function : 401");
    let refreshFunction = await refreshTokenFunction({
      refreshToken: data.refreshToken,
      dispatch: data.dispatch,
    });
    refreshFunction.status === 200 && (await tryFetch(refreshFunction.token));
  }
  responseStatus === 200
    ? data.addSnack(
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            columnGap: "10px",
          }}
        >
          <span>
            {"Le circuit "}
            <em>
              <u>{data.circuit.name}</u>
            </em>
            {" a eté "}
            {data.newStatus ? "activé. " : "desactivé ."}
          </span>
          {data.newStatus ? <PowerIcon /> : <PowerOffIcon />}
        </div>,
        {
          variant: "success",
        }
      )
    : data.addSnack(
        <span>
          {"Impossible "}
          {data.newStatus
            ? " d'activer le circuit "
            : " de désactiver le circuit "}
          <u>{data.circuit.name}</u>
        </span>,
        {
          variant: "error",
        }
      );
  return responseStatus;
};
