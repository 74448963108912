import React from "react";
import { Box, alpha } from "@mui/material";
import { useSelector } from "react-redux";
import Header from "../components/header/header";
import CircuitsPage from "./CircuitsPage";
import { Route, Routes } from "react-router-dom";
import { breakpoints } from "../utils/responsive";
import HomePage from "./HomePage";
import AddBoardPage from "./AddBoardPage";
import EventsPage from "./EventsPage";
import ErrorPage from "./ErrorPage";
import WelcomePage from "./WelcomePage";
import { MenuMaker } from "../components/global/menuMaker";
import { MenuMobileMaker } from "../components/global/menuMobileMaker";
import { FaqPage } from "./FaqPage";
import Configurator from "../components/global/configurator";
import ProfilePage from "./ProfilePage";
import MyBoardsPage from "./MyBoardsPage";
import OneBoardMenu from "../components/profile/oneBoardMenu";
import TechnicalInfos from "../components/profile/technicalInfos";
import BatimentInfosSwitch from "../components/profile/batimentInfosSwitch";
import ConsoPage from "./ConsoPage";
import AnalysePage from "./AnalysePage";
import Tension from "../components/profile/tension";
import AccessManagement from "../components/profile/accessManagement";

const Body = () => {
  const configRedux = useSelector((state) => state.config);
  const authRedux = useSelector((state) => state.auth);

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: alpha(
          configRedux.darkMode ? "#000000" : "#ffffff",
          0.5
        ),
      }}
    >
      <Configurator />
      <Header />
      <Box
        sx={{
          p: 0,
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        {configRedux.screenWidth > breakpoints.minDesktop ? (
          <MenuMaker />
        ) : (
          <MenuMobileMaker />
        )}
        <Box
          component="main"
          sx={{
            p: 0,
            height: "100vh",
          }}
        >
          <Box
            sx={{
              width:
                configRedux.screenWidth > breakpoints.minDesktop
                  ? "calc(100vw - 240px - 48px)"
                  : "calc(100vw - 48px)",
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <Box sx={{ width: "100%", flexBasis: "68px" }}></Box>
            <Box
              sx={{
                width: "100%",
                flexBasis: "calc(100vh - 68px - 55px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                overflow: "auto",
                p: 3,
              }}
            >
              <Routes>
                <Route
                  path="/"
                  element={authRedux.status ? <HomePage /> : <WelcomePage />}
                />
                <Route
                  path="/signin"
                  element={authRedux.status ? <ErrorPage /> : <WelcomePage />}
                />
                <Route
                  path="/signup"
                  element={authRedux.status ? <ErrorPage /> : <WelcomePage />}
                />
                <Route
                  path="/home"
                  element={authRedux.status ? <HomePage /> : <ErrorPage />}
                />
                <Route
                  path="/circuits"
                  element={authRedux.status ? <CircuitsPage /> : <ErrorPage />}
                />
                <Route path="/faq" element={<FaqPage />} />
                <Route
                  path="/events"
                  element={authRedux.status ? <EventsPage /> : <ErrorPage />}
                />
                <Route
                  path="/addboard"
                  element={authRedux.status ? <AddBoardPage /> : <ErrorPage />}
                />
                <Route
                  path="/profile"
                  element={authRedux.status ? <ProfilePage /> : <ErrorPage />}
                />
                <Route
                  path="/boards"
                  element={authRedux.status ? <MyBoardsPage /> : <ErrorPage />}
                />
                <Route
                  path="/boards/:boardId"
                  element={authRedux.status ? <OneBoardMenu /> : <ErrorPage />}
                />
                <Route
                  path="/boards/:boardId/technical-informations"
                  element={
                    authRedux.status ? <TechnicalInfos /> : <ErrorPage />
                  }
                />
                <Route
                  path="/boards/:boardId/batiment-informations"
                  element={
                    authRedux.status ? <BatimentInfosSwitch /> : <ErrorPage />
                  }
                />
                <Route
                  path="/boards/:boardId/tension"
                  element={authRedux.status ? <Tension /> : <ErrorPage />}
                />
                <Route
                  path="/boards/:boardId/access-management"
                  element={
                    authRedux.status ? <AccessManagement /> : <ErrorPage />
                  }
                />
                <Route
                  path="/conso"
                  element={authRedux.status ? <ConsoPage /> : <ErrorPage />}
                />
                <Route
                  path="/analyse"
                  element={authRedux.status ? <AnalysePage /> : <ErrorPage />}
                />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </Box>
            <Box sx={{ width: "100%", flexBasis: "55px" }}></Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Body;
