const passwordValidator = (password) => {
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@[\]^_=!"#$%&'()*+,-./:;<>{}<>|~]).{8,}$/;
  const lengthRegex = /^.{8,}$/;
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const digitRegex = /\d/;
  const specialCharRegex = /[@[\]^_=!"#$%&'()*+,-./:;<>{}<>|~]/;
  return {
    global: passwordRegex.test(password),
    length: lengthRegex.test(password),
    upperCase: uppercaseRegex.test(password),
    lowerCase: lowercaseRegex.test(password),
    digit: digitRegex.test(password),
    specialChar: specialCharRegex.test(password),
  };
};

export default passwordValidator;
