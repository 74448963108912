const endpoints = {
  login: "/login",
  loginFacebook: "/login/facebook",
  signup: "/register",
  refreshToken: "/token/refresh",
  getBoards: "/electronic_boards",
  getCircuits: "/circuits",
  getActiveEvents: "/events_active",
  getEvents: "/events",
  timeBetweenBoardsRefresh: 3600000,
  addBoard: "/electronic_board/add_electronic_board_by_shareCode",
  patchCircuit: "/circuits",
  patchBoardMode: "/electronic_boards",
  getProfile: "/current_user",
  patchProfile: "/users",
  getBatiment: "/batiments",
  getConso: "/electronic_boards",
  getConsoHour: "/consommationhoraire",
  getConsoDay: "/consommationjournaliere",
  getConsoMonth: "/consommationmensuelle",
  changePassword: "/change_password",
};

export default endpoints;
