import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { colorsTheme } from "../../assets/theme/theme";
import {
  setAnalysePeriodDefineMonth,
  setAnalysePeriodDefineYear,
} from "../../redux/features/analyse";

const PeriodDefine = () => {
  const configRedux = useSelector((state) => state.config);
  const analyseRedux = useSelector((state) => state.analyse);
  const boardsRedux = useSelector((state) => state.boards);

  const dispatch = useDispatch();

  const [newYear, setNewYear] = useState(analyseRedux.periodDefineYear);
  const [newMonthMonth, setNewMonthMonth] = useState(
    analyseRedux.periodDefineMonthMonth
  );
  const [newMonthYear, setNewMonthYear] = useState(
    analyseRedux.periodDefineMonthYear
  );

  const today = dayjs();
  const minDate = dayjs()
    .year(
      new Date(
        boardsRedux.boards[boardsRedux.actualBoard - 1].createdAt
      ).getFullYear()
    )
    .month(
      new Date(
        boardsRedux.boards[boardsRedux.actualBoard - 1].createdAt
      ).getMonth()
    )
    .date(
      new Date(
        boardsRedux.boards[boardsRedux.actualBoard - 1].createdAt
      ).getDate()
    )
    .hour(0)
    .minute(0)
    .second(1);

  useEffect(() => {
    setNewYear(analyseRedux.periodDefineYear);
    setNewMonthMonth(analyseRedux.periodDefineMonthMonth);
    setNewMonthYear(analyseRedux.periodDefineMonthYear); // eslint-disable-next-line
  }, [analyseRedux.periodChoice]);

  return (
    analyseRedux.periodChoice !== 2 && (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <DatePicker
          disabled={analyseRedux.isLoading}
          format={
            analyseRedux.periodChoice === 0
              ? "MM / YYYY"
              : analyseRedux.periodChoice === 1 && "YYYY"
          }
          slotProps={{ textField: { size: "small" } }}
          yearsPerRow={3}
          value={
            analyseRedux.periodChoice === 0
              ? dayjs().date(1).month(newMonthMonth).year(newMonthYear)
              : analyseRedux.periodChoice === 1 &&
                dayjs().date(1).month(1).year(newYear)
          }
          maxDate={today}
          minDate={minDate}
          sx={{
            position: "relative",
            width: "100%",
            backgroundColor: `${
              configRedux.darkMode ? "dark" : "light"
            }.secondary`,
            borderRadius: 2,
            "& .MuiInputBase-input": {
              disabled: true,
              height: 31,
              textAlign: "center",
              color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
            },
            "& .Mui-disabled": {
              WebkitTextFillColor:
                colorsTheme.palette[configRedux.darkMode ? "dark" : "light"]
                  .primary,
            },
            "& .MuiSvgIcon-root": {
              fill: colorsTheme.palette[configRedux.darkMode ? "dark" : "light"]
                .primary,
            },
          }}
          label={""}
          onError={() => {
            if (analyseRedux.periodChoice === 1) {
              setNewYear(analyseRedux.periodDefineYear);
            } else if (analyseRedux.periodChoice === 0) {
              setNewMonthMonth(analyseRedux.periodDefineMonthMonth);
              setNewMonthYear(analyseRedux.periodDefineMonthYear);
            }
          }}
          onAccept={(newValue) => {
            const dateNow = dayjs().hour(23).minute(59).second(59);
            const creationBoardDate = minDate;
            const inputDate = dayjs()
              .year(newValue.$y)
              .month(newValue.$M)
              .date(newValue.$D)
              .hour(12)
              .minute(0)
              .second(0);
            if (
              dateNow.diff(inputDate) > 0 &&
              inputDate.diff(creationBoardDate) > 0
            ) {
              if (analyseRedux.periodChoice === 1) {
                setNewYear(newValue.$y);
                dispatch(setAnalysePeriodDefineYear({ year: newValue.$y }));
              } else if (analyseRedux.periodChoice === 0) {
                setNewMonthMonth(newValue.$M);
                setNewMonthYear(newValue.$y);
                dispatch(
                  setAnalysePeriodDefineMonth({
                    year: newValue.$y,
                    month: newValue.$M,
                  })
                );
              }
            } else {
              if (analyseRedux.periodChoice === 1) {
                setNewYear(analyseRedux.periodDefineYear);
              } else if (analyseRedux.periodChoice === 0) {
                setNewMonthMonth(analyseRedux.periodDefineMonthMonth);
                setNewMonthYear(analyseRedux.periodDefineMonthYear);
              }
            }
          }}
          views={
            analyseRedux.periodChoice === 0
              ? ["month", "year"]
              : analyseRedux.periodChoice === 1 && ["year"]
          }
        />
      </LocalizationProvider>
    )
  );
};

export default PeriodDefine;
