import { useSelector } from "react-redux";
import pictos from "../../utils/pictos";
import { alpha, Box, LinearProgress, Typography } from "@mui/material";
import { colorsTheme } from "../../assets/theme/theme";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { eehPowerValue } from "../../utils/eehDefinition";

const AnalyseCircuitBox = ({ circuit }) => {
  const configRedux = useSelector((state) => state.config);
  const analyseRedux = useSelector((state) => state.analyse);
  const boardsRedux = useSelector((state) => state.boards);

  const [totalHours, setTotalHours] = useState(1);

  const initialZero = (num) => (num < 10 ? `0${num}` : num);

  const calculateStartDate = (boardCreationDate) => {
    if (
      analyseRedux.periodChoice === 2 ||
      (analyseRedux.periodChoice === 1 &&
        boardCreationDate.getFullYear() === analyseRedux.periodDefineYear)
    ) {
      return dayjs(
        `${boardCreationDate.getFullYear()}-${initialZero(
          boardCreationDate.getMonth() + 1
        )}-${initialZero(boardCreationDate.getDate())}`
      ).startOf("day");
    } else if (analyseRedux.periodChoice === 1) {
      return dayjs(`${analyseRedux.periodDefineYear}-01-01`).startOf("year");
    } else if (analyseRedux.periodChoice === 0) {
      return dayjs(
        `${analyseRedux.periodDefineMonthYear}-${initialZero(
          analyseRedux.periodDefineMonthMonth + 1
        )}-01`
      ).startOf("month");
    }
  };

  const calculateEndDate = (boardCreationDate, nowDate) => {
    if (
      analyseRedux.periodChoice === 2 ||
      (analyseRedux.periodChoice === 1 &&
        nowDate.getFullYear() === analyseRedux.periodDefineYear)
    ) {
      return dayjs().startOf("hour");
    } else if (analyseRedux.periodChoice === 1) {
      return dayjs(`${boardCreationDate.getFullYear()}-12-31`).endOf("year");
    } else if (analyseRedux.periodChoice === 0) {
      return dayjs(
        `${analyseRedux.periodDefineMonthYear}-${initialZero(
          analyseRedux.periodDefineMonthMonth + 1
        )}-01`
      ).endOf("month");
    }
  };

  useEffect(() => {
    const boardCreationDate = new Date(
      boardsRedux.boards[boardsRedux.actualBoard - 1].createdAt
    );
    const nowDate = new Date(Date.now());
    const startDate = calculateStartDate(boardCreationDate);
    const endDate = calculateEndDate(boardCreationDate, nowDate);
    setTotalHours(Math.round(endDate.diff(startDate, "hour", true))); // eslint-disable-next-line
  }, [
    analyseRedux.periodDefineYear,
    analyseRedux.periodDefineMonthYear,
    analyseRedux.periodDefineMonthMonth,
    analyseRedux.periodChoice,
    analyseRedux.analyseResult,
  ]);

  function LinearProgressWithLabel() {
    return (
      <Box
        sx={{
          flexBasis: "50%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100%", mr: 1, position: "relative" }}>
          <LinearProgress
            sx={{
              width: "100%",
              height: 16,
              borderRadius: 15,
              "& .MuiLinearProgress-bar": {
                transitionDuration: "8s",
                transitionTimingFunction: "linear",
                backgroundColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.link`,
              },
              "&.MuiLinearProgress-root": {
                backgroundColor: alpha(
                  colorsTheme.palette[configRedux.darkMode ? "dark" : "light"]
                    .link,
                  0.2
                ),
              },
            }}
            variant="determinate"
            value={
              analyseRedux.detailChoice === 0
                ? (circuit.consommation /
                    analyseRedux.analyseResultTotalPower) *
                  100
                : analyseRedux.detailChoice === 1
                ? (circuit.price / analyseRedux.analyseResultTotalPrice) * 100
                : analyseRedux.detailChoice === 2 &&
                  (circuit.consommation /
                    totalHours /
                    eehPowerValue /
                    (analyseRedux.analyseResultTotalPower /
                      totalHours /
                      eehPowerValue)) *
                    100
            }
          />
          <Box
            sx={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <Typography
              variant="body2"
              color={`${configRedux.darkMode ? "dark" : "light"}.primary`}
            >{`${
              analyseRedux.detailChoice === 0
                ? Math.round(
                    (circuit.consommation /
                      analyseRedux.analyseResultTotalPower) *
                      100
                  )
                : analyseRedux.detailChoice === 1
                ? Math.round(
                    (circuit.price / analyseRedux.analyseResultTotalPrice) * 100
                  )
                : analyseRedux.detailChoice === 2 &&
                  Math.round(
                    (circuit.consommation /
                      totalHours /
                      eehPowerValue /
                      (analyseRedux.analyseResultTotalPower /
                        totalHours /
                        eehPowerValue)) *
                      100
                  )
            }% - ${
              analyseRedux.detailChoice === 0
                ? circuit.consommation < 1000
                  ? Math.round(circuit.consommation)
                  : Math.round(circuit.consommation / 1000)
                : analyseRedux.detailChoice === 1
                ? Math.round(circuit.price)
                : analyseRedux.detailChoice === 2 &&
                  Math.round(
                    (circuit.consommation / totalHours / eehPowerValue) * 10
                  ) / 10
            } ${
              analyseRedux.detailChoice === 0
                ? circuit.consommation < 1000
                  ? "Wh"
                  : "kWh"
                : analyseRedux.detailChoice === 1
                ? "€"
                : analyseRedux.detailChoice === 2 && "EEH"
            }`}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width:
          //   configRedux.screenWidth <= breakpoints.minTablet
          // ?
          "100%",
        // : "calc((100% - 16px) / 2)",
        backgroundColor: `${configRedux.darkMode ? "dark" : "light"}.secondary`,
        borderRadius: 2,
        p: 0,
        m: 0,
        display: "flex",
        flexDirection: "column",
        rowGap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: 1,
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexBasis: "50%",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              columnGap: 2,
            }}
          >
            <Box
              sx={{
                backgroundColor: `${
                  configRedux.darkMode ? "dark" : "light"
                }.background`,
                p: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 2,
              }}
            >
              <img
              // CHANGE IF ID > LIST LENGTH
                alt={
                  pictos[
                    circuit.pictogramme.id < 63 ? circuit.pictogramme.id - 1 : 0
                  ].name
                }
                src={
                  pictos[
                    circuit.pictogramme.id < 63 ? circuit.pictogramme.id - 1 : 0
                  ].picto
                }
                style={{
                  filter: configRedux.darkMode
                    ? "brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(1%) hue-rotate(198deg) brightness(87%) contrast(98%)"
                    : "brightness(0) saturate(100%) invert(3%) sepia(8%) saturate(6%) hue-rotate(332deg) brightness(99%) contrast(101%)",
                }}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
                }}
              >
                {circuit.name}
              </Typography>
              <Typography
                sx={{
                  color: `${configRedux.darkMode ? "dark" : "light"}.primary`,
                }}
              >
                {circuit.location}
              </Typography>
            </Box>
          </Box>
          <LinearProgressWithLabel />
        </Box>
      </Box>
    </Box>
  );
};

export default AnalyseCircuitBox;
